import {
  primaryColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

import orange from "@material-ui/core/colors/orange";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import { createMuiTheme } from "@material-ui/core";


const timePickerStyle = createMuiTheme({
  palette: {
    primary: green,
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: orange[500],
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: orange[500],
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: orange[500],
      },
      noPoint: {
        backgroundColor: orange[500],
      },
      thumb : {
        border: '14px solid ' + primaryColor[0],
      },
    },
    MuiButton: {
      textPrimary: {
        color: orange[500],
      }
    },
    MuiFormControl: {
      root: {
        marginTop: 13,
      }
    },
    MuiInputLabel: {
      formControl: {
        fontSize: 14,
        color: grey.A200,
      }
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: '1px solid ' + grayColor[4],
        }
      }
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
      }
    }
  },
});

export default timePickerStyle;