/**
 NULLチェック
@param  input   チェック対象
@return Boolean チェック結果(問題あるかどうか)
                true  : 問題あり（NULL）
                false : 問題なし（NOT　NULL)
*/
export default function isNotPassNullChk(value) {
    if ( value === null || value === undefined ) return true;
    if((typeof value) === 'string')
        value = value.trim();
    if ( value === '') return true;
    return false;
}
