import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/paginationStyle.js";

import { PAGINATION_PREV_NAME,PAGINATION_NEXT_NAME } from 'variables/config.js';

const useStyles = makeStyles(styles);


NBrootsPagination.defaultProps = {
  color: "primary",
  current: 1
};

NBrootsPagination.propTypes = {

  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  count: PropTypes.number,
  pagesize: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  current: PropTypes.number

};

export default function NBrootsPagination(props) {

  const classes = useStyles();

  const { color } = props;

  const numPages = () => {
    var pageCount = Math.ceil(props.count / props.pagesize);
    if (isNaN(pageCount) || pageCount === 0)
      return 1;
    else
      return pageCount;

  }

  const lstPages = () => {
    return ([...new Array(numPages()).keys()].map(item => item + 1));
  }

  const showPages = () => {
    var result = [];

    var subArr = [];

    if (lstPages().length <= 5) {

      subArr = lstPages().slice(0, lstPages().length);

    } else {

      var starti = props.current < 3 ? 0 : props.current - 3;
      var endi;
      if (starti + 5 > numPages()) {
        starti = numPages() - 5;
        endi = numPages();
      } else {
        endi = starti + 5;
      }
      subArr = lstPages().slice(starti, endi);
    }

    result.push({
      text: PAGINATION_PREV_NAME,
      active: false,
      disabled: props.current === 1 ? true : false,
    });

    for (var idx = 0; idx < subArr.length; idx++) {
      var item = {
        text: subArr[idx],
        active: props.current === subArr[idx] ? true : false,
        disabled: false
      }
      result.push(item);
    }

    result.push({
      text: PAGINATION_NEXT_NAME,
      active: false,
      disabled: props.current === numPages() ? true : false,
    });

    return result;
  }


  return (

    <ul className={classes.pagination}>
      {/* {console.log(showPages())} */}
      {showPages().map((prop, key) => {

        const paginationLink = cx({
          [classes.paginationLink]: true,
          [classes[color]]: prop.active,
          [classes.disabled]: prop.disabled
        });

        return (
          <li className={classes.paginationItem} key={key}>
            <Button onClick={() => props.onClick(prop)} className={paginationLink}>
              {prop.text}
            </Button>
          </li>
        );

      })}

    </ul>
  );
}
