/**
 * 通貨フォーマット返却関数
 * @param  {number,string} num 対象数値
 * @return {string}     フォーマット後文字列
 */
export default function ccyFormat(num) {
  if (!num) {
    return "";
  }
  if (typeof num === "string") {
    num = Number(num.replace(/,/g, ""));
  }

  return isNaN(num) ? "" : `${num.toLocaleString()}`;
}
