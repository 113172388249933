/**
 * 空白の内容をNULLに変換
 * API 送信する前に、情報処理用（非必須チェック対応）
 * @param  {String}  input    処理対象
 * @return {String}           処理結果
 */
export default function cEmptyToNull(input) {

  if (input === undefined) return null;
  if (input === '') return null;
  if((typeof input) === 'string')
    input = input.trim();
  return input;
}
