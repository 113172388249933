import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Service from "utils/service/service.js"
import cNullToEmpty from "utils/functions/cNullToEmpty";
import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";

import styles from "assets/jss/material-dashboard-pro-react/views/UserInfo/userProfileStyle.js";
import { BASE_URL, VALIDATE_MSG} from 'variables/config.js';
// メモリ中企業リスト
import { userInfo } from 'variables/runtimeInfos.js';
// exp components

import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";

export default function UserProfile(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);

  //画面編集項目＜初期状態＞
  // メールアドレス  ユーザ名 	フリカナ 	パスワード 
  var t = {

    idCompany: userInfo.corpid,
    // companyName: userInfo.corpname,
    // email: userInfo.email,
    companyName: "",
    email: "",
    zip:"",
    address:"",
    //入力項目
    name: "",
    kana: "",
    //MSG項目
    msg_name: "",
    msg_kana: "",
    //CHECK結果
    chk_name: "",
    chk_kana: "",


  };
  const [vals, setVals] = React.useState(t);

  // const { info, setInfo } = props;

  //初期化処理
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {

    //進捗画面
    setIsLoading(true);
    //情報のロード
    // handleClickStartSave();
    formatDetailData(props);
    //  handleClickStartSave();

  }, [props]);    //[] 忘れないよう…　初期化


  const formatDetailData = (tProps) => {
    var url = BASE_URL + "/API/employee/Get";
    //　検索条件  前画面から取得
    var data = {
      id: userInfo.userid
    };
    // console.log(data);
    var tVals;
    Service(tProps, url, data).then(resp => {
      // console.log(resp + '20200423-------------------------------')
      // 正常系
      if (resp.status === 0) {
        var info = resp.infos;
        //画面編集項目（SET）
        tVals = {
          id: info.id,
          companyName:info.companyName,
          name: cNullToEmpty(info.name),
          kana: cNullToEmpty(info.kana),
          email:info.email,
          zip:info.zip,
          address:info.address,

          updateIndex: info.updateIndex,       //更新の場合、必ず必須
        };
        // //Copy　STATE情報　By　Value
        // var tResult = JSON.parse(JSON.stringify(tVals));

        setVals(tVals);

      } else {
        //その他APIエラー   
        tVals = {
          //入力項目
          // id: "",
          // email: "",
          // name: "",
          // companyName: "",
          // kana: "",
          // password: "",
          // admin: 0,
        };

        setVals(tVals);

        setMsgInfo([true, resp.msg, 'warning']);
      }
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };

  //画面動作 SAVE
  const handleClickStartSave = (name, value) => {
    let tVals = vals;
    //一括チェック
    validate(tVals, 'name', tVals.name);
    validate(tVals, 'kana', tVals.kana);
    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if (
    tVals["chk_name"] === "error" ||
    tVals["chk_kana"] === "error" ) {

    setVals(tVals);
    return;
  }
    // //情報をSTATEに保存する。
    tVals[name] = value;

    var tResult = Object.assign({}, tVals);
    setVals(tResult);

    setIsLoading(true);

    var url = BASE_URL + "/API/employee/Update";

    //　入力データ
    var data = {
      idCompany: userInfo.corpid,
      id: userInfo.userid,
      name: tResult.name,
      kana: tResult.kana,
      updateIndex: tResult.updateIndex,    //更新の場合、必ず必須
    };
    // console.log(data);

    Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        setMsgInfo([true, resp.msg, 'success']);
        //情報のロード
        formatDetailData();
      } else {
        setMsgInfo([true, resp.msg, 'danger']);
      }
      //進捗画面を閉じる
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });

    // formatDetailData();
  };

  //編集発生する時
  const handleEditOnChange = (name, value) => {
    let tVals = vals;
    //STATE内容、項目名、入力値
    validate(tVals, name, value);
    //情報をSTATEに保存する。

      tVals[name] = value;
 
    var tResult = Object.assign({}, tVals);
    setVals(tResult);

  }


  function validate(tVals, name, value) {
    //チェック処理
  if (name === 'name') {
    if (isNotPassNullChk(value)) {
      tVals["chk_" + name] = "error";
      tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
    } else if (isNotPassCharTypeChk(value, "zenkaku")) {
      tVals["chk_" + name] = "error";
      tVals["msg_" + name] = VALIDATE_MSG.MSG_003;
    } else if (isNotPassLengthChk(value, null, 100)) {
      tVals["chk_" + name] = "error";
      tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
    } else {
      tVals["chk_" + name] = "success";
      tVals["msg_" + name] = "";
    }
  }else if(name === 'kana'){
    if (isNotPassNullChk(value)) {
      tVals["chk_" + name] = "error";
      tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
    } else if ( isNotPassCharTypeChk(value, 'katakana')) {
      tVals["chk_" + name] = "error";
      tVals["msg_" + name] = VALIDATE_MSG.MSG_005;
    } else if (isNotPassLengthChk(value, null, 50)) {
      tVals["chk_" + name] = "error";
      tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 50);
    } else {
      tVals["chk_" + name] = "success";
      tVals["msg_" + name] = "";
    }
  }

  }


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={8} md={6}>
          <Card profile>
            <CardHeader className={classes.cardHeader}>
            </CardHeader>
            <CardBody profile>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="メールアドレス："
                    id="mail_address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      // defaultValue: "test@exp-corp.com",
                      value: vals.email,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="名前："
                    id="name"
                    success={vals.chk_name === "success"}             // chkResult + Json.prop
                    error={vals.chk_name === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      // defaultValue: "山田さん",
                      value: vals.name,
                      disabled: false,
                      onChange: event => {
                        handleEditOnChange("name", event.target.value);
                      }
                    }}
                    helperText={vals.msg_name}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="フリガナ："
                    id="kana"
                    success={vals.chk_kana === "success"}             // chkResult + Json.prop
                    error={vals.chk_kana === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      // defaultValue: "ヤマダサン",
                      value: vals.kana,
                      disabled: false,
                      onChange: event => {
                        handleEditOnChange("kana", event.target.value);
                      }
                    }}
                    helperText={vals.msg_kana}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="企業名："
                    id="company_name"
                    inputProps={{
                      // defaultValue: "株式会社イーバイピー",
                      value: vals.companyName,
                      disabled: true,
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="郵便番号："
                    id="postal_code"
                    inputProps={{
                      // defaultValue: "141-0031",
                      value: vals.zip,
                      disabled: true,
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="住所："
                    id="address"
                    inputProps={{
                      // defaultValue: "東京都品川区西五反田８丁目８−１６ 高砂ビル ４０３Ａ",
                      value: vals.address,
                      disabled: true,
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ textAlign: "right", display: "block" }}>
              <Button round color="warning" onClick={() => handleClickStartSave()}>変更</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {/* 共通ダイアログ画面 */}
      <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </div>
  );
}
