/**
 文字列チェック()
@param  input    String  チェック対象文字列
@param  charType String  チェック種別
                        　・"zenkaku"               : 全角文字（ひらがな・カタカナ・漢字 etc.）
                        　・"hiragana"              : 全角ひらがな
                        　・"katakana"              : 全角カタカナ
                        　・"alphanumeric"          : 半角英数字（大文字・小文字）
                        　・"numeric"               : 半角数字
                        　・"alphabetic"            : 半角英字（大文字・小文字）
                        　・"upper-alphabetic"      : 半角英字（大文字のみ）
                        　・"lower-alphabetic"      : 半角英字（小文字のみ）
@return Boolean チェック結果(問題あるかどうか)
                true  : 問題あり（引数に指定した種別以外の文字列が含まれている）
                false : 問題なし（引数に指定した種別の文字列のみで構成されている)
*/
export default function isNotPassCharTypeChk(input, charType) {
    switch (charType) {
        // 全角文字（ひらがな・カタカナ・漢字 etc.）
        case "zenkaku":
            return (input.match(/^[^\0x01-\x7E\xA1-\xDF]+$/)) ? false : true ;
        // 全角ひらがな
        case "hiragana":
            return (input.match(/^[\u3041-\u3096]+$/)) ? false : true ;
        // 全角カタカナ
        case "katakana":
            return (input.match(/^[\u30a1-\u30fc]+$/)) ? false : true ;
        // 半角英数字（大文字・小文字）
        case "alphanumeric":
            return (input.match(/^[0-9a-zA-Z]+$/)) ? false : true ;
        // 半角数字
        case "numeric":
            return (input.match(/^[0-9]+$/)) ? false : true ;
        // 半角英字（大文字・小文字）
        case "alphabetic":
            return (input.match(/^[a-zA-Z]+$/)) ? false : true ;
        // 半角英字（大文字のみ）
        case "upper-alphabetic":
            return (input.match(/^[A-Z]+$/)) ? false : true ;
        // 半角英字（小文字のみ）
        case "lower-alphabetic":
            return (input.match(/^[a-z]+$/)) ? false : true ;
        default:
            return false;
    }

}
