import React from "react";
import { Route, Redirect } from "react-router-dom";
import { userInfo} from 'variables/runtimeInfos.js';   

// export default ({ component: ComposedComponent, ...rest }) => {
//   return (
//     <Route {...rest} render={(props) => (
//       ((userInfo.token === undefined) || (userInfo.token === null) || (token === "") || (userInfo.token === "null"))
//       ? <Redirect to={{
//         pathname: '/auth/login',
//         state: { from: props.location }
//       }} />
//       : <Route {...props} component={ComposedComponent}/>
//     )} />
//   )
// };

export default ({ component: ComposedComponent, ...rest }) => {
  const token = userInfo.token;

  if((token === undefined) || (token === null) || (token === "") || (token === "null")){
    return (
      <Redirect
        to={{
          pathname: "/auth/login",
          state: { from: rest.location }
        }}
      />);
  }

  return (
    <Route {...rest} component={ComposedComponent}/>
  );
};