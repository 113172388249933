import React from 'react';
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
// styles for buttons on sweetalert
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);

export default function NBrootsConfirm(props) {

  const { info, setInfo, setInfo2} = props;
  const classes = useStyles();

  //仕様について
  //https://github.com/djorg83/react-bootstrap-sweetalert

  return (
    <React.Fragment>
      {info[0] === true ?
        <SweetAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-250px" }}
          title="確認"
          confirmBtnText="OK"
          cancelBtnText="キャンセル"
          onConfirm={(e) => setInfo2 === undefined ? setInfo([false,"",{"opt": info[2].opt, "id": info[2].id, "result": 1}]): setInfo2(e, [false,"",{"opt": info[2].opt, "id": info[2].id, "result": 1}])}
          onCancel={(e) => setInfo2 === undefined ? setInfo([false,"",{"opt": info[2].opt, "id": info[2].id, "result": 0}]):setInfo2(e,[false,"",{"opt": info[2].opt, "id": info[2].id, "result": 0}])}
          confirmBtnCssClass={
            classes.button + " " + classes.warning
          }
          cancelBtnCssClass={
            classes.button + " " + classes.warning
          }
        >
          {info[1]}
        </SweetAlert>
        :  null
      }
    </React.Fragment>
  );
}

// import React from 'react';
// // material-ui components
// import { makeStyles } from "@material-ui/core/styles";
// import Slide from "@material-ui/core/Slide";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";
// // @material-ui/icons
// // import Close from "@material-ui/icons/Close";
// // core components
// import Button from "components/CustomButtons/Button.js";

// import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";

// const useStyles = makeStyles(styles);

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

// export default function NBrootsConfirm(props) {

//   const { info, setInfo} = props;
//   const classes = useStyles();

//   return (
//       <Dialog
//         classes={{
//           root: classes.center,
//           paper: classes.modal
//         }}
//         open={info[0]}
//         transition={Transition}
//         keepMounted
//         onClose={() => setInfo([false,"",{"opt": info[2].opt, "id": info[2].id, "result": 0}])}
//         aria-labelledby="modal-slide-title"
//         aria-describedby="modal-slide-description"
//       >
//         <DialogTitle
//           id="classic-modal-slide-title"
//           disableTypography
//           className={classes.modalHeader}
//         >
//           {/* <Button
//             justIcon
//             className={classes.modalCloseButton}
//             key="close"
//             aria-label="Close"
//             color="transparent"
//             onClick={() => setInfo(false,"")}
//           >
//             <Close className={classes.modalClose} />
//           </Button> */}
//           <h4 className={classes.modalTitle}>確認</h4>
//         </DialogTitle>
//         <DialogContent
//           id="modal-slide-description"
//           className={classes.modalBody}
//         >
//           <h5>{info[1]}</h5>
//         </DialogContent>
//         <DialogActions
//           className={classes.modalFooter + " " + classes.modalFooterCenter}
//         >
//           <Button onClick={() => setInfo([false,"",{"opt": info[2].opt, "id": info[2].id, "result": 0}])}>キャンセル</Button>
//           <Button onClick={() => setInfo([false,"",{"opt": info[2].opt, "id": info[2].id, "result": 1}])} color="success">
//             ＯＫ
//         </Button>
//         </DialogActions>
//       </Dialog>
//   );
// }