import {
    REQUEST_TIMEOUT,
    ERROR_TIMEOUT,
  } from 'variables/config.js';

import { JWTTokenUtils} from '../functions/jwtTokenUtils.js';
import { userInfo } from 'variables/runtimeInfos.js';   
import { clearLoginUserInfo } from 'layouts/initSystemData.js';

const createError = status => ({ status });

export default function ServiceLogin(url, data, method = 'POST', timeout = REQUEST_TIMEOUT) {
    // 清除token
    clearLoginUserInfo();

    const createOptions = (method, data, token) => ({
        method,
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(data),
    });

    const {token} = userInfo;
    var options = createOptions(method, data, token);

    return new Promise(async (resolve, reject) => {
        let timer = setTimeout(() => {
            return reject(createError(ERROR_TIMEOUT));
          }, timeout);

        const response = await fetch(url, options);
        clearTimeout(timer);          

        if (response.ok) {
            // 添加
            var token = response.headers.get('Authorization');
            userInfo.token = token;

            // 分解token中的payload信息
            var payload = JWTTokenUtils(token);

            // 返回用户ID
            return resolve(payload?.UserInfo?.id);
        }

        return reject(response);
    });
}
