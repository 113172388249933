import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { BASE_URL, VALIDATE_MSG } from 'variables/config.js';
import Service from "utils/service/service.js"

import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";
import isNotPassEmailChk from "utils/validations/isNotPassEmailChk";

import PrivacyPolicyPopup from "views/CommonPopup/PrivacyPolicyPopup.js";
// exp components
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Business from "@material-ui/icons/Business";
import Home from "@material-ui/icons/Home";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/MngSystem/Register/registerStyle.js";

import logo from "assets/img/dimpact_logo.jpeg";

const useStyles = makeStyles(styles);

export default function RegiterPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const [checked, setChecked] = React.useState([]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();

  
   //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //映像一覧画面
  const [privacyPolicyInfo, setPrivacyPolicyInfo] = React.useState([false, '', false]);
  //画面編集項目＜初期状態＞
  var t = {
    username            : "",     //入力項目
    kana                : "",       
    email               : "",
    corpname            : "",
    zip                 : "",
    address             : "",
    password            : "", 
    passwordconfirm     : "", 
    msg_username            : "",     //MSG項目
    msg_kana                : "",       
    msg_email               : "",
    msg_corpname            : "",
    msg_zip                 : "",
    msg_address             : "",
    msg_password            : "", 
    msg_passwordconfirm     : "", 
    chk_username            : "",     // CHECK結果＜CSS影響＞
    chk_kana                : "",       
    chk_email               : "",
    chk_corpname            : "",
    chk_zip                 : "",
    chk_address             : "",
    chk_password            : "", 
    chk_passwordconfirm     : "",     
  };

  const [vals, setVals] = React.useState([t]);      //[] !!!変更後、画面render発生させるため、array使用

  //初期化処理
  React.useEffect(() => {
  }, []);    //毎回OPENする時処理を行う

  //編集発生する時
  const handleEditOnChange = (name,value) => {

    let tVals = vals[0];

    //STATE内容、項目名、入力値
    validate(tVals,name,value);

    //情報をSTATEに保存する。
    tVals[name]  = value;
    
    setVals([tVals]);
  }

  function validate(tVals,name,value){
    //チェック処理
    if (name === 'username') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassCharTypeChk(value, "zenkaku")) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_003;
      } else if (isNotPassLengthChk(value, null, 100)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }else if(name === 'kana'){
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if ( isNotPassCharTypeChk(value, 'katakana')) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_005;
      } else if (isNotPassLengthChk(value, null, 50)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 50);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if(name === 'email'){
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassEmailChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_101;
      } else if (isNotPassLengthChk(value, null, 100)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if(name === 'corpname'){
      if (isNotPassNullChk(value)){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      // } else if(isNotPassCharTypeChk(value,"zenkaku")){
      //   tVals["chk_" + name] = "error";
      //   tVals["msg_" + name] = VALIDATE_MSG.MSG_003;
      } else if(isNotPassLengthChk(value,null,100)){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?",100);
      } else {
        tVals["chk_" + name]  = "success";
        tVals["msg_" + name]  = "";
      }
    }else if(name === 'zip'){
      if(isNotPassNullChk(value)){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if(isNotPassCharTypeChk(value,"numeric")){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_007;
      } else if(isNotPassLengthChk(value,null,7)){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] =  VALIDATE_MSG.MSG_200.replace("?",7);
      } else {
        tVals["chk_" + name]  = "success";
        tVals["msg_" + name]  = "";
      }
    }else if(name === 'address'){
      if(isNotPassNullChk(value)){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      // } else if(isNotPassCharTypeChk(value,"zenkaku")){
      //   tVals["chk_" + name] = "error";
      //   tVals["msg_" + name] = VALIDATE_MSG.MSG_003;
      } else if(isNotPassLengthChk(value,null,255)){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] =  VALIDATE_MSG.MSG_200.replace("?",255);
      } else {
        tVals["chk_" + name]  = "success";
        tVals["msg_" + name]  = "";
      }
    } else if (name === 'password') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassCharTypeChk(value, "alphanumeric")) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_006;
      } else if (isNotPassLengthChk(value, 6, null)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_201.replace("?", 6);
      } else if (isNotPassLengthChk(value, null, 15)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 15);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }else if (name === 'passwordconfirm') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassCharTypeChk(value, "alphanumeric")) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_006;
      } else if (isNotPassLengthChk(value, 6, null)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_201.replace("?", 6);
      } else if (isNotPassLengthChk(value, null, 15)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 15);
      } else if(value!==tVals.password){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_009;
      }else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }
  }

  //プライバシー規約リンク押す
  const pabloOpen = () => {
    var tPrivacyPolicyInfo = [
      true,           //Popup画面表示FLG
      {               //情報
      },
      false           //保存ボタン押すFLG
    ]
    setPrivacyPolicyInfo(tPrivacyPolicyInfo);
  };

  //新規登録ボタン押す
  const handleClickRegisterBtn = () => {

    let tVals = vals[0];
    //一括チェック
    validate(tVals,'username',tVals.username);
    validate(tVals,'kana',tVals.kana);
    validate(tVals,'email',tVals.email);
    validate(tVals,'corpname',tVals.corpname);
    validate(tVals,'zip',tVals.zip);
    validate(tVals,'address',tVals.address);
    validate(tVals,'password',tVals.password);
    validate(tVals,'passwordconfirm',tVals.passwordconfirm);

    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if( tVals["chk_username"] === "error" ||
        tVals["chk_kana"] === "error" ||
        tVals["chk_email"] === "error" ||
        tVals["chk_corpname"] === "error" ||
        tVals["chk_zip"] === "error" ||
        tVals["chk_address"] === "error" ||
        tVals["chk_password"] === "error" ||
        tVals["chk_passwordconfirm"] === "error" ){

        setVals([tVals]);
        return;
    }

    //送信処理
    setIsLoading(true);
    var tResult = Object.assign({}, tVals);
    var url = BASE_URL + "/API/Register/Create";
    var data = {
      username        : tResult.username,
      kana            : tResult.kana,
      email           : tResult.email,
      corpname        : tResult.corpname,
      zip             : tResult.zip,
      address         : tResult.address,
      password        : tResult.password,
      passwordconfirm : tResult.passwordconfirm,
    };

    Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        setMsgInfo([true, resp.msg, 'success']);
        setIsLoading(false);

        // 画面跳转
        props.history.push('/auth/login');
      } else {
        //その他APIエラー  
        setMsgInfo([true, resp.msg, 'warning']);
        setIsLoading(false);
      }

    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={6}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <img src={logo} alt="logo" width="300"/>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="名前 *"
                  id="username"
                  success={vals[0].chk_username === "success"}
                  error={vals[0].chk_username === "error"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    value     : vals[0].username,  
                    onChange  : event => handleEditOnChange("username", event.target.value),
                    placeholder: "名前",
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                  helperText={vals[0].msg_username}     
                />
                <CustomInput
                  labelText="フリガナ *"
                  id="kana"
                  success={vals[0].chk_kana === "success"}
                  error={vals[0].chk_kana === "error"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    value     : vals[0].kana,  
                    onChange  : event => handleEditOnChange("kana", event.target.value),
                    placeholder: "フリガナ",
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                  helperText={vals[0].msg_kana}   
                />
                <CustomInput
                  labelText="メールアドレス *"
                  id="email"
                  success={vals[0].chk_email === "success"}
                  error={vals[0].chk_email === "error"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    value     : vals[0].email,  
                    onChange  : event => handleEditOnChange("email", event.target.value),
                    placeholder: "メールアドレス",
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                  helperText={vals[0].msg_email} 
                />
                <CustomInput
                  labelText="企業名 *"
                  id="corpname"
                  success={vals[0].chk_corpname === "success"}
                  error={vals[0].chk_corpname === "error"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    value     : vals[0].corpname,  
                    onChange  : event => handleEditOnChange("corpname", event.target.value),
                    placeholder: "企業名",
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Business className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                  helperText={vals[0].msg_corpname} 
                />
                <CustomInput
                  labelText="郵便番号 *"
                  id="zip"
                  success={vals[0].chk_zip === "success"}
                  error={vals[0].chk_zip === "error"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    value     : vals[0].zip,  
                    onChange  : event => handleEditOnChange("zip", event.target.value),
                    placeholder: "郵便番号",
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                  helperText={vals[0].msg_zip} 
                />
                <CustomInput
                  labelText="住所 *"
                  id="address"
                  success={vals[0].chk_address === "success"}
                  error={vals[0].chk_address === "error"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    value     : vals[0].address,  
                    onChange  : event => handleEditOnChange("address", event.target.value),
                    placeholder: "住所",
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Home className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                  helperText={vals[0].msg_address} 
                />
                <CustomInput
                  labelText="パスワード *"
                  id="password"
                  success={vals[0].chk_password === "success"}
                  error={vals[0].chk_password === "error"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    value     : vals[0].password,  
                    onChange  : event => handleEditOnChange("password", event.target.value),
                    placeholder: "パスワード",
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                  helperText={vals[0].msg_password} 
                />
                <CustomInput
                  labelText="パスワードを再入力 *"
                  id="passwordconfirm"
                  success={vals[0].chk_passwordconfirm === "success"}
                  error={vals[0].chk_passwordconfirm === "error"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    value     : vals[0].passwordconfirm,  
                    onChange  : event => handleEditOnChange("passwordconfirm", event.target.value),
                    placeholder: "パスワードを再入力",
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                  helperText={vals[0].msg_passwordconfirm} 
                />
                <FormControlLabel
                  classes={{
                    root: classes.checkboxLabelControl,
                    label: classes.checkboxLabel
                  }}
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(1)}
                      checkedIcon={
                        <Check className={classes.checkedIcon} />
                      }
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  label={
                    <span>
                      当社の利用規約および<a href="#pablo"><span onClick={pabloOpen}> プライバシー規約</span></a>に同意したとみなされます。
                    </span>
                  }
              />
              </CardBody>
              <div className={classes.center}>
                <Button round disabled={checked.length>0?false:true} onClick={handleClickRegisterBtn} color="warning" >
                  新規登録
                </Button>
              </div>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      {/* プライバシー規約Popup画面 */}
      <PrivacyPolicyPopup info={privacyPolicyInfo} setInfo={setPrivacyPolicyInfo} />
      {/* 共通ダイアログ画面 */}
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </div>
  );
}
