import Service from "utils/service/service.js"
import { BASE_URL } from 'variables/config.js';

// メモリ中企業リスト + 素材-種類リスト
import { corpsInfo, groupsInfo, getGroupsInfo, materialCategoryInfo } from 'variables/runtimeInfos.js';

import { userInfo } from 'variables/runtimeInfos.js';  

//画面遷移する時、処理を行う
export const clearLoginUserInfo = () => {
    // 清除token信息
    userInfo.token      = "";

    // 清除登陆客户信息
    userInfo.userid      = "";
    userInfo.username    = "";
    userInfo.useremail   = "";
    userInfo.corpid      = "";
    userInfo.corpname    = "";

    // ユーザ権限　
    // ０一般ユーザ　1　企業管理員　2　システム管理員
    userInfo.userlevel   = 0;
};

//画面遷移する時、処理を行う
export const initSystemData = async (props) => {

  //console.log('企業リストの取得:' + result);
  return await initCorpListData(props);
};


function initCorpListData(props){

  groupsInfo.lst  = [];
  //console.log('企業リストの取得');

  return new Promise(resolve => {

    var url = BASE_URL + "/API/InitInfo/GetAllInfos";

    var data = {

    };

    Service(props, url, data).then(resp => {
      // console.log(resp);
      // 正常系
      if (resp.status === 0) {
        
        var info = resp.infos;

        //Data情報の処理
        //var lst = info.dataRows;
        //メモリにセットする。
        corpsInfo.lst = info.corplst;
        //素材-種類リスト
        materialCategoryInfo.lst = info.categorylst;

        //企業ユーザーの場合、そのまま設置場所リストを初期化する。
        if(corpsInfo.lst.length === 1){
          getGroupsInfo(corpsInfo.lst[0].id)
        }
        
        resolve('ok');

      } else {
        //その他APIエラー   
        resolve('ng');   
      }
    }).catch(resp => {
      //接続エラーなど
      resolve('ng');   
    });


  });
};
