/**
 *　設置場所メンテナンス
 * 
 */
/* eslint-disable */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Grid from '@material-ui/core/Grid';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import FormControl from "@material-ui/core/FormControl";
import Box from '@material-ui/core/Box';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

//exp components
// import NBrootsPagination from "components/@NBrootsPagination/NBrootsPagination.js";
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";

import Service from "utils/service/service.js"
import { BASE_URL, VALIDATE_MSG, CORP_ID_SELECTED_ALL } from 'variables/config.js';
import cEmptyToNull from "utils/functions/cEmptyToNull"

import styles from "assets/jss/material-dashboard-pro-react/views/CommonPopup/CommonPopupStyle";

import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";

// メモリ中企業リスト
import { userInfo, corpsInfo, getGroupsInfo } from 'variables/runtimeInfos.js';

export default function GroupListMntPopup(props) {

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { info, setInfo } = props;
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);

  //検索結果（初期状態）
  var tSearchResult = {
    total     : 0,            //記録数
    lst       : []            //明細リスト
  }
  const [searchResult, setSearchResult] = React.useState(tSearchResult);
  //画面編集項目＜初期状態＞
  var t = {
    name              : "",       //設置場所名
    companyid         : userInfo.userlevel === 2?CORP_ID_SELECTED_ALL:userInfo.corpid,           //検索条件　企業ID
    msg_name          : "",       //MSG項目
    msg_companyid     : "",
    chk_name          : "",       //CHECK結果＜CSS影響＞
    chk_companyid     : "",
  };
  const [vals, setVals] = React.useState(t);      //[] !!!変更後、画面render発生させるため、array使用


  const open = info[0];

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if(open){
      // console.log("OPEN");
      //進捗画面
      setIsLoading(true);
      //設置場所名初期化
      setVals(t);
      //情報のロード
      formatLstData(t);
    }
    return ()=>{
      setMsgInfo([false, '', 'info']);
    }
  }, [open]);    //[] 忘れないよう…　初期化


  //===========================================
  //検索/改ページなど
  //===========================================
  const formatLstData = (tSearchCondition) => {
    var url = BASE_URL + "/API/GroupList/Search";

    var data = {
      companyid : cEmptyToNull(tSearchCondition.companyid === CORP_ID_SELECTED_ALL ? '' : tSearchCondition.companyid)
    };
    // 条件
    // console.log("検索条件-----------");
    // console.log(data);
    var tResult ;
    Service(props, url, data).then(resp => {
      // console.log("検索結果-----------");
      // console.log(resp);
      // 正常系
      if (resp.status === 0) {
        //和其他不同，不含分页信息
        //Data情報の処理
        var lst = resp.infos;
        //検索結果（セット）
        tResult = {
          total : info.total,
          lst   : [...lst]
        }
        setSearchResult(tResult);
      } else {
        //その他APIエラー  
        //検索結果（初期状態） 
        tResult = {
          total : 0,
          lst   : []
        }
        setSearchResult(tResult);
        setMsgInfo([true, resp.msg, 'warning']);   
      }
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      //setMsgInfo([true, resp, 'danger']);
    });
  };

  //検索条件部
  //企業変更の場合
  const handleChangeSearchStart = (name,e) => {    
    let tVals = vals;

    //選択情報をSTATEに保存
    if(e.target.name === 'corpSelect'){
      tVals.companyid = e.target.value;
    }

    validate(tVals, name, tVals.companyid);
    //情報をSTATEに保存する。
    var tResult = Object.assign({}, tVals);
    setVals(tResult);

    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(tVals);
  }

  //閉じるボタン押す
  const handleClickCancelBtn = () => {
    var tCorpListDetailInfo = [
      //当画面表示FLG    
      false,
      //当画面編集情報       
      {},
      //IsAfterOK
      false
    ]
    //戻り値のセット、リスト画面に戻す
    setInfo(tCorpListDetailInfo);
  }


  //編集発生する時
  const handleEditOnChange = (name,value) => {

    let tVals = vals;
    //STATE内容、項目名、入力値
    validate(tVals,name,value);
    //情報をSTATEに保存する。
    tVals[name]  = value;
    //Copy　STATE情報　By　Value
    var tResult = Object.assign({}, tVals);

    setVals(tResult);
    
  }

  function validate(item, name, value) {
    //チェック処理
    if (name === 'name') {
      if (isNotPassNullChk(value)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_001;
      // } else if (isNotPassCharTypeChk(value, "zenkaku")) {
      //   item["chk_" + name] = "error";
      //   item["msg_" + name] = VALIDATE_MSG.MSG_003;
      } else if (isNotPassLengthChk(value, null, 100)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        item["chk_" + name] = "success";
        item["msg_" + name] = "";
      }
    } else if (name === 'companyid') {
      if (isNotPassNullChk(value)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else {
        item["chk_" + name] = "success";
        item["msg_" + name] = "";
      }
    }
  }

  function validateCompany(item, value) {
    if (isNotPassNullChk(value)) {
      item["chk_companyid"] = "error";
      item["msg_companyid"] = VALIDATE_MSG.MSG_001;
    } else if (value === CORP_ID_SELECTED_ALL) {
      item["chk_companyid"] = "error";
      item["msg_companyid"] = VALIDATE_MSG.MSG_513;
    } else {
      item["chk_companyid"] = "success";
      item["msg_companyid"] = "";
    }
  }

  //画面動作 追加ボタン押す
  const handleClickStartAdd = () => {
    var tVals = vals;

    //一括チェック
    validate(tVals,'name',tVals.name);
    validateCompany(tVals,tVals.companyid);

    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if( tVals["chk_name"] === "error" ||
        tVals["chk_companyid"] === "error"){

      var tResult = Object.assign({}, tVals)
      setVals(tResult);
      return;
    }
    
    var url = BASE_URL + "/API/GroupList/Create";
    setIsLoading(true);

    //　入力データ
    var data = {
      idCompany  : cEmptyToNull(tVals.companyid),    //選択された企業ID
      name       : cEmptyToNull(tVals.name),     //入力された設置場所名
    };

    // console.log(data);

    //更新処理
    Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        setMsgInfo([true, resp.msg, 'success']);
        //情報のロード
        formatLstData(tVals);
      } else {
        setMsgInfo([true, resp.msg, 'danger']);
      }
      //進捗画面を閉じる
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };


  //===========================================
  //削除機能
  //=========================================== 
  const handleClickStartDel = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        "opt"     : "del",
        "id"      : obj,
        "result"  : 0
      }
    ]);
  };
  
  //確認メッセージ後の処理
  React.useEffect(() => {
    //削除処理
    function doDel(obj) {
      //編集IDX取得
      setIsLoading(true);
      var url = BASE_URL + "/API/GroupList/Delete";
      //　入力データ
      var data = {
        id          : cEmptyToNull(obj.id),
      };
      // console.log(data);
      //更新処理
      Service(props, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {
          setMsgInfo([true, resp.msg, 'success']);
          //情報のロード
          formatLstData(vals);
        } else {
          setMsgInfo([true, resp.msg, 'danger']);
        }
        //進捗画面を閉じる
        setIsLoading(false);
      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });
    };
    //起動確認結果
    if (confirmInfo[2].opt === "del" && confirmInfo[2].result === 1) {
      doDel(confirmInfo[2].id);     
    }
  }, [confirmInfo]);

  return (
    <Dialog fullWidth={true} maxWidth={'lg'} onClose={handleClickCancelBtn} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" className={classes.dialogHeader} onClose={handleClickCancelBtn}>
        設置場所管理
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="warning"
          onClick={handleClickCancelBtn}
          style={{ float: "right" }}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>

      <DialogContent>
        <GridItem className={classes.modalDialog} xs={12} sm={12} md={12} lg={12}>
          <GridContainer>
            <Grid item xs={12} sm={4}>
              {userInfo.userlevel === 2 &&
                <FormControl style={{ width: "80%" }}
                  className={classes.selectFormControl}
                  disabled={userInfo.userlevel===2?false:true}>
                  <InputLabel htmlFor="corpSelect" className={vals.chk_companyid === "error" ? classes.selectLabelErr : classes.selectLabel}>
                    企業 *
                  </InputLabel>
                  <Select
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    value={vals.companyid}
                    onChange={(e) => handleChangeSearchStart("companyid", e)}
                    inputProps={{ name: "corpSelect"}}>
                    <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                      企業を選択
                    </MenuItem>
                    <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={CORP_ID_SELECTED_ALL}>
                      すべて
                    </MenuItem>
                    {corpsInfo ? corpsInfo.lst.map((item,key) =>
                      <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={key}>
                        {item.corpname}
                      </MenuItem> 
                    ) : null}
                  </Select>
                  <Box className={classes.selectHelpTextErr}>{vals.msg_companyid}</Box>
                </FormControl>
              }
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={8} className={classes.optBox}>
              <CustomInput
                labelText="設置場所名："
                id="groupName"
                success={vals.chk_name === "success"}
                error={vals.chk_name === "error"}
                formControlProps={{
                  fullWidth: true,
                  className:classes.customFormControlClasses
                }}
                inputProps={{
                  value     : vals.name,
                  disabled  : false,
                  onChange  : event => handleEditOnChange("name", event.target.value)
                }}
                helperText={vals.msg_name}
              />
              <Button color="warning" className={classes.marginRight}　onClick={handleClickStartAdd}>
                <Add className={classes.icons} /> 設置場所追加
              </Button>
            </Grid>
            <TableContainer style={{ minHeight: 440, maxHeight: 440 }}>
              <Table stickyHeader>
                <TableBody className={classes.tableBody}>
                  {searchResult.lst ? searchResult.lst.map((item, key) =>
                    <TableRow className={classes.tableRow} key={key}>
                      {userInfo.userlevel === 2 && <TableCell className={classes.tableCell} style={{ width: "20%" }}>{item.idCompanyName}</TableCell>}
                      <TableCell className={classes.tableCell} style={{ width: "70%" }}>{item.name}</TableCell>
                      <TableCell className={classes.tableCell} style={{ width: "10%" }}>
                        <Tooltip id="tooltip-top" title="削除" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                          <Button simple className={classes.actionButton} onClick={() => { handleClickStartDel(item) }} color="danger">
                            <Delete />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </GridContainer>
        </GridItem>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClickCancelBtn} color="warning">
          キャンセル
          </Button> */}
        <Button onClick={handleClickCancelBtn} color="warning">
          閉じる
        </Button>
      </DialogActions>
      {/* 共通ダイアログ画面 */}
      <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Dialog>

  );
}
