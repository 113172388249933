import {
    blackColor,
    primaryColor,
    whiteColor,
    grayColor,
    hexToRgb
} from 'assets/jss/material-dashboard-pro-react.js';

const darkcyanColor = [
    "#008B8B",
    "#2B9581",
];

const turquoiseColor = [
    "#76D8C5",
];

const customCommonStyle = {
    imagesrc: {
        height: "50px",
        width: "50px",
    },
    switchBase: {
        color: darkcyanColor[1] + "!important"
    },
    switchBaseUnchecked: {
        color: grayColor[4] + "!important"
    },
    switchIcon: {
        backgroundColor: grayColor[4] + "!important",
        boxShadow: "0 1px 3px 1px rgba(" + hexToRgb(grayColor[4]) + ", 0.4)",
        color: whiteColor + " !important",
        border: "1px solid rgba(" + hexToRgb(grayColor[4]) + ", .54)"
    },
    switchIconUnchecked: {
        backgroundColor: grayColor[4] + "!important",
        boxShadow: "0 1px 3px 1px rgba(" + hexToRgb(grayColor[4]) + ", 0.4)",
        color: whiteColor + " !important",
        border: "1px solid rgba(" + hexToRgb(grayColor[4]) + ", .54)"
    },
    switchIconChecked: {
        borderColor: darkcyanColor[1],
        transform: "translateX(0px)!important"
    },
    switchBar: {
        width: "30px",
        height: "15px",
        backgroundColor: "rgb(" + hexToRgb(grayColor[1]) + ")",
        borderRadius: "15px",
        opacity: "0.7!important"
    },
    switchChecked: {
        "& + $switchBar": {
            backgroundColor: "rgba(" + hexToRgb(turquoiseColor[0]) + ", 1) !important"
        },
        "& $switchIcon": {
            backgroundColor: darkcyanColor[1] + "!important",
            boxShadow: "0 1px 3px 1px rgba(" + hexToRgb(darkcyanColor[1]) + ", 0.4)",
            color: whiteColor + " !important",
            border: "1px solid rgba(" + hexToRgb(darkcyanColor[1]) + ", .54)"
        }
    }
    
};

export default customCommonStyle;
