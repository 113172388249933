import React from "react";
import { Switch, Route,Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import LoginPage        from "views/MngSystem/Login/Login.js";
import RegisterPage     from "views/MngSystem/Register/Register.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import register from "assets/img/register.jpeg";
import login from "assets/img/login.jpeg";

const useStyles = makeStyles(styles);

export default function Pages(props) {

  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
  }, []);

  const getBgImage = () => {
    if (window.location.pathname.indexOf("/auth/register") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/auth/login") !== -1) {
      return login;
    } 
  };

  const getActiveRoute = () => {
    let activeRoute = "Default Brand Text";
    
    //routes.js設定以外の画面項目
    if(window.location.href.indexOf("/auth/login") !== -1){
      return "ログイン";
    }else if(window.location.href.indexOf("/auth/register") !== -1){
      return "新規登録";
    }

    return activeRoute;
  };

  return (
    <div>
      <AuthNavbar brandText={getActiveRoute()} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Switch>
            <Route path="/auth/login" component={LoginPage} />
            <Route path="/auth/register" component={RegisterPage} />
            <Redirect from="/" to="/auth/login" />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <Footer white />
        </div>
      </div>
    </div>
  );
  
}
