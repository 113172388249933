import {
    ERROR_TIMEOUT,
    ERROR_UNAUTHORIZED,
    ERROR_BAD_REQUEST,
} from 'variables/config.js';

import { userInfo } from 'variables/runtimeInfos.js';
// import { clearLoginUserInfo } from 'layouts/initSystemData.js';

const createOptions = (method, data, token) => ({
    method,
    headers: new Headers({
        //'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token
    }),
    body: data
});

const createError = code => ({ status: { code } });

export default function Upload(url, files, keys, infos = undefined, method = 'POST', timeout = 600000) {
    //Tokenの取得
    const {token} = userInfo;

    // console.log(token);

    const data = new FormData();

    data.append('infos', infos);

    if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let key = keys[i];
            data.append('files', file, file.name);
            data.append('keys', key);
        }
    } else {
        data.append('files', []);
        data.append('keys', []);
    }

    const options = createOptions(method, data, token);

    return new Promise(async (resolve, reject) => {
        let timer = setTimeout(() => {
            return reject(createError(ERROR_TIMEOUT));
        }, timeout);

        if (token === null) {
            return reject(createError(ERROR_UNAUTHORIZED));
        }

        // console.log(options);
        var response;
        try{
            response = await fetch(url, options);
        }catch(ex){
            // console.log(ex);
            return reject(ex);
        }        

        clearTimeout(timer);

        const json = await response.json();

        if (response.ok || response.status === ERROR_BAD_REQUEST) {
            return resolve(json);
        }

        return reject(json);
    })
}
