/**
 *　映像一覧選択
 * 
 */
/* eslint-disable */
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

// import VideoThumbnail from "react-video-thumbnail";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// exp components
import NBrootThumbnailImage from "components/@NBrootThumbnailImage/NBrootThumbnailImage.js";
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootSnapShot from "components/@NBrootSnapShot/NBrootSnapShot.js";

import { BASE_URL, VALIDATE_MSG } from 'variables/config.js';
import Upload from "utils/service/upload.js"
import cEmptyToNull from "utils/functions/cEmptyToNull"
import millisecondFormat from "utils/functions/millisecondFormat";
import styles from "assets/jss/material-dashboard-pro-react/views/CommonPopup/CommonPopupStyle";

import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";
import isNotPassNumberRangeChk from "utils/validations/isNotPassNumberRangeChk";
// メモリ中企業リスト
import { userInfo, corpsInfo } from 'variables/runtimeInfos.js';


export default function VideoUploader(props) {

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { info, setInfo } = props;
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  // メールアドレス  ユーザ名 	フリカナ 	パスワード 
  var t = {
    crop: userInfo.userlevel === 2 ? '' : userInfo.corpid,           //企業ID
    name: "",
    src: "",
    desp: "",       //当画面未使用
    price: 0,       //当画面未使用
    blob: null,
    snapshot: "",
    snapShotAt: 0,
    chk_src:"",
    msg_src:"",
    chk_crop:"",
    msg_crop:"",
    chk_name:"",
    msg_name:"",
  };
  const [vals, setVals] = React.useState(t);

  const open = info[0];

  const onDrop = useCallback((acceptedFiles) => {

    setIsLoading(true);

    acceptedFiles.forEach((file) => {

      let tVals = vals;
      //初期化
      tVals.src = '';
      tVals.msg_src = '';

      //ファイル関連チェック
      // http://jsfiddle.net/dsbonev/cCCZ2/
      // console.log(file.size);
      //サイズのチェック
      if(file.size > 200000000){
        tVals.msg_src = VALIDATE_MSG.MSG_103;
        var tResult = Object.assign({}, tVals);
        setVals(tResult);
        setIsLoading(false);
        return;
      }
      //タイプのチェック

      //処理結果のチェックなど

      //クリア
      var tResult = Object.assign({}, tVals);
      setVals(tResult);
      setTimeout(() => {
        //セット　ヴィデオ処理開始
        var fileURL = URL.createObjectURL(file);
        tVals.src = fileURL;
        tVals.blob = file;
        tResult = Object.assign({}, tVals);

        setVals(tResult);

      }, 100)

    })

  }, [vals]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });


  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (open) {
      setVals(t);
    }
  }, [open]);    //[] 子画面の場合、毎回開く時、処理を行う

  //検索条件部
  //企業変更の場合
  const handleChangeCropsStart = (e) => {
    let tVals = vals;
    //選択情報をSTATEに保存
    if (e.target.name === 'corpSelect')
      tVals.crop = e.target.value;
    
    validate(tVals, 'crop', e.target.value);

    var tResult = Object.assign({}, tVals);

    setVals(tResult);

  }

  //編集発生する時
  const handleEditOnChange = (name, value) => {
    let tVals = vals;
    //編集内容の行を取得
    if (name === "snapShotAt") {
      tVals[name] = (isNaN(Number(value)) ? 0 : Number(value));
    } else {
      tVals[name] = value;
    }
    validate(tVals, name, value);
    //Copy by value
    var tResult = Object.assign({}, tVals);
    //情報をSTATEに保存する。
    setVals(tResult);
  }

  //入力チェック
  const validate = (tVals, name, value) => {
    //チェック処理
    if (name === 'crop') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'name') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      // } else if (isNotPassCharTypeChk(value, "zenkaku")) {
      //   tVals["chk_" + name] = "error";
      //   tVals["msg_" + name] = VALIDATE_MSG.MSG_003;
      } else if (isNotPassLengthChk(value, null, 100)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'src') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_105;
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }
  };

    
  //入力チェック
  const validateAll = () => {  
    var tVals = vals;
    //一括チェック
    validate(tVals,'crop',tVals.crop);
    validate(tVals,'name',tVals.name);
    validate(tVals,'src',tVals.src);
    
    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if(tVals["chk_crop"] === "error" ||
      tVals["chk_src"] === "error" ||
      tVals["chk_name"] === "error" ){
      
      return true;
    }


    return false;

  };

  //Reloadボタン押す
  const handleClickReloadBtn = () => {


    var tVals = vals;
    var tmp = tVals.src;

    //チェック
    if(tmp === "") return;


    tVals.src = "";
    setIsLoading(true);
    var tResult = Object.assign({}, tVals);
    // console.log(tResult);
    setVals(tResult);
    setTimeout(() => {

      tVals.src = tmp;
      tResult = Object.assign({}, tVals);
      setVals(tResult);
      //setIsLoading(false);

    }, 500)


  }

  //保存ボタン押す
  const handleClickUploadBtn = () => {
    //チェック
    var tVals = vals;

    if(validateAll()){

      var tResult = Object.assign({}, tVals);
      // console.log(tResult);
      setVals(tResult);
      return;

    }



    //[当画面表示FLG ,　情報,　IsAfterOK]
    const url = BASE_URL + '/API/VideoFileUpload';
    var info = {
      crop: vals.crop,
      name: vals.name,
      desp: vals.desp,
      price: vals.price,
      duration: vals.duration,
      w: vals.w, 
      h: vals.h,
      snapShotAt: vals.snapShotAt,
    };
    var strInfo = JSON.stringify(info);

    //STEP1 File Upload 
    const data = [];
    const key = [];
    data.push(vals.blob);
    key.push("mp4");

    data.push(vals.snapshot);
    key.push("SnapShot");

    setIsLoading(true);
    //---------API呼び出す処理----------------
    Upload(url, data, key, strInfo).then(resp => {
      // 正常系
      // console.log("結果-----------");
      // console.log(resp);
      if (resp.status === 0) {

        //[当画面表示FLG ,　情報,　IsAfterOK]
        var tReturnVal = [false, {}, true];

        setInfo(tReturnVal);

      } else {

        setMsgInfo([true, resp.msg, 'warning']);

      }
      setIsLoading(false);
    }).catch(resp => {
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_902, 'danger']);
    });
  }

  //キャンセルボタン押す
  const handleClickCancelBtn = () => {

    var tVals = vals;
    // console.log(tVals);

    //[当画面表示FLG ,　情報,　IsAfterOK]
    var tReturnVal = [false, {}, false];
    setInfo(tReturnVal);

  }

  const processThumbnailHandler = (data) =>{
    // console.log(thumbnail);
    // console.log(data);
    var tVals = vals;
    if(data.thumbnail === undefined || data.thumbnail === "") {
      tVals.src = '';
      tVals.chk_src = 'error';
      tVals.msg_src = VALIDATE_MSG.MSG_104;
      setVals(tVals);
      setIsLoading(false);
      return;
    }

    tVals.snapshot      = base64ToBlob(data.thumbnail);
    tVals.duration      = data.duration;
    tVals.w             = data.w;
    tVals.h             = data.h;
    setVals(tVals);
    setIsLoading(false);
  }

  function base64ToBlob(urlData) {
    var arr = urlData.split(',');
    var mime = arr[0].match(/:(.*?);/)[1] || 'image/png';
    // 去掉url的头，并转化为byte
    var bytes = window.atob(arr[1]);
    // 处理异常,将ascii码小于0的转换为大于0
    var ab = new ArrayBuffer(bytes.length);
    // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
    var ia = new Uint8Array(ab);

    for (var i = 0; i < bytes.length; i++) {
      ia[i] = bytes.charCodeAt(i);
    }

    return new Blob([ab], {
      type: mime
    });
  }

  return (
    <Dialog fullWidth={true} maxWidth={'md'} onClose={handleClickCancelBtn} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" className={classes.dialogHeader} onClose={handleClickCancelBtn}>
        マイコンテンツ追加
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="warning"
          onClick={handleClickCancelBtn}
          style={{ float: "right" }}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent>
        <GridItem className={classes.modalUploadDialog} xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={6}>
          </Grid>
          <Grid container item xs={12} sm={12} >
            <Grid item xs={12} sm={6}>
              <br />              <br />
              <Box {...getRootProps()} style={{ minHeight: 320, maxHeight: 320 }}>
                {vals.src !== "" ?
                  <Box style={{ paddingLeft: "15px" }}>
                    <NBrootThumbnailImage
                      videoUrl={vals.src}
                      snapshotAtTime={vals.snapShotAt}
                      thumbnailHandler={processThumbnailHandler}
                      width={320}
                      height={320}
                    />
                  </Box>
                  :
                  <Button color="warning" style={{ marginLeft: 100, marginTop: 100 }}>
                    ファイル選択
                  </Button>       
                }
                <input {...getInputProps()} accept="video/mp4" />
              </Box>
              <Box className={classes.selectHelpTextErr}>{vals.msg_src}</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              {userInfo.userlevel === 2 &&
                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: "80%" }}
                    className={classes.selectFormControl}
                    disabled={userInfo.userlevel === 2 ? false : true}>
                    <InputLabel htmlFor="corpSelect" className={vals.chk_crop === "error" ? classes.selectLabelErr : classes.selectLabel}>
                      企業 *
                    </InputLabel>
                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      value={vals.crop}
                      classes={{ select: classes.select }}
                      onChange={(e) => handleChangeCropsStart(e)}
                      inputProps={{ name: "corpSelect" }}>
                      <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                        企業を選択
                        </MenuItem>
                      {corpsInfo ? corpsInfo.lst.map((item, key) =>
                        <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={key}>
                          {item.corpname}
                        </MenuItem>
                      ) : null}
                    </Select>
                    <Box className={classes.selectHelpTextErr}>{vals.msg_crop}</Box>
                  </FormControl>                
                </Grid>
              }
              <Grid item xs={12} sm={12}>
                <CustomInput
                  labelText="映像名称 *"
                  success={vals.chk_name === "success"}
                  error={vals.chk_name === "error"}
                  id="name"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: vals.name,
                    onChange: event => {
                      handleEditOnChange("name", event.target.value);
                    }
                  }}
                  helperText={vals.msg_name}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <br/>
                <NBrootSnapShot
                  labelText="サムネイル取得時間（秒） *"
                  id="snapShotAt"
                  maxDuration={vals.duration ? parseInt(vals.duration/1000) : 0}
                  value={vals.snapShotAt}
                  onChange={ handleEditOnChange }
                  onReload={ handleClickReloadBtn }
                />
              </Grid>
            </Grid>
          </Grid>
        </GridItem>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancelBtn} color="warning">
          キャンセル
          </Button>
        <Button onClick={handleClickUploadBtn} color="warning">
          アップロード
        </Button>
      </DialogActions>
      {/* 共通ダイアログ画面 */}
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Dialog>

  );
}
