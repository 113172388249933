/**
 *　会社編集
 * 
 */
/* eslint-disable */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// exp components
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";

import { VALIDATE_MSG } from 'variables/config.js';
// import Service from "utils/service/service.js"
// import { BASE_URL } from 'variables/config.js';

// import cNullToEmpty from "utils/functions/cNullToEmpty";

// import verifyLength from "utils/validations/verifyLength";

import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassPhoneChk from "utils/validations/isNotPassPhoneChk";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";


import styles from "assets/jss/material-dashboard-pro-react/views/CommonPopup/CommonPopupStyle";

export default function CorpListAddPopup(props) {

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  const { info, setInfo } = props;
  const open = info[0];

  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);

  //画面編集項目＜初期状態＞
  // 企業	郵便番号	住所	連絡方法
  var t = {
    id            : "",
    name          : "",       //入力項目
    zip           : "",
    address       : "",
    telephone     : "", 
    msg_name      : "",       //MSG項目
    msg_zip       : "",
    msg_address   : "",
    msg_telephone : "", 
    chk_name      : "",       //CHECK結果＜CSS影響＞
    chk_zip       : "", 
    chk_address   : "",
    chk_telephone : ""
  };
  const [vals, setVals] = React.useState([t]);      //[] !!!変更後、画面render発生させるため、array使用

  //初期化処理
  React.useEffect(() => {
    if (open){

    }

    //Clean
    return () =>{
      setVals([t]);
    }
  }, [open]);    //毎回OPENする時処理を行う

 
  //編集発生する時
  const handleEditOnChange = (name,value) => {

    let tVals = vals[0];

    //STATE内容、項目名、入力値
    validate(tVals,name,value);

    //情報をSTATEに保存する。
    tVals[name]  = value;
    
    setVals([tVals]);
    
  }

  function validate(tVals,name,value){
    //チェック処理
    if(name === 'name'){
      isNotPassNullChk(value) ? 
        (tVals["chk_" + name] = "error",tVals["msg_" + name] = VALIDATE_MSG.MSG_001) : 
      // isNotPassCharTypeChk(value,"zenkaku") ? 
      //   (tVals["chk_" + name] = "error",tVals["msg_" + name] = VALIDATE_MSG.MSG_003) : 
      isNotPassLengthChk(value,null,100) ? 
        (tVals["chk_" + name] = "error",tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?",100)) : 
      (tVals["chk_" + name]  = "success",tVals["msg_" + name]  = "");

    }else if(name === 'zip'){
      isNotPassNullChk(value) ? 
        (tVals["chk_" + name] = "error",tVals["msg_" + name] = VALIDATE_MSG.MSG_001) : 
      isNotPassCharTypeChk(value,"numeric") ? 
        (tVals["chk_" + name] = "error",tVals["msg_" + name] = VALIDATE_MSG.MSG_007) : 
        isNotPassLengthChk(value,null,7) ? 
        (tVals["chk_" + name] = "error",tVals["msg_" + name] =  VALIDATE_MSG.MSG_200.replace("?",7)) : 
        (tVals["chk_" + name]  = "success",tVals["msg_" + name]  = "");

    }else if(name === 'address'){
      isNotPassNullChk(value) ? 
        (tVals["chk_" + name] = "error",tVals["msg_" + name] = VALIDATE_MSG.MSG_001) : 
      // isNotPassCharTypeChk(value,"zenkaku") ? 
      //   (tVals["chk_" + name] = "error",tVals["msg_" + name] = VALIDATE_MSG.MSG_003) : 
      isNotPassLengthChk(value,null,255) ? 
        (tVals["chk_" + name] = "error",tVals["msg_" + name] =  VALIDATE_MSG.MSG_200.replace("?",255)) : 
        (tVals["chk_" + name]  = "success",tVals["msg_" + name]  = "");

    }else if(name === 'telephone'){
      isNotPassNullChk(value) ? 
        (tVals["chk_" + name] = "error",tVals["msg_" + name] = VALIDATE_MSG.MSG_001) : 
      isNotPassPhoneChk(value) ? 
        (tVals["chk_" + name] = "error",tVals["msg_" + name] = VALIDATE_MSG.MSG_100) : 
        (tVals["chk_" + name]  = "success",tVals["msg_" + name]  = "");

    }
  }


  //保存ボタン押す
  const handleClickOKBtn = () => {

    let tVals = vals[0];
    //一括チェック
    validate(tVals,'name',tVals.name);
    validate(tVals,'zip',tVals.zip);
    validate(tVals,'address',tVals.address);
    validate(tVals,'telephone',tVals.telephone);

    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if( tVals["chk_name"] === "error" ||
        tVals["chk_zip"] === "error" ||
        tVals["chk_address"] === "error" ||
        tVals["chk_telephone"] === "error" ){

          setVals([tVals]);
          return;
    }

    //送信処理
    var tCorpListDetailInfo = [
      //当画面表示FLG    
      false,
      //当画面編集情報       
      vals[0],
      //IsAfterOK
      true
    ]
    //前画面に渡す、画面閉じる
    setInfo(tCorpListDetailInfo);
  }

  //キャンセルボタン押す
  const handleClickCancelBtn = () => {
    var tCorpListDetailInfo = [
      //当画面表示FLG    
      false,
      //当画面編集情報  キャンセルの場合、セットする必要がない     
      {},
      //IsAfterOK
      false
    ]
    setInfo(tCorpListDetailInfo);
  }

  return (
    <Dialog onClose={handleClickCancelBtn} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" className={classes.dialogHeader} onClose={handleClickCancelBtn}>
        企業追加
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="warning"
          onClick={handleClickCancelBtn}
          style={{ float: "right" }}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>

      <DialogContent>
        <GridItem xs={12} sm={12}>
          <CustomInput
            labelText="企業名 *"
            id="name"
            success={vals[0].chk_name === "success"}
            error={vals[0].chk_name === "error"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value     : vals[0].name,  
              onChange  : event => handleEditOnChange("name", event.target.value)
            }}
            helperText={vals[0].msg_name}     
          />
          <CustomInput
            labelText="郵便番号 *"
            id="zip"
            success={vals[0].chk_zip === "success"}             // chkResult + Json.prop
            error={vals[0].chk_zip === "error"}
            value={vals[0].zip}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value     : vals[0].zip,  
              onChange: event => {
                handleEditOnChange("zip", event.target.value);
              }
            }}
            helperText={vals[0].msg_zip}  
          />
          <CustomInput
            labelText="住所 *"
            id="address"
            success={vals[0].chk_address === "success"}             // chkResult + Json.prop
            error={vals[0].chk_address === "error"}
            value={vals[0].address}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value     : vals[0].address,                       //set
              onChange  : event => {
                handleEditOnChange("address", event.target.value);
              }
            }}
            helperText={vals[0].msg_address}  
          />
          <CustomInput
            labelText="電話番号 *"
            id="telephone"
            success={vals[0].chk_telephone === "success"}             // chkResult + Json.prop
            error={vals[0].chk_telephone === "error"}
            value={vals[0].telephone}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value     : vals[0].telephone,                       //set
              onChange  : event => {
                handleEditOnChange("telephone", event.target.value);
              }
            }}
            helperText={vals[0].msg_telephone}  
          />
        </GridItem>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickCancelBtn} color="warning">
          キャンセル
          </Button>
        <Button onClick={handleClickOKBtn} color="warning">
          保存
          </Button>
      </DialogActions>
      {/* 共通ダイアログ画面 */}
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Dialog>

  );
}
