import 'date-fns';
import React from 'react';
import PropTypes from "prop-types";
import DateFnsUtils from '@date-io/date-fns';
import ja from "date-fns/locale/ja";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import FormHelperText from "@material-ui/core/FormHelperText";

import timePickerStyle from "assets/jss/material-dashboard-pro-react/components/timePickerStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);


const getDate = (val) => {
  if(val){
    val = '2020-01-01T' + val + ':00'
    return new Date(val);
  }
  return null;
}

export default function NBrootsTimePicker(props) {
  const classes = useStyles();
  const {
    labelText,
    id,
    success,
    error,
    value,
    onChange,
    helperText
  } = props;

  const handleDateChange = (date) => {
    onChange(id, moment(date).format("HH:mm"))
  };

  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
      <ThemeProvider theme={timePickerStyle}>
        <TimePicker
          // variant='inline'
          okLabel='ok'
          cancelLabel='キャンセル'
          ampm={false}
          id={id}
          label={labelText}
          value={ getDate(value) }
          onChange={ handleDateChange }
          error={helperText}
        />
        {helperText !== undefined ? (
          <FormHelperText id={id + "-text"} className={helpTextClasses}>
            {helperText}
          </FormHelperText>
        ) : null}
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

NBrootsTimePicker.propTypes = {
  labelText: PropTypes.node,
  id: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.any.isRequired,
};
