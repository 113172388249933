import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// exp components
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";
import Service from "utils/service/service.js"
import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";
// import isNotPassEmailChk from 'utils/validations/isNotPassEmailChk';
// import cNullToEmpty from "utils/functions/cNullToEmpty";
import styles from "assets/jss/material-dashboard-pro-react/views/UserInfo/passwordUpdate.js";
import { BASE_URL ,VALIDATE_MSG} from 'variables/config.js';
// メモリ中企業リスト
import { userInfo } from 'variables/runtimeInfos.js';
const useStyles = makeStyles(styles);

export default function PasswordUpdate(props) {
  
  const classes = useStyles();
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);
  

  //画面編集項目＜初期状態＞
  var t = {

    idCompany: userInfo.corpid,
    // companyName: userInfo.corpname,
    // email       : userInfo.email,
    //入力項目
    // name: "",
    // kana: "",
    oldpassword:"",
    password: "",
    newconfirmpassword:"",
    //MSG項目
    msg_oldpassword: "",
    msg_password: " ",
    msg_newconfirmpassword: "",
    //CHECK結果
    chk_oldpassword:"",
    chk_password:"",
    chk_newconfirmpassword: "",
  };
  const [vals, setVals] = React.useState(t);

  //初期化処理
  React.useEffect(() => {

    //進捗画面
    setIsLoading(true);
    //情報のロード
    // handleClickStartSave();
    formatDetailData(props);

  }, [props]);    //[] 忘れないよう…　初期化

  const formatDetailData = (tProps) => {
    var url = BASE_URL + "/API/employee/Get";
    //　検索条件  前画面から取得
    var data = {
      id: userInfo.userid
    };
    // console.log(data);
    var tVals;
    Service(tProps, url, data).then(resp => {
      // console.log(resp)
      // 正常系
      if (resp.status === 0) {
        var info = resp.infos;
        //画面編集項目（SET）
        tVals = {
          id: info.id,
          // name  : cNullToEmpty(info.name),
          // kana  : cNullToEmpty(info.kana),
          oldpass:info.password,
          oldpassword:"",
          password:"",
          newconfirmpassword:"",

          msg_oldpassword: "",
          msg_password: " ",
          msg_newconfirmpassword: "",

          chk_oldpassword:"",
          chk_password:"",
          chk_newconfirmpassword: "",

          updateIndex: info.updateIndex,       //更新の場合、必ず必須
        };
        // //Copy　STATE情報　By　Value
        // var tResult = JSON.parse(JSON.stringify(tVals));

        setVals(tVals);

      } else {
        //その他APIエラー   
        tVals = {
          //入力項目
          // id: "",
          // email: "",
          // name: "",
          // companyName: "",
          // kana: "",
          // password: "",
          // admin: 0,
        };

        setVals(tVals);

        setMsgInfo([true, resp.msg, 'warning']);
      }
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };

  //変更ボタン押す
  const updateClick = () => {
    let tVals = vals;
    //一括チェック
    validate(tVals, 'oldpassword', tVals.oldpassword);
    validate(tVals, 'password', tVals.password);
    validate(tVals, 'newconfirmpassword', tVals.newconfirmpassword);

    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if (tVals["chk_oldpassword"] === "error" ||
      tVals["chk_password"] === "error" ||
      tVals["chk_newconfirmpassword"] === "error") {

        var tResult = Object.assign({}, tVals);
        setVals(tResult);
     
      return;
    }
    

    setIsLoading(true);

    var url = BASE_URL + "/API/employee/Update";

    //　入力データ
    var data = {
      // idCompany   : userInfo.corpid,
      id: userInfo.userid,
      
      password: tVals.password,
      updateIndex: tVals.updateIndex,    //更新の場合、必ず必須
    };
    // console.log(data);

    Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        setMsgInfo([true, resp.msg, 'success']);
        //情報のロード
        formatDetailData();
        // formatLstData(search);
      } else {
        setMsgInfo([true, resp.msg, 'danger']);
      }
      //進捗画面を閉じる
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  
  };

  //編集発生する時
  const handleEditOnChange = (name, value) => {
    let tVals = vals;
    //STATE内容、項目名、入力値
    validate(tVals, name, value);

    //情報をSTATEに保存する。
    tVals[name] = value;

    var tResult = Object.assign({}, tVals);
    setVals(tResult);

  }

  function validate(tVals, name, value) {
    //チェック処理
    if (name === 'oldpassword') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassCharTypeChk(value, "alphanumeric")) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_006;
      } else if (isNotPassLengthChk(value, 6, null)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_201.replace("?", 6);
      } else if (isNotPassLengthChk(value, null, 15)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 15);
      } else if(value!==tVals.oldpass){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_008;
      }else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }else if (name === 'password') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassCharTypeChk(value, "alphanumeric")) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_006;
      } else if (isNotPassLengthChk(value, 6, null)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_201.replace("?", 6);
      } else if (isNotPassLengthChk(value, null, 15)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 15);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }else if (name === 'newconfirmpassword') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassCharTypeChk(value, "alphanumeric")) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_006;
      } else if (isNotPassLengthChk(value, 6, null)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_201.replace("?", 6);
      } else if (isNotPassLengthChk(value, null, 15)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 15);
      } else if(value!==tVals.password){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_009;
      }else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }
  }



  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={8} md={6}>
          <Card>
            <CardHeader color="warning" icon>
              {/* <CardIcon color="warning">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>パスワード変更</h4>*/}
            </CardHeader>
            <CardBody>
              <form>
                <CustomInput
                  labelText="現在のパスワード *"
                  id="oldpassword"
                  success={vals.chk_oldpassword === "success"}             // chkResult + Json.prop
                  error={vals.chk_oldpassword === "error"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: vals.oldpassword,
                    onChange: event => {
                      handleEditOnChange("oldpassword", event.target.value);
                    },

                    // type: "email"
                  }}
                  helperText={vals.msg_oldpassword}
                />
                <CustomInput
                  labelText="新しいパスワード *"
                  id="password"
                  success={vals.chk_password === "success"}             // chkResult + Json.prop
                  error={vals.chk_password === "error"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  // value={registerPassword}
                  inputProps={{
                    value: vals.password,
                    onChange: event => {
                      
                      handleEditOnChange("password", event.target.value);
                    },
                    type: "password",
                    autoComplete: "off"
                  }}
                  helperText={vals.msg_password}
                />
                <CustomInput
                   labelText="新しいパスワードを再入力 *"
                   id="newconfirmpassword"
                  success={vals.chk_newconfirmpassword === "success"}             // chkResult + Json.prop
                  error={vals.chk_newconfirmpassword === "error"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: vals.newconfirmpassword,
                    onChange: event => {
                      handleEditOnChange("newconfirmpassword", event.target.value);
                    },
                    type: "password",
                    autoComplete: "off"
                  }}
                  helperText={vals.msg_newconfirmpassword}
                />
                <div style={{ textAlign: "right", display: "block" }}>
                  <Button
                    round
                    color="warning"
                    onClick={updateClick}
                    className={classes.registerButton}
                  >
                    変更
              </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* 共通ダイアログ画面 */}
      <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </div>
  );
}
