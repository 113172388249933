import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import {
  warningColor,
} from "assets/jss/material-dashboard-pro-react.js";


NBrootsDialogProcess.propTypes = {
  open                    : PropTypes.bool,
};

export default function NBrootsDialogProcess(props) {

  return (

    <Box>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <CircularProgress style={{color: warningColor[0] , margin: '20px'}} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}