import {
  warningColor,
  whiteColor,
  tooltip,
  cardTitle
} from 'assets/jss/material-dashboard-pro-react.js';

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.js';
import customCommonStyle from "assets/jss/material-dashboard-pro-react/customCommonStyle.js";


const terminalsListStyle = {
  ...customSelectStyle,
  ...hoverCardStyle,
  ...customCommonStyle,
  tooltip:{
    ...tooltip,
    marginTop: '0px',
    marginBottom: '3px'
  },
  cardTitle: {
    ...cardTitle,
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    color:whiteColor,
    textDecoration: "none"
  },
  card:{
    marginBottom: "0px"
  },
  cardBody: {
    padding: '0.4375rem 20px',
  },
  searchBox:{
    textAlign:'left',
  },
  optBox:{
    textAlign :'right',
  },
  pageJumpBox:{
    textAlign:'center',
    height:'40px',
  },
  tableBox:{
    height    : 'calc(100% - 180px)',
    width     : 'calc(100% - 50px)',
    position  : 'absolute',
    paddingTop: '10px',
  },
  tableCardBody:{
    padding       : '10px',
    height        : '100%',
    width         : '100%',
    borderRadius  : '6px',
    //display       : 'block',
    position      : 'relative',
    // position      : 'absolute'
    // marginTop: '10px',

    // width:'100%',
    // marginTop: '10px',
    // overflowY:'hidden',
    // overflowX: 'hidden',
  },
  table:{
    height    : '100%',
    width     : '100%',
    marginTop : '0px',
    display   : 'block',
    // position  : 'fixed',
    overflowX : 'auto',
    overflowY : 'auto'
  },
  /*テーブルヘッダ部ICONのSTYLE*/
  tableHeadIcon: {
    margin          : 0,
    '&:hover': {
        backgroundColor: '#EFEFEF',
    }

  },
  tableHeadSwapIcon: {
    color        : '#c3c3c3',
    height       : '17px',
    width        : '17px',
    '&:hover': {
          backgroundColor: '#EFEFEF',
      }
  },
  /*テーブルヘッダ部のSTYLE*/
  tableHead: {
    // fontSize          : '14px',
    // backgroundColor   : '#f8f8f8',
    padding           : '10px 10px 10px 10px',
    minheight         : '50px',
    minwidth          : '80px',
    height            : '50px',
    // color             : '#383838',
    // fontWeight        : 'bold',
    lineHeight        : '1rem',
    verticalAlign     : 'middle',
    textAlign         : 'center',
    'z-index'         : '10',
    'white-space'     : 'nowrap',
  },
  tableSortHeadWrapper: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  /*テーブルBODY部のSTYLE*/
  tableCell:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'center'
  },
  /*テーブルBODY部のSTYLE*/
  tableCellLeft:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'left'
  },
  /*テーブルBODY部のSTYLE*/
  tableCellRight:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'right'
  },
  /*テーブルBODY部のSTYLE*/
  tableBody:{
    maxheight        : '500px',
    overflow         : 'visible'
  },
  /*テーブル行のSTYLE*/
  tableRow:{
    marginLeft       : '3px',
    backgroundColor  : '#FFFFFF',
    height           : '40px',
    '&:hover': {
        backgroundColor: '#FFFDF2',
      }
  },
  inputLoopNum: {
    color: "inherit",
    paddingTop: "0px",
    margin: "0 0 10px 0",
    "& p": {
      display: "inline",
      marginBottom: "1px",
      marginTop: "0px"
    }
  },
  //操作ボタン
  actionButton: {
    //margin: '10px 0px 0px 10px',
    margin: '5px',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px'
    }
  },
  inputName: {
    margin: '0 0 5px 0',
    paddingTop: '10px'
  },  
  dialogHeader: {
    backgroundColor: warningColor[0] + '!important',
    color: whiteColor + ' !important',
  }
};

export default terminalsListStyle;
