export const BASE_URL = 'https://cccloud.shop:8443'
//export const BASE_URL = 'https://localhost:8443'

export const PAGINATION_PREV_NAME = '＜';
export const PAGINATION_NEXT_NAME = '＞';

/**
 * サービス関連設定
 */
export const REQUEST_TIMEOUT = 120000;
/**
 * エラーコード
 */
export const ERROR_BAD_REQUEST = 400;
export const ERROR_UNAUTHORIZED = 401;
export const ERROR_FORBIDDEN = 403;
export const ERROR_NOT_FOUND = 404;
export const ERROR_TIMEOUT = 408;
export const ERROR_CONFLICT = 409;
export const ERROR_INTERNAL_SERVER_ERROR = 500;
export const ERROR_SERVICE_UNAVAILABLE = 503;

export const CORP_ID_SELECTED_ALL = '113f3f1e-9354-11ea-a338-000c294cd73a';

export const VALIDATE_MSG = {
    MSG_001 : "ご入力ください。",
    MSG_002 : "ご選択ください。",
    MSG_003 : "全角文字で入力してください。",
    MSG_004 : "全角ひらがなで入力してください。",
    MSG_005 : "全角カタカナで入力してください。",
    MSG_006 : "半角英数字で入力してください。",
    MSG_007 : "半角数字で入力してください。",
    MSG_008 : "パスワードが一致しません。",
    MSG_009 : "パスワードが一致しません。",
    MSG_010 : "HH:mm形式で入力してください。",
    // MSG_011 : "開始時間が停止時間を超えています。",
    MSG_011 : "終了時間は開始時間より後ろの時間にしてください。",
    MSG_012 : "プレイリストの項目を追加してください。",
    MSG_013 : "{0}行目の「{1}」と時間が重複しています。開始時間と停止時間を修正してください。",

    MSG_100 : "電話番号は10桁～11桁で入力してください。",
    MSG_101 : "メールアドレスもう一回確認してください。",
    MSG_102 : "メールアドレスまたはパスワードが不正です。",
    MSG_103 : "２００Ｍ以下のファイルを選択してください。",
    MSG_104 : "ファイル不正、他のファイルを選択してください。",
    MSG_105 : "ファイルを選択してください。",

    MSG_200 : "最大?桁で入力してください。",
    MSG_201 : "?桁以上で入力してください。",
    MSG_202 : "一つ以上の端末を選択してください。",
    MSG_203 : "?桁以上の数字で入力してください。",
    MSG_204 : "?桁以下の数字で入力してください。",
    MSG_205 : "{0}桁～{1}桁の数字で入力してください。",
    MSG_206 : "{0}～{1}の数字を入力してください。",

    MSG_501 : "更新処理完了しました。",
    MSG_502 : "更新処理失敗しました。",
    MSG_503 : "削除しても宜しいでしょうか？",
    MSG_504 : "削除処理完了しました。",
    MSG_505 : "削除処理失敗しました。",
    MSG_506 : "起動しても宜しいでしょうか？",
    MSG_507 : "停止しても宜しいでしょうか？",
    MSG_508 : "更新しても宜しいでしょうか？",
    MSG_509 : "更新処理失敗しました",
    MSG_510 : "リセットしても宜しいでしょうか？",
    MSG_511 : "同期しても宜しいでしょうか？",
    MSG_512 : "企業を変更すると、編集内容すべて初期化してもよろしいでしょうか？",
    MSG_513 : "企業を選択してください。",
    MSG_514 : "連携端末の場合、一つだけ映像（1回再生）を選択することが可能です。",
    MSG_515 : "編集途中ですが、終了しても宜しいでしょうか？",

    MSG_901 : "情報取得失敗しまた。",
    MSG_902 : "アップロード失敗しました。",
};

export const STATUS_CODE = {
    OK                  : 0,
	NOT_FOUND           : 1,
	UK_COMPANY_NAME     : 2,
	UK_EMPLOYEE_EMAIL   : 3,
	FAIL                : 1000,
};
