import {
  REQUEST_TIMEOUT,
  ERROR_TIMEOUT,
  // ERROR_CONFLICT,
  // ERROR_UNAUTHORIZED,
  // ERROR_BAD_REQUEST,
} from 'variables/config.js';

import { userInfo } from 'variables/runtimeInfos.js';
import { clearLoginUserInfo } from 'layouts/initSystemData.js';

const createOptions = (method, data, token) => ({
  method,
  headers: new Headers({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }),
  body: JSON.stringify(data),
});

const createGetOptions = (method, data, token) => ({
  method,
  headers: new Headers({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }),
});

const createError = status => ({ status });

export default function Service(props, url, data, method = 'POST', timeout = REQUEST_TIMEOUT) {

  const {token} = userInfo;
  var options = "";
  
  if(method === 'GET'){
    options = createGetOptions(method, token);
  }else{
    options = createOptions(method, data, token);
  }

  return new Promise(async (resolve, reject) => {

    let timer = setTimeout(() => {
      return reject(createError(ERROR_TIMEOUT));
    }, timeout);

    const response = await fetch(url, options);
    clearTimeout(timer);

    const json = await response.json();

    // 权限不足时，删除token，返回到登陆画面
    switch (response.status) {
      case 401:
        if(((props !== undefined) && (props !== null)) && ((props.history !== undefined) || (props.history === null))){
           // 清除token
           clearLoginUserInfo();

           // 返回到登陆画面
           props.history.push('/auth/login');
         }
        break;
      case 403:
      case 404:
      case 500:
        // exception
        break;
      default:
        break;
    }

    if (response.ok) {
      // 查询成功后，更新token信息
      let newToken = response.headers.get('Authorization');
      if(!((token === undefined) || (token === null) || (token === "") || (token === "null"))){
        userInfo.token = newToken;
      }

      return resolve(json);
    }

    return reject(response);
  });
}
