/**
 Time Compare
    2:3
    02:3
    02:03
 
@param  a       Compare対象
@param  b       Compare対象
@return int     Compare結果
                -1 : a < b
                 0 : a = b
                 1 : a > b
*/
export default function cTimeCompare(a, b) {
  var beginTime = new Date('1970-01-01T' + a + 'Z');
  var endTime = new Date('1970-01-01T' + b + 'Z');
  if (beginTime.getTime() > endTime.getTime()) {
    return 1;
  } else if (beginTime.getTime() === endTime.getTime()) {
    return 0;
  } else {
    return -1;
  }
}