import {
    defaultFont,
    grayColor
} from "assets/jss/material-dashboard-pro-react.js";

const snapShotStyle = (theme) => ({
    margin: {
        height: theme.spacing(3),
    },
    labelRoot: {
        ...defaultFont,
        color: grayColor[3] + " !important",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        top: "10px",
        letterSpacing: "unset",
        transform: 'translate(0, 1.5px)',
        'transform-origin': 'top left',
        "& + $underline": {
          marginTop: "0px"
        }
    },
});
  
export default snapShotStyle;
  