import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import Box from '@material-ui/core/Box';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// import {
//   warningColor,
// } from "assets/jss/material-dashboard-pro-react.js";

NBrootsDialogPlayer.propTypes = {
  open: PropTypes.bool,
  src: PropTypes.string,
  srcLst: PropTypes.array,
};

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
    </MuiDialogTitle>
  );
});

export default function NBrootsDialogPlayer(props) {

  const {src, srcLst} = props;
  const {index, setIndex} = props;

  const onContextMenu = (e) => {
    e.preventDefault();
  };

  const onEnded = () => {
    // console.log('onEnded=======================')
    // console.log(index)
    if(srcLst && srcLst.length > 0){
      var i = index;
      i++;
      if(i >= srcLst.length){
        // i = 0;
        return;
      }
      // console.log('changeIndex:'+ i);
      setIndex(i);
    }
  };

  return (
    <Box>
      <Dialog
        open={props.open}
        onClose={props.onClose} aria-labelledby="customized-dialog-title" >
        <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
          Player
        </DialogTitle>
        <DialogContent>

          <video style={{ width: '100%' }}
            src={srcLst && srcLst.length > 0 ? srcLst[index] : src ? src : ''}
            controls
            controlsList="nodownload"
            autoPlay
            onContextMenu={onContextMenu}
            onEnded={onEnded}
          />            

        </DialogContent>
      </Dialog>
    </Box>
  );
}