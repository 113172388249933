/**
 *　プライバシー規約
 * 
 */
/* eslint-disable */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Grid from '@material-ui/core/Grid';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// styles components
import styles from "assets/jss/material-dashboard-pro-react/views/CommonPopup/CommonPopupStyle";

export default function PrivacyPolicyPopup(props) {

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { info, setInfo } = props;
  
  const open = info[0];

  //閉じるボタン押す
  const handleClickCancelBtn = () => {
    //[当画面表示FLG ,　情報,　IsAfterOK]
    var tReturnVal = [ false, {}, false];
    setInfo(tReturnVal);
  }

  return (
    <Dialog fullWidth={true} maxWidth={'lg'} onClose={handleClickCancelBtn} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" className={classes.dialogHeader} onClose={handleClickCancelBtn}>
          プライバシー規約
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="warning"
          onClick={handleClickCancelBtn}
          style={{ float: "right" }}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent>
        <GridItem className={classes.modalDialog} xs={12} sm={12} md={12} lg={12}>
          <GridContainer>
            <Grid item xs={12} sm={12} className={classes.optBox}>
              2020年8月26日
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxCenter}>
              Dimpactクラウド配信ツール　利用規約
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              この規約（以下「本規約」といいます。）は、株式会社Droots（以下「当社」といいます。）が提供するソフトウェア、サービス及びウェブサイト（以下総称して「本サービス」といいます。）をお客様にご利用いただくにあたり、お客様との法的な合意事項を定めるものです。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              お客様におかれましては、本サービスのご利用前に、本規約の内容を充分にご確認及びご理解くださいますよう、よろしくお願い申し上げます。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              お客様は本サービスを利用するに当たって表示されるウェブサイト上で、 本規約に「同意する」をクリックすることによって、本規約の全ての規定が適用されることに同意し、当社との間で本規約を内容とする利用契約（以下「本利用契約」といいます。）を締結する意思表示をします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第1条(定義)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              本規約で使用する用語の定義は、特段の記載がある場合を除き、次に掲げるとおりとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (1)本サービス・・・当社が運営する「Dimpactクラウド配信ツール」サービス及び関連するサービス
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (2)本サイト・・・本サービスのコンテンツが掲載されたウェブサイト
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (3)本コンテンツ・・・本サービス上で提供される文字、文章、音、静止画、動画、ソフトウェアプログラム、コード等の総称(投稿情報を含みます。)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (4)利用者・・・本サービスを利用する全てのお客様
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (5)申請者・・・本サービスを利用しようとして本サービスに申し込むお客様
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (6)投稿・・・利用者が文章、文字列、画像、動画等をアップロードする行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (7)投稿情報・・・利用者が投稿等によりアップロードした文章、文字列、画像、動画等の情報の総称
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (8)個人情報・・・住所、氏名、職業、電話番号等個人を特定することのできる情報の総称
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (9)登録情報・・・利用者が本サイトにて登録した情報の総称(メールアドレスやアカウントのID、パスワード等を含み、投稿情報は除きます。)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (10)個別規程・・・本サービスに関して、本規約とは別に、ガイドライン、ポリシー、規程、規約等の名称で当社が本サービス上に掲示又は利用者に配布する文書
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (11)知的財産・・・発明、考案、植物の新品種、意匠、著作物その他の人間の創造的活動により生み出されるもの、商標、商号その他事業活動に用いられる商品又は役務を表示するもの及び営業秘密その他の事業活動に有用な技術上又は営業上の情報
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (12)知的財産権・・・特許権、実用新案権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利又は法律上保護される利益
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第2条(本規約への同意)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              1 申請者は、本サービスを利用するに当たって表示されるウェブサイト上で、本規約に「同意する」をクリックすることにより、本規約の適用を受けることに合意する意思表示を行います。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              2 申請者が、本規約に「同意する」をクリックし、当社が申請者による本サービスの利用に合意した時点で、申請者と当社との間で、本利用契約が成立するものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              3 申請者が未成年者である場合は、親権者など法定代理人の同意を得たうえで、本サービスの利用を申請し、利用しなければなりません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              4 申請者が本サービスを事業者のために利用しようとする場合は、当該事業者も本規約に同意したうえで本サービスを利用しなければなりません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              5 個別規程がある場合、利用者は個別規程の内容も遵守しなければならないものとし、個別規程は本規約の一部を構成するものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第3条(規約の変更)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              1 当社は、以下の場合、利用者の承諾を得ることなく、いつでも、本規約の内容を改定することができるものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (1)本規約の改定が、利用者の一般の利益に適合するとき
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (2)本規約の改定が、本利用契約を締結した目的に反せず、かつ、改定の必要性、改定後の内容の相当性その他の事情に照らして合理的なものであるとき
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              2 当社は、本規約を改定するときは、改定の内容及びその内容効力発生時期について、当社所定の方法により、利用者に通知します。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              3 前項の改定の効力は、当社が通知した効力発生時期から生じます。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              4 第1項の場合のほか、当社は、必要と認めるときは、本規約の内容を改定することができるものとします。この場合、当社は、改定について利用者の同意を求めるものとし、利用者は、「同意する」をクリックすることにより、変更後の本規約に異議なく同意したものとみなされます。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第4条(アカウントの登録・管理等)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              1 本サービスを利用するには、当社所定の方法により、本サービスを利用するご本人が本サービスのアカウント登録を申請し、アカウントの登録を受けてこれを維持する必要があります。当社は、当社所定の基準に従って、アカウント登録の申請に対し、登録の可否を判断し、登録を認める場合にはその旨を申請者に通知し、これによりアカウント登録が完了します。当社が申請者のアカウント登録を完了した時点で、第3条第2項において当社が申請者による本サービスの利用を認めたものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              2 第1項に基づきアカウント登録を申請した者が以下の各号のいずれかに該当する場合、当社はアカウント登録を拒否することができます。この場合、本利用契約は成立せず、当社は理由を説明する義務を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (1)アカウント登録に関連して当社に提供された情報の全部又は一部につき虚偽、誤記、不足又は公序良俗に違反し若しくは第三者に不快感を与える内容があった場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (2)成年被後見人、であって成年後見人の代理による申請でない場合又は未成年者、被保佐人若しくは本利用契約の締結に同意が必要な被補助人であって、それぞれ法定代理人、保佐人若しくは補助人の同意を得ていなかった場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (3)過去に本規約に違反したことがある場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (4)その他当社が不適切と判断した場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              3 利用者は、登録情報に変更があった場合は速やかに登録情報を修正し、常に登録情報が最新の情報となるようにしなければなりません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              4 利用者は、登録情報について、自己の責任の下、任意に登録、及び管理するものとします。利用者は、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買などをしてはなりません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              5 当社は、登録情報によって本サービスが利用された場合、利用登録を行った本人が利用したものと扱うことができ、当該利用によって生じた結果及びそれに伴う一切の責任については、利用登録を行った本人に帰属するものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              6 利用者は、登録情報の不正使用によって当社又は第三者に損害が生じた場合、当社及び第三者に対し、当該損害を賠償しなければなりません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              7 登録情報の管理は、利用者が自己の責任の下で行うものとし、登録情報が不正確又は虚偽であったために利用者が被った一切の不利益及び損害に関して、当社は何ら責任を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              8 登録情報が盗用され又は第三者に利用されていることが判明した場合、利用者は直ちにその旨を当社に通知するとともに、当社の指示に従うものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              9 利用者は、当社所定の方法により、いつでもアカウントを削除し本サービスの利用をやめることができます。ただし、有料プランの場合その他当社に対して債務を負っている場合は、利用をやめる前にすべての債務を当社に弁済しなければなりません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              10 当社は、利用者が、以下の各号のいずれかに該当する場合は、事前に通知・催告することなく、利用者の本サービスの利用を一時的に停止し、又はアカウントを削除することができます。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (1)本規約に違反したと当社が判断した場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (2)登録情報が真実かつ正確ではないと判明した場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (3)支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始その他の倒産手続の開始の申立てがあった場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (4)反社会的勢力等(暴力団、暴力団員、暴力団準構成員、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団、反社会的勢力、その他これに準ずる者をいいます。)であると当社が判断した場合、又は資金提供等を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等と何らかの交流・関与を行っていると当社が判断した場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (5)本条第2項各号のいずれかに該当する場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (6)その他当社が適当でないと判断した場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              11 当社は、あらかじめ利用者に通知・催告することなく、別途定める本利用契約の終了時又は最終のアクセスから1年以上経過した時、利用者のアカウントを削除することができるものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              12 理由の如何を問わず、アカウントが削除された時点で利用者は本サービスを退会したものとし本サービスを利用することができなくなります。利用者が誤ってアカウントを削除した場合も、アカウントの復旧を行うことはできず、本サービスを再度利用する場合には、あらためて登録を行うものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第5条(個人情報等の取扱い)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              個人情報及び利用者情報については、当社が別途定める「Drootsサービスプライバシーポリシー」に則り、適正に取り扱うこととします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第6条(禁止行為)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              利用者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為又はそのおそれがあると当社が判断する行為をしてはなりません。当社において、利用者が以下の禁止事項に違反したと認めた場合、利用停止、投稿削除等、当社において必要と判断した措置をとることができるものとし、利用者はこれに異議なく同意するものとします。また、当社は、本条に基づき当社が行った措置に起因又は関連して利用者に損害が生じたと場合でも、一切の責任を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (1)当社又は第三者の知的財産権を侵害する行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (2)当社又は第三者の名誉・信用を毀損又は不当に差別もしく若しくは誹謗中傷する行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (3)当社又は第三者の財産を侵害する行為、又は侵害するおそれのある行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (4)当社又は第三者に経済的損害を与える行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (5)当社又は第三者に対する脅迫的な行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (6)利用者が、以下のいずれかの情報を投稿すること
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              ①	第三者の権利及び財産に対して損害を与えるリスクのある情報
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              ②	第三者に対して有害な情報、第三者の身体又は精神を傷つける情報
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              ③	犯罪や不法行為、危険行為に属する情報又はそれらを教唆、幇助する情報
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              ④	不法、有害、脅迫、虐待、人種差別、中傷、名誉毀損、侮辱、ハラスメント、扇動又は不快を与えることを意図し、若しくはそのような結果を生じさせるおそれのある内容を有する情報
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              ⑤	事実に反する、又は存在しないと分かっている情報
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              ⑥	利用者自身がコントロール可能な権利を持たない情報
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              ⑦	第三者の著作権を含む知的財産権やその他の財産権を侵害する情報、公共の利益又は個人の権利を侵害する情報
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              ⑧	わいせつ、児童ポルノ又は児童虐待にあたる画像、文書等の情報
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              ⑨	法令に違反し又は公序良俗に反する情報（免許、許認可、登録等がなければ表示することができない情報を、当該必要な免許、許認可、登録等なくして表示する場合を含むが、これに限りません。）
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              ⑩	その他当社が不適切と判断する情報
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (7)コンピューターウィルス、有害なプログラムを使用し又はそれを誘発する行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (8)本サービス用インフラ設備に対して過度な負担をかける行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (9)当社ウェブサイトのサーバーやシステム、セキュリティへの攻撃
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (10)当社提供のインターフェース以外の方法で当社サービスにアクセスを試みる行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (11)一人の利用者が、複数のアカウントを取得する行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (12)法令、裁判所の判決、決定若しくは命令、又は拘束力のある行政上の措置に違反する行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (13)公序良俗に反する行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (14)面識のない異性との出会いや交際を目的とする行為又は性行為やわいせつな行為を目的とする行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (15)過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引又は助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を送信する行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (16)第三者に対する嫌がらせや誹謗中傷を目的とする行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (17)当社又は第三者になりすます行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (18)反社会的勢力等に対する利益供与その他の協力・関与行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (19)宗教活動又は宗教団体への勧誘行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (20)当社による本サービスの運営又は他の利用者による本サービスの利用を妨害し、これらに支障を与える行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (21)上記のいずれかに該当する行為を助長する行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (22)上記の他、当社が不適切と判断する行為
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第7条(コンテンツの取扱い)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              1 利用者は、本サービスのコンテンツを当社の定める範囲内でのみ使用することができるものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              2 本サービスで提供される全てのコンテンツに関する権利は当社が有しており、利用者に対し、本サービスの利用の許諾のほかに、当社が有する特許権、実用新案権、意匠権、商標権、著作権、その他知的財産権の実施又は使用許諾をするものではありません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              3 利用者は、当社の定める使用範囲を超えていかなる方法によっても複製、送信、譲渡(利用者同士の売買も含みます。)、貸与、翻訳、翻案、無断で転載、二次使用、改変、逆アセンブル、逆コンパイル、リバースエンジニアリング等を行う事を禁止します。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              4 利用者は、退会等により利用者たる資格を喪失した場合、その後、当社から提供されたコンテンツを使用することはできません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              5 利用者が、本サービス上に投稿その他の方法で送信したコンテンツ(静止画、動画、文字情報その他一切の情報)に関する著作権(著作権法第21条ないし同第28条に規定する権利を含む全ての著作権を含みます。)については利用者に帰属するものとします。ただし、利用者は、コンテンツの送信時に、当社に対し、日本国内外において、当該コンテンツを無償かつ非独占的に使用することを許諾するものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              6 利用者は本サービス内では、当社に対し、著作者人格権を行使しないものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              7 当社は本コンテンツ及び投稿情報のバックアップを行う義務を負いません。利用者は、投稿情報のバックアップが必要な場合には、自己の費用と責任でこれを行うものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              8 当社は、法令又は本規約の遵守状況などを確認する必要がある場合、投稿情報の内容を確認することができます。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第8条(有料プラン)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              1 本サービス内の一部コンテンツを使用するには、対価を支払うことにより有料プランに加入することが必要です。有料プランの金額、決済方法その他の事項は、別途当社が定め、本サービス又は当社ウェブサイトに表示します。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              2 当社は、本サービスにおいて無償又は有料プランについて、当社の裁量でその価格を変更することがあります。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              3 利用者が有料プランの対価の支払いを遅滞した場合、利用者は、年14.6%の割合による遅延損害金を当社に対して支払うものとします。支払いの遅滞が2か月以上継続した場合、当社は、利用者による本サービスの利用を停止することができます。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              4 有料プランによる利用は、利用者本人に対してのみ許諾します。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第9条(免責)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              1 当社は、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              2 当社は、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              3 当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令又は業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証するものではありません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              4 本サービスの提供を受けるために必要な、コンピューター、スマートフォンその他の機器、ソフトウェア、通信回線その他の通信環境等は、利用者の費用と責任において準備し維持するものとします。当社は、本サービスが全ての機器等に対応していることを保証するものではなく、本サービスの利用に供する機器等のOSのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              5 利用者は、AppStore、GooglePlay等のサービスストアの利用規約及び運用方針の変更等に伴い、本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              6 当社は、本サービスを利用したことにより直接的又は間接的に利用者に発生した損害について、一切賠償責任を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              7 当社は、利用者その他の第三者に発生した機会逸失、業務の中断その他いかなる損害(間接損害や逸失利益を含みます。)に対して、当社が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              8 他の利用者又は第三者から投稿についての削除依頼があった時、当社の判断で削除の可否を決定できるものとし、当該判断により生じた一切の責任について、当社は責任を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              9 第1項ないし前項の規定は、当社に故意又は重過失が存する場合又は契約書が消費者契約法上の消費者に該当する場合には適用しません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              10 前項が適用される場合であっても、当社は、過失(重過失を除きます。)による行為によって利用者に生じた損害のうち、特別な事情から生じた損害については、一切賠償する責任を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              11 本サービスの利用に関し当社が損害賠償責任を負う場合、当該損害が発生した月に利用者から受領した利用額を限度として賠償責任を負うものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              12 利用者と他の利用者との間の紛争及びトラブルについて、当社は一切責任を負いません。利用者と他の利用者でトラブルになった場合でも、両者同士の責任で解決するものとし、利用者は、当社に対し、一切の請求をすることができません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              13 利用者は、本サービスの利用に関連し、他の利用者に損害を与えた場合又は第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償又はかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              14 利用者の行為により、当社が第三者から損害賠償等を請求をされた場合には、利用者の費用(弁護士費用)と責任で、これを解決するものとします。当社が、当該第三者に対して、損害賠償金を支払った場合には、当社は、利用者に対し、当該損害賠償金を含む一切の費用(弁護士費用及び逸失利益を含みます。)を求償することができます。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              15 利用者が本サービスの利用に関連して当社に損害を与えた場合、利用者の費用と責任において当社に対して損害(訴訟費用及び弁護士費用を含みます。)を賠償する責任を負います。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              16 本サービスで他の利用者が投稿した情報の正確性について、当社は保証しません。当社は、本サービスに掲載されている情報についての紛争及びトラブルについて一切の責任を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              17 利用者は、有効に本サービスのアカウント登録を受けている期間内に限り、本規約及び当社の定めるところに従い、本サービスを利用することができます。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              18 利用者は、自らの責任において本サービスを利用するものとし、本サービスにおいて行った一切の行為及びその結果について一切の責任を負うものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第10条(広告の掲載について)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              利用者は、本サービス上にあらゆる広告が含まれる場合があること、当社又はその提携先があらゆる広告を掲載する場合があることを理解し、これに同意します。本サービス上の広告の形態や範囲は、当社によって随時変更されます。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第11条(本サービスの停止・変更・終了等)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              1 当社は、以下のいずれかに該当する場合には、利用者に事前に通知することなく、本サービスの全部又は一部を停止することができるものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (1)本サービスに関する機器等の点検又は保守作業を行う場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (2)本サービスに関する機器、通信回線等が事故等により停止した場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (3)火災、停電、天災地変などの不可抗力により本サービスの提供が不可能ないし困難な場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              (4)その他当社が必要と判断する場合
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              2 当社は、当社が必要と判断する場合、あらかじめ利用者に通知することなく、いつでも、本サービスの全部又は一部の内容を変更することができます。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              3 当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社は、利用者に事前に通知するものとし、有料プランの利用者に対しては、支払済みの料金のうち、本サービスの提供の終了により本サービスを利用できなくなった期間（1か月未満を切り捨てます。）に対応する料金を返金します。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第12条(権利譲渡の禁止)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              1 利用者は、あらかじめ当社の書面による承諾がない限り、本規約上の地位及び本規約に基づく権利又は義務の全部又は一部を第三者に譲渡してはならないものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              2 当社は、本サービスの全部又は一部を当社の裁量により第三者に譲渡(事業譲渡、会社分割その他本サービスが移転する一切の場合を含みます。)することができ、その場合、譲渡された権利の範囲内で利用者のアカウントを含む、本サービスに係る利用者の一切の権利を譲渡先に移転することができるものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第13条(分離可能性)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の他の規定は、継続して完全に効力を有するものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第14条(連絡方法)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              1 本サービスに関する利用者の当社へのご連絡・お問い合わせは、本サービス又は当社が運営するウェブサイト内の適宜の場所に設置するお問い合わせフォームからの送信又は当社が別途指定する方法により行うものとします。利用者から通知・連絡があった場合、当社は、当社所定の方法により、利用者の本人確認を行うことができるものとします。また、利用者からの通知・連絡に対する回答方法については、その都度当社が最適と考える方法により回答することができるものとし、その回答方法を利用者が指定することはできないものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              2 本サービスに関する当社から利用者への通知・連絡は、当社が運営するウェブサイト内の適宜の場所への掲示その他、当社が適当と判断する方法により行うものとします。当社からの通知・連絡の不着、遅延等により生じる損害について、当社は一切の責任を負いません。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              第15条(準拠法、管轄裁判所)
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              1 本規約の有効性、解釈及び適用については、日本法に準拠し、日本法に従って解釈されるものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              2 本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、利用者は、当社の定めるところに従うものとします。これにより解決しない場合には、当社及び利用者は、信義誠実の原則に従って協議の上速やかに解決を図るものとします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBoxLeft}>
              3当社と利用者等との間での論議・訴訟その他一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を専属的合意管轄裁判所とします。
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBox}>
              2020年8月26日
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBox}>
              株式会社Droots
            </Grid>
          </GridContainer>
        </GridItem>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancelBtn} color="warning">
            閉じる
          </Button>
      </DialogActions>
    </Dialog>
  );
}
