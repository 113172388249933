/**
 *　設置場所メンテナンス
 * 
 */
/* eslint-disable */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// exp components
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";

import { BASE_URL, VALIDATE_MSG } from 'variables/config.js';
import Service from "utils/service/service.js"
import cEmptyToNull from "utils/functions/cEmptyToNull"
import batFormat from "utils/functions/batFormat";
import millisecondFormat from "utils/functions/millisecondFormat";
import styles from "assets/jss/material-dashboard-pro-react/views/CommonPopup/CommonPopupStyle";

export default function PlayListPopup(props) {

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { info, setInfo } = props;
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);

  const [vals, setVals] = React.useState([]);
  
  const playlistid = info[1].playlistid;
  const upload = info[1].upload;
  const termid = info[1].termid;
  const idTermPlaylist = info[1].idTermPlaylist;
  const getDownLoadStatus = info[1].getDownLoadStatus;
  const pageid = info[1].pageid;
  const open = info[0];

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if(open){
      //進捗画面
      setIsLoading(true);
      formatDetailData(playlistid,props);
    }
  }, [open,playlistid,props]); 

  const formatDetailData = (id,tProps) => {
    var url;
    var data;
    if (upload) {
      url = BASE_URL + "/API/TerminalList/GetTerminalPlayList";
      data = {
        id: cEmptyToNull(termid),
        idTermPlaylist: cEmptyToNull(idTermPlaylist)
      };
    } else {
      url = BASE_URL + "/API/PlayList/Get";
      data = {
        playlistid: cEmptyToNull(id)
      };
    }

    Service(tProps, url, data).then(resp => {
      // console.log(resp);
      // 正常系
      if (resp.status === 0) {
        var lst
        if (upload) {
          lst = resp.infos;
        } else {
          lst = resp.infos.playlist;
        }
        setVals(lst);

      } else {
        //その他APIエラー
        setVals([]);
        setMsgInfo([true, resp.msg, 'warning']);    
      }
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };

  //キャンセルボタン押す
  const handleClickCancelBtn = () => {
    var tReturnVal = [ false, info[1], true];
    setInfo(tReturnVal);
  }

  React.useEffect(() => {
    //起動確認結果
    if (confirmInfo[2].opt === "reDownload" && confirmInfo[2].result === 1) {
      //STATE情報渡す
      var url = BASE_URL + "/API/TerminalList/RedownloadTerminalDownlodItem";

      console.log('reDownload')

      var data = confirmInfo[2].id;

      // var tResult;
      Service(props, url, data).then(resp => {
        console.log(resp.status)
        // 正常系
        if (resp.status === 0) {

          setMsgInfo([true, resp.msg, 'success']);

          // 重新导入画面数据
          formatDetailData(playlistid,props);
        } else {
          //その他APIエラー
          setMsgInfo([true, resp.msg, 'warning']);
        }

        setIsLoading(false);

      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });
    }
  }, [confirmInfo]);

  const reDownload = (item) => {

    return; // reDownloadをコメント

    if(!upload || item === null || item.downLoadStatus !== 6 || pageid !== "TerminalDetail"){
      return;
    }

    setConfirmInfo([true, VALIDATE_MSG.MSG_508,
      {
        opt        : "reDownload",
        id         : item,     //STATE情報渡す
        result     : 0
      }
    ]);
  };

  return (

    <Dialog fullWidth={true} maxWidth={'lg'} onClose={handleClickCancelBtn} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" className={classes.dialogHeader} onClose={handleClickCancelBtn}>
          プレイリスト詳細
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="warning"
          onClick={handleClickCancelBtn}
          style={{ float: "right" }}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>

      <DialogContent>
        <GridItem className={classes.modalDialog} xs={12} sm={12} md={12} lg={12}>
          <GridContainer>
            <TableContainer style={{ minHeight: 440, maxHeight: 440 }}>
              <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead} style={{ width: "20%" }}>サムネイル</TableCell>
                      <TableCell className={classes.tableHead} style={{ width: "20%" }}>プレイリスト名</TableCell>
                      <TableCell className={classes.tableHead} style={{ width: "20%" }}>再生時間</TableCell>
                      <TableCell className={classes.tableHead} style={{ width: "20%" }}>サイズ</TableCell>
                      {/* <TableCell className={classes.tableHead} style={{ width: "15%" }}>再生回数</TableCell> */}
                      {/* <TableCell className={classes.tableHeadCenter} style={{ width: "15%" }}>ステータス</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {vals ? vals.map((item, key) =>
                    <TableRow className={classes.tableRow} key={key} onClick={() => reDownload(item)}>
                      <TableCell className={classes.tableCell} ><img className={classes.imagesrc} height="15%" src={item.imagesrc} alt="..." /></TableCell>
                      <TableCell className={classes.tableCellLeft} >{item.name}</TableCell>
                      <TableCell className={classes.tableCell} >{millisecondFormat(item.duration)}</TableCell>
                      <TableCell className={classes.tableCell} >{batFormat(item.size)}</TableCell>
                      {/* <TableCell className={classes.tableCell} >{item.loop_count}</TableCell> */}
                      {/* <TableCell className={classes.tableCellCenter} >
                        {upload ?
                          getDownLoadStatus(item.downLoadStatus)
                          : "未設定"
                        }
                      </TableCell> */}
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </GridContainer>
        </GridItem>

      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickCancelBtn} color="warning">
          戻る
        </Button>
      </DialogActions>
        {/* 共通ダイアログ画面 */}
        <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
        <NBrootsDialogProcess open={isLoading} color="warning" />
        <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Dialog>

  );
}
