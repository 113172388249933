import React from 'react';
import PropTypes from 'prop-types';
import { IMG_BASE64 } from './ImgBase64.js';

NBrootsImg.propTypes = {
    src: PropTypes.string,
};

export default function NBrootsImg(props) {
    const { src } = props;

    const imageRef = React.useRef(null);

    const [img, setImg] = React.useState(IMG_BASE64);

    const onLoad = () => {
        
        if(img !== src){
            
            setImg(src)
        }

    }

    //初期化処理
    React.useEffect(() => {

        imageRef.current.src = IMG_BASE64;
        
        // console.log(imageRef);

    }, [src]);    //[] 忘れないよう…　初期化



    return (
        <img
            ref={imageRef}
            src={img}
            alt="..."
            onLoad={onLoad}
        />
    );
}