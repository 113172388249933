/**
 Timeチェック
    2:3
    02:3
    02:03
 
@param  input   チェック対象
@return Boolean チェック結果(問題あるかどうか)
                true  : 問題あり
                false : 問題なし
*/
export default function isNotPassTimeChk(input){
    
    if (input === null || input === '') return false;
    return (input.match(/^(20|21|22|23|\d|([0-1]\d)):[0-5]\d$/)) ? false : true;
}