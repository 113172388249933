import React from "react";
// import ReactImageAppear from 'react-image-appear';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// import AccessTime from "@material-ui/icons/AccessTime";
import Delete from "@material-ui/icons/Delete";
import Save from "@material-ui/icons/Save";
// import Scale from "@material-ui/icons/LinearScale";
import Edit from "@material-ui/icons/Edit";
import AttachMoney from "@material-ui/icons/AttachMoney";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// exp components
import NBrootsPagination from "components/@NBrootsPagination/NBrootsPagination.js";
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsDialogPlayer from "components/@NBrootsDialogPlayer/NBrootsDialogPlayer.js";
import NBrootsImg from "components/@NBrootsImg/NBrootsImg.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";
import VideoUploader from "./VideoUploader";
import Service from "utils/service/service.js"

import { BASE_URL, VALIDATE_MSG, CORP_ID_SELECTED_ALL } from 'variables/config.js';
import cEmptyToNull from "utils/functions/cEmptyToNull"
import ccyFormat from "utils/functions/ccyFormat"
import styles from "assets/jss/material-dashboard-pro-react/views/MngSystem/AdminMarket/marketForAdminStyle";
import { PAGINATION_PREV_NAME, PAGINATION_NEXT_NAME } from 'variables/config.js';
// 素材-種類リスト
import { materialCategoryInfo } from 'variables/runtimeInfos.js';

import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";
import isNotPassNumberRangeChk from "utils/validations/isNotPassNumberRangeChk";

// const useStyles = makeStyles(styles);

export default function MntMarket(props) {


  const useStyles = makeStyles(styles);
  const classes = useStyles();
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);

  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);

  //ページジャンプとソート用    pageInfos   : [記録数、ページサイズ,当ページ番号]
  // const [pageInfos, setPageInfos] = React.useState([0, 10, 1]);

  //player画面用情報          playerInfo  : [表示FLG、VideoSrc]
  const [playerInfo, setPlayerInfo] = React.useState([false, '']);


  //明細情報
  // const [lstContent, setLstContent] = React.useState([]);

  //検索条件（初期状態）
  var tSearch = {
    categoryId: CORP_ID_SELECTED_ALL,           //検索条件　素材-種類ID
    orderby: 'update_time',          //orderby
    sorttype: 'desc',                 //sorttype
    pageIndex: 1,            //当ページ番号
    pageSize: 8            //ページサイズ
  }
  const [search, setSearch] = React.useState(tSearch);

  //検索結果（初期状態）
  var tSearchResult = {
    total: 0,            //記録数
    lst: []            //明細リスト
  }
  const [searchResult, setSearchResult] = React.useState(tSearchResult);

  //アップロード画面用情報
  var tVideoUploaderInfo = [
    false,          //Popup画面表示FLG
    {},             //情報<当画面未使用>
    false           //保存ボタン押すFLG<当画面未使用>
  ]
  //場所追加画面関連情報
  const [videoUploaderInfo, setVideoUploaderInfo] = React.useState(tVideoUploaderInfo);

  //初期化処理
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {

    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search, props);

  }, [search, props]);    //[] 忘れないよう…　初期化



  //APIJSON　→　画面用JSON（FLGやERRMSGなど）
  // const formatLstData = async () => {
  const formatLstData = (tSearchCondition, tProps) => {


    var url = BASE_URL + "/API/MntSysAdminList/Search";

    var data = {
      id     : cEmptyToNull(tSearchCondition.categoryId === CORP_ID_SELECTED_ALL ? '' : tSearchCondition.categoryId),
      orderby: tSearchCondition.orderby,
      sorttype: tSearchCondition.sorttype,
      pageIndex: tSearchCondition.pageIndex,
      pageSize: tSearchCondition.pageSize
    };
    // var tResult ;
    Service(tProps, url, data).then(resp => {
      // console.log("検索結果-----------20200421");
      // console.log(resp);
      var tResult;
      // 正常系
      if (resp.status === 0) {
        var info = resp.infos;
        //Data情報の処理
        var lst = info.list;
        //検索結果（セット）
        tResult = {
          total: info.total,
          lst: [...lst]
        }
        // console.log(tResult);
        setSearchResult(tResult);
        //FLAGなどの整理
        lst = lst.map(item => {
          return {
            ...item,
            //追加属性
            isEdit: false,
            chk_name: '',
            chk_remark: '',
            chk_price: '',
            msg_name: '',
            msg_remark: '',
            msg_price: '',
          }
        });
        //検索結果（セット）
        tResult = {
          total: info.total,
          lst: [...lst]
        }
        // console.log(tResult);
        setSearchResult(tResult);
      } else {
        //その他APIエラー
        //検索結果（初期状態）
        tResult = {
          total: 0,
          lst: []
        }
        setSearchResult(tResult);
        setMsgInfo([true, resp.msg, 'warning']);
      }
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });

  };

  //改ページ処理
  // pageInfos   : [ページサイズ,当ページ番号]
  const handleChangePageStart = (page) => {

    let t = search;
    if (page.disabled) return;
    if (page.text === search.pageIndex) return;

    if (page.text === PAGINATION_PREV_NAME) {
      t.pageIndex = t.pageIndex - 1;
    } else if (page.text === PAGINATION_NEXT_NAME) {
      t.pageIndex = t.pageIndex + 1;
    } else {
      t.pageIndex = page.text;
    }

    //情報セット
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  };

  //===========================================
  //編集機能
  //===========================================
  //画面動作 EDIT
  const handleClickStartEdit = (obj) => {
    //編集IDX取得
    var idx = searchResult.lst.indexOf(obj);
    //編集MODEFLGセット
    if (!obj.isEdit) {
      obj.isEdit = true;
    }
    //情報セット
    searchResult.lst[idx] = obj;
    //Copy　STATE情報　By　Value
    var tResult = JSON.parse(JSON.stringify(searchResult));
    //STATEに保存＜画面側、ICON変更、編集MODEに入る＞
    setSearchResult(tResult);

  };


  //画面動作 Edit On Change
  const handleChangeStartEdit = (obj, propname, inVal) => {
    //編集IDX取得
    // var index = lstContent.indexOf(obj);
    var idx = searchResult.lst.indexOf(obj);
    //チェック
    validate(obj, propname, inVal);
    //情報セット
    searchResult.lst[idx][propname] = inVal;
    //Copy　STATE情報　By　Value
    var tResult = JSON.parse(JSON.stringify(searchResult));
    //STATEに保存
    setSearchResult(tResult);

  };

  function validate(item, name, value) {
    //チェック処理
    if (name === 'name') {
      if (isNotPassNullChk(value)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_001;
      // } else if (isNotPassCharTypeChk(value, "zenkaku")) {
      //   item["chk_" + name] = "error";
      //   item["msg_" + name] = VALIDATE_MSG.MSG_003;
      } else if (isNotPassLengthChk(value, null, 100)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        item["chk_" + name] = "success";
        item["msg_" + name] = "";
      }
    } else if (name === 'remark') {
      if (isNotPassNullChk(value)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_001;
      // } else if (isNotPassCharTypeChk(value, "zenkaku")) {
      //   item["chk_" + name] = "error";
      //   item["msg_" + name] = VALIDATE_MSG.MSG_003;
      } else if (isNotPassLengthChk(value, null, 255)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 255);
      } else {
        item["chk_" + name] = "success";
        item["msg_" + name] = "";
      }
    } else if (name === 'price') {
      if (isNotPassNullChk(value)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_001;

      } else if (isNotPassCharTypeChk(value + "", "numeric")) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_007;
      } else if (isNotPassNumberRangeChk(value, 0, 1000000000)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_205.replace("{0}", 1).replace("{1}", 999999999);
      } else {
        item["chk_" + name] = "success";
        item["msg_" + name] = "";
      }
    }
  }



  //画面動作 SAVE
  const handleClickStartSave = (obj) => {
    //一括チェック
    validate(obj, 'name', obj.name);
    validate(obj, 'remark', obj.remark);
    validate(obj, 'price', obj.price);

    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if (obj["chk_name"] === "error" ||
      obj["chk_remark"] === "error" ||
      obj["chk_price"] === "error"
    ) {
      //Copy　STATE情報　By　Value
      var tResult = JSON.parse(JSON.stringify(searchResult));
      //STATEに保存
      setSearchResult(tResult);
      return;
    }

    setIsLoading(true);

    var url = BASE_URL + "/API/MntSysAdminList/Update";

    //　入力データ
    var data = {
      // id          : cEmptyToNull(obj.id),
      id: obj.id,
      name: cEmptyToNull(obj.name),
      remark: cEmptyToNull(obj.remark),
      price: cEmptyToNull(obj.price),
      updateIndex: obj.updateIndex,
    };
    // console.log(data+'20200422----------');

    Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        setMsgInfo([true, resp.msg, 'success']);
        //情報のロード
        formatLstData(search);
      } else {
        setMsgInfo([true, resp.msg, 'danger']);
      }
      //進捗画面を閉じる
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });

  };

  //===========================================
  //追加機能
  //===========================================
  //画面動作 ADD
  const handleClickStartAdd = () => {
    var tVideoUploaderInfo = [
      true,          //Popup画面表示FLG
      {               //情報
      },
      false           //保存ボタン押すFLG
    ]
    setVideoUploaderInfo(tVideoUploaderInfo);
  };

  //Popup画面後の処理
  React.useEffect(() => {
    //追加処理
    function doAfterAdd() {
      //情報のロード
      formatLstData(search);
      setMsgInfo([true, 'アップロード成功しました。', 'success']);
    }
    //保存ボタン押す後
    if (videoUploaderInfo[2] === true)
      doAfterAdd();

  }, [videoUploaderInfo, search]);

  //画面動作 PLAY
  const handleClickStartPlay = (obj) => {

    setPlayerInfo([true, obj.src]);

  };

  //===========================================
  //削除機能
  //===========================================
  //画面動作 DEL
  const handleClickStartDel = (item) => {
    // console.log('削除:' + item);
    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        opt: "del",
        id: item.id,
        result: 0
      }
    ]);
  };



  //確認メッセージ後の処理
  React.useEffect(() => {

    //削除処理
    function doDel(obj, tProps) {
      setIsLoading(true);
      var url = BASE_URL + "/API/MntSysAdminList/Delete";
      var data = {
        id: obj["id"]
      };
      Service(tProps, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {
          setMsgInfo([true, resp.msg, 'success']);
          //情報のロード
          formatLstData(search);
        } else {
          //その他APIエラー
          setMsgInfo([true, resp.msg, 'success']);
        }

        setIsLoading(false);
      }).catch(resp => {
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });

    }

    //起動確認結果
    if (confirmInfo[2].opt === "del" && confirmInfo[2].result === 1) {
      // console.log(confirmInfo[2]);
      doDel(confirmInfo[2], props);
    }

  }, [confirmInfo, search, props]);

  const handleChangeSearchStart = (e) => {

    let t = search;
    //選択情報をSTATEに保存
    if (e.target.name === 'typeSelect') {
      t.categoryId = e.target.value;
    } else if (e.target.name === 'sortItemSelect') {
      t.orderby = e.target.value;
    }

    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  }


  return (
    <Box>

      <Grid container>
        {/* 検索部 */}
        <Grid item xs={6} sm={6} className={classes.searchBox}>
          <FormControl style={{ width: "40%" }}
            className={classes.selectFormControl}>
            <InputLabel htmlFor="typeSelect" className={classes.selectLabel}>
              種類
            </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              value={search.categoryId}
              onChange={(e) => handleChangeSearchStart(e)}
              inputProps={{ name: "typeSelect"}}>
              <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                種類を選択
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={CORP_ID_SELECTED_ALL}>
                すべて
              </MenuItem>
              {materialCategoryInfo.lst ? materialCategoryInfo.lst.map((item,key) =>
                <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.categoryId} key={1000 + key}>
                  {item.categoryName}
                </MenuItem> 
              ) : null}
              {/* <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={1} key={1}>
                春
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={2} key={2}>
                夏
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={3} key={3}>
                秋
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={4} key={4}>
                冬
              </MenuItem> */}


            </Select>
          </FormControl>
        </Grid>
        {/* 操作ボタン部 */}
        <Grid item xs={6} sm={6} className={classes.optBox}>
          <Button color="warning" onClick={() => handleClickStartAdd()}>コンテンツ追加</Button>
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
        {/* ページジャンプ */}
        <Grid item xs={12} sm={6} className={classes.pageJumpBox}>
          {/* [記録数、ページサイズ,当ページ番号] */}
          <NBrootsPagination
            count={searchResult.total}
            pagesize={search.pageSize}
            current={search.pageIndex}
            onClick={handleChangePageStart}
            color="warning"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl style={{ width: 120 }}
            variant="filled"
            underline="none"
            className={classes.selectFormControl2}>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select2 }}
              value={search.orderby}
              onChange={(e) => handleChangeSearchStart(e)}
              inputProps={{ name: "sortItemSelect"}}>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={'update_time'} key={201}>
                更新日
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={'create_time'} key={202}>
                登録日
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={'play_times'} key={203}>
                プレイ数
              </MenuItem>

            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* ScrollBar表示部分 */}
      <Box xs={12} className={classes.gidBox}>
        <GridContainer>
          {searchResult.lst ? searchResult.lst.map((item, idx) =>
            <GridItem xs={12} sm={6} md={3} key={idx}>
              <Card product className={classes.cardHover}>
                <CardHeader image className={classes.cardHeaderHover}>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <NBrootsImg src={item.imagesrc} />
                  </a>
                  {/* <ReactImageAppear src={item.imagesrc} placeholder/> */}
                </CardHeader>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardHoverUnder}>
                    <Tooltip id="tooltip-top" title="プレイ" placement="bottom"
                      classes={{ tooltip: classes.tooltip }}>
                      <Button color="transparent" simple justIcon>
                        <PlayCircleOutline className={classes.underChartIcons}
                          onClick={() => handleClickStartPlay(item)} />
                      </Button>
                    </Tooltip>
                    {item.isEdit ?
                      // <Tooltip id="tooltip-top" title="保存" placement="bottom"
                      //   classes={{ tooltip: classes.tooltip }}>
                      //   <Button color="success" simple justIcon>
                      //     <Save className={classes.underChartIcons}
                      //       onClick={() => handleClickStartSave(item)} />
                      //   </Button>
                      // </Tooltip>
                      null
                      :
                      <Tooltip id="tooltip-top" title="編集" placement="bottom"
                        classes={{ tooltip: classes.tooltip }}>
                        <Button color="success" simple justIcon>
                          <Edit className={classes.underChartIcons}
                            onClick={() => handleClickStartEdit(item)} />
                        </Button>
                      </Tooltip>
                    }
                    <Tooltip id="tooltip-top" title="削除" placement="bottom"
                      classes={{ tooltip: classes.tooltip }}>
                      <Button color="danger" simple justIcon>
                        <Delete className={classes.underChartIcons}
                          onClick={() => handleClickStartDel(item)} />
                      </Button>
                    </Tooltip>
                  </div>
                  {item.isEdit ?
                    <Box>
                      <CustomInput id="name"
                        success={item.chk_name === "success"}      // chkResult + Json.prop
                        error={item.chk_name === "error"}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.inputloopnum,
                        }}
                        //value={item.name}                               //set
                        inputProps={{
                          value: item.name,                       //set
                          onChange: event => {
                            handleChangeStartEdit(item, "name", event.target.value);
                          },
                        }}
                        helperText={item.msg_name}
                      />
                      <CustomInput id="remark"
                        success={item.chk_remark === "success"}      // chkResult + Json.prop
                        error={item.chk_remark === "error"}
                        formControlProps={{
                          className: classes.inputDetail,
                          fullWidth: true
                        }}
                        value={item.remark}                               //set
                        inputProps={{
                          value: item.remark,
                          onChange: event => {
                            handleChangeStartEdit(item, "remark", event.target.value);
                          },
                        }}
                        helperText={item.msg_remark}
                      />
                    </Box>
                    :
                    <Box>
                      <h4 className={classes.cardProductTitle}>
                        {item.name}
                      </h4>
                      <p className={classes.cardProductTitle}>
                        {item.remark}
                      </p>
                    </Box>
                  }
                </CardBody>
                <CardFooter product>
                    {item.isEdit ?
                      <React.Fragment>
                        <div className={classes.price}>
                          <p>￥
                            <CustomInput id="price"
                              success={item.chk_price === "success"}      // chkResult + Json.prop
                              error={item.chk_price === "error"}
                              formControlProps={{
                                className: classes.inputPrice,
                                fullWidth: true
                              }}
                              value={item.price}                               //set
                              inputProps={{
                                defaultValue: item.price,                       //set
                                onChange: event => {
                                  handleChangeStartEdit(item, "price", event.target.value);
                                },
                              }}
                              helperText={item.msg_price}
                            />
                          </p>
                        </div>
                        <div className={classes.price}>
                          <Tooltip id="tooltip-top" title="保存" placement="bottom"
                            classes={{ tooltip: classes.tooltip }}>
                              <Save className={classes.successText} onClick={() => handleClickStartSave(item)} />
                          </Tooltip>
                        </div>
                      </React.Fragment>
                      :
                      <div className={classes.price}>
                      <p>￥{ccyFormat(item.price)}</p>
                      </div>

                    }
                </CardFooter>
              </Card>
            </GridItem>
          ) : null}
        </GridContainer>
      </Box>
      {/* アップロードPopup画面 */}
      <VideoUploader info={videoUploaderInfo} setInfo={setVideoUploaderInfo} />
      {/* 共通ダイアログ画面 */}
      <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
      <NBrootsDialogPlayer open={playerInfo[0]} src={playerInfo[1]} onClose={() => setPlayerInfo([false, ''])} />
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Box>
  );
}
