/**
 * 空白の内容NULLを空白の内容に変換
 * API から情報取得後に、情報処理用（STAGEに書き込み前に処理する）
 * @param  {String}  input    処理対象
 * @return {String}           処理結果
 */
export default function cNullToEmpty(input) {
  if (input === null) return '';
  if (input === undefined) return '';
  return input;
}
