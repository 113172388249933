export default function cStatusToString(input) {

  if (input === undefined) return null;

  var deviceStatusValue = [];
  if (input === 0) {
    deviceStatusValue.push("停止中");
  }
  if (input === 1) {
    deviceStatusValue.push("起動中");
  }
  if (input === 2) {
    deviceStatusValue.push("オンライン");
  }
  if (input === 3) {
//    deviceStatusValue.push("問題あり");
    deviceStatusValue.push("異常あり");
  }
  if (input === 4) {
    deviceStatusValue.push("オフライン");
  }

  return deviceStatusValue.join();
}
