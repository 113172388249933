import {
  tooltip,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";

const marketForUserStyle = {
  ...hoverCardStyle,
  ...customSelectStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardBody: {
    padding: "0.4375rem 20px",
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "0px",
    textAlign: "center"
  },
  // successText: {
  //   color: successColor[0]
  // },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& p": {
      display: "inline",
      verticalAlign: "super",
      marginBottom: "2px",
      marginTop: "0px"
    }
  },
  optBox: {
    textAlign: "right",
  },
  pageJumpBox:{
    textAlign:"center",
    height:"40px",
  },
  gidBox:{
    height:"calc(100% - 115px)",
    overflowY:"auto",
    overflowX: "hidden",
    paddingRight: "20px",
    paddingTop: "50px"
    // position: "fixed"
  }
};

export default marketForUserStyle;
