// import UserProfiles from "views/UserProfile/UserProfile.js";

// views
import TerminalsList    from "views/TerminalList/TerminalList.js";
import VideosList       from "views/Videos/VideosList.js";

import SystemUserList   from "views/MngSystem/UserList/UserList.js";
import CropList        from "views/MngSystem/CropList/CropList.js";

import UserList         from "views/MngCrop/UserList/UserList.js";

import PlayList         from "views/PlayList/PlayList.js";
import MarketForAdmin   from "views/MngSystem/AdminMarket/MntMarket.js";
import MarketForUser    from "views/Markets/MarketForUser.js";


// @material-ui/icons
import Image            from "@material-ui/icons/Image";
// import Person           from "@material-ui/icons/Person";
// import Update           from '@material-ui/icons/Update';
import SupervisedUserCircleIcon 
                        from '@material-ui/icons/SupervisedUserCircle';

var dashRoutes = [
  {
    path: "/TerminalList",
    name: "端末状況",
    icon: "3d_rotation",
    component: TerminalsList,
    userlevel:0,
    layout: ""
  },
  // {
  //   path: "/ComTerminalList",
  //   name: "連携端末状況",
  //   icon: "view_module",
  //   component: ComTerminalsList,
  //   userlevel:0,
  //   layout: ""
  // },  
  {
    path: "/VideoList",
    name: "マイコンテンツ",
    icon: "movie_creation",
    component: VideosList,
    userlevel:0,
    layout: ""
  },
  {
    path: "/PlayList",
    name: "プレイリスト",
    icon: "playlist_play",
    component: PlayList,
    userlevel:0,
    layout: ""
  },
  {
    path: "/UserMarket",
    name: "3D映像マーケット",
    icon: "shopping_cart",
    component: MarketForUser,
    userlevel:0,
    layout: ""
  },
  {
    collapse: true,
    name: "企業管理",
    icon: Image,
    state: "mntGroupCollapse",
    userlevel:1,
    views: [
      {
        path: "/MngGroup/Userlist",
        name: "アカウント管理",
        icon: "group",
        component: UserList,
        userlevel:1,
        layout: ""
      },
    ]
  },
  {
    collapse: true,
    name: "システム管理",
    icon: Image,
    state: "mntSystemCollapse",
    userlevel:2,
    views: [
      {
        path: "/MngSystem/CropList",
        name: "企業管理",
        icon: "group",
        component: CropList,
        userlevel:2,
        layout: ""
      },    
      {
        path: "/MngSystem/UserList",
        name: "アカウント管理",
        icon: SupervisedUserCircleIcon,
        component: SystemUserList,
        userlevel:2,
        layout: ""
      },
      {
        path: "/MngSystem/AdminMarket",
        name: "コンテンツ管理",
        icon: "shopping_cart",
        component: MarketForAdmin,
        userlevel:2,
        layout: ""
      }
    ]
  },

];

export default dashRoutes;
