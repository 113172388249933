import React from "react";
// @material-ui/core components
import {
  warningColor,
} from 'assets/jss/material-dashboard-pro-react.js';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputLabel from "@material-ui/core/InputLabel";
// import Tooltip from "@material-ui/core/Tooltip";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Add from "@material-ui/icons/Add";
import Clear from "@material-ui/icons/Clear";

// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Pagination from "components/Pagination/Pagination.js";

// exp components
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";
import NBrootsTimePicker from "components/@NBrootsTimePicker/NBrootsTimePicker.js";

import Service from "utils/service/service.js"
import cEmptyToNull from "utils/functions/cEmptyToNull"
import cNullToEmpty from "utils/functions/cNullToEmpty";

// import { BASE_URL, VALIDATE_MSG } from 'variables/config.js';
import { BASE_URL, VALIDATE_MSG } from 'variables/config.js';

// //PlayList一覧画面
import isNotPassNullChk from "utils/validations/isNotPassNullChk";
// import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";
import isNotPassTimeChk from "utils/validations/isNotPassTimeChk.js";
import isNotPassTimeCompareChk from "utils/validations/isNotPassTimeCompareChk.js";
import goBack from "utils/functions/goBack";

import TerminalDetailItem from "views/TerminalList/TerminalDetailItem";

import styles from "assets/jss/material-dashboard-pro-react/views/TerminalList/terminalDetailStyle.js";

import { userInfo, corpsInfo } from 'variables/runtimeInfos.js';
// メモリ中企業リスト
import { groupsInfo, getGroupsInfo } from 'variables/runtimeInfos.js';

const theme = createMuiTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: warningColor[0]
      }
    },
    MuiTab: {
      root: {
        "&:hover": {
          color: warningColor[0]
        }
      },
    }
  }
});

export default function TerminalDetail(props) {
  //样式
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);
  //画面パラメーター情報の取得
  const terminalid = cEmptyToNull(props.match.params.id);   //端末ID
  //曜日
  const days = [        //曜日
    {
      id: "0",                //
      day: "日",              //曜日
    },
    {
      id: "1",                //
      day: "月",              //曜日
    },
    {
      id: "2",                //
      day: "火",              //曜日
    },
    {
      id: "3",                //
      day: "水",              //曜日
    },
    {
      id: "4",                //
      day: "木",              //曜日
    },
    {
      id: "5",                //
      day: "金",              //曜日
    },
    {
      id: "6",                //
      day: "土",              //曜日
    },
  ];
  //画面編集項目＜初期状態＞
  var initVals = {
    id: "",       //端末ID
    idCompany: "",       //所属会社
    idCompanyName: "",       //所属会社名
    idGroup: "",       //所属設置場所
    idGroupName: "",       //所属設置場所名
    name: "",       //端末名
    place: "",       //設置場所説明
    status: 0,       //端末ステータス
    statusInfo: "",  //端末ステータス情報
    runTimeLst: [],  //稼働時間リスト
    deviceStartTime: "",       //＜放送条件＞開示時刻
    deviceEndTime: "",       //＜放送条件＞終了時刻
    devicePower:0,   // デバイスパワー:0->停止;1->起動
    sizeX: "",       //端末サイズ（縦）
    sizeY: "",       //端末サイズ（横）
    deviceMatrix: [],//構成機器のマトリックス
    suite: 0,       //連携端末区分　0　非　1　是
    sendAlertMail: 0,// アラートメール 0:ON / 1:OFF
    lst: [],         //構成機器リスト   ［］
    terminalSetInfo: {
        // name: "",       //端末名
        // suit_main: "",       //連携端末MASTER区分
        // feature: "",       //機種
        // deviceid: "",
        // ssid: "",
        name    : "",            //機器名称
        location: "",            //位置
        localvediolst: [],       //端末動画リスト  ［］
        playlst: [],       //PLAYLISTリスト  ［］
    }                      //配信設定情報
  };
  const [vals, setVals] = React.useState(initVals);
  const [selectedRow, setSelectedRow] = React.useState(props.location.state ? props.location.state.id : '');
  const [search, setSearch] = React.useState(props.location.state ? props.location.state.searchConds : '');

  //何もしない
  const doNothing = () => { };

  // 初期化処理
  React.useEffect(() => {
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatDetailData(terminalid,props);
  }, [terminalid,props]);    //[] 忘れないよう…　初期化

  // deviceErrorLst::deviceError 0->端末から接続がない;1->起動異常;2->停止異常;3-アップロード異常;4->端末と配信ツールでステータス(開始、停止)が違い
  const hasDeviceError = (deviceErrorLst, deviceError) => {
    for (var i = 0; i < deviceErrorLst.length; i++) {
      if (deviceErrorLst[i].deviceError === deviceError) {
        return true;
      }
    }
    return false;
  }

  const formatStatusInfo = (tVals, deviceErrorLst) => {
    var tStatusInfo;
    if (deviceErrorLst.length === 0) {
      tStatusInfo = {
        msgContent: '端末は正常に動作しています',
      };
    } else {
      var errorMsg = [];
      // deviceErrorLst::deviceError 0->端末から接続がない
      if (hasDeviceError(deviceErrorLst, 0)) {
        errorMsg.push({
          msgTitle: '端末と通信できません',
          msgContent: [
            { content: '端末と通信できません。' },
            { content: '端末に電源が入っているか、' },
            { content: '端末がWiFiに繋がっているか、ご確認ください。' },
          ],
        });
      }
      // deviceErrorLst::deviceError 1->起動異常
      if (hasDeviceError(deviceErrorLst, 1)) {
        errorMsg.push({
          msgTitle: '端末の起動に失敗しました',
          msgContent: [
            { content: '端末の起動に失敗しました。' },
            { content: '配信ツールから、端末の起動を行ってください。' },
            { content: 'それでも正常に起動しない場合、手動で端末を起動してください。' },
          ],
        });
      }
      // deviceErrorLst::deviceError 2->停止異常
      if (hasDeviceError(deviceErrorLst, 2)) {
        errorMsg.push({
          msgTitle: '端末の停止に失敗しました',
          msgContent: [
            { content: '端末の停止に失敗しました。' },
            { content: '配信ツールから、端末の停止を行ってください。' },
            { content: 'それでも正常に停止しない場合、手動で端末の停止処理を行ってください。' },
          ],
        });
      }
      // deviceErrorLst::deviceError 3-アップロード異常
      if (hasDeviceError(deviceErrorLst, 3)) {
        errorMsg.push({
          msgTitle: 'プレイリストが正常にアップロードされていません',
          msgContent: [
            { content: '端末に保存されている動画と、設定されている動画が異なっています。' },
            { content: 'プレイリストのアップロードを行ってください。' },
          ],
        });
      }
      // deviceErrorLst::deviceError 4-端末と配信ツールでステータス(開始、停止)が違い
      if (hasDeviceError(deviceErrorLst, 4)) {
        errorMsg.push({
          msgTitle: '端末と配信ツールのステータスが違います。',
          msgContent: [],
        });
      }
      tStatusInfo = {
        errorMsg: errorMsg,
      };
    }
    tVals["statusInfo"] = tStatusInfo;
  }

  //===========================================
  //検索及び改ページなど
  //===========================================
  const formatDetailData = (id,tProps) => {
    var url = BASE_URL + "/API/TerminalList/Get";
    var data = {
      id: cEmptyToNull(id)
    };
    var tResult;
    Service(tProps, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        var info = resp.infos;
        //画面編集項目（SET）            
        getGroupsInfo(cNullToEmpty(info.company_id));

        tResult = {
          id: info.id,
          idCompany: cNullToEmpty(info.company_id),
          idCompanyName: cNullToEmpty(info.company_name),
          idGroup: cNullToEmpty(info.group_terminal_id),
          idGroupName: cNullToEmpty(info.group_name),
          name: cNullToEmpty(info.name),
          place: cNullToEmpty(info.place),
          status: info.status,
          runTimeLst: info.runTimeLst,
          deviceStartTime: cNullToEmpty(info.deviceStartTime),       //＜放送条件＞開示時刻
          deviceEndTime: cNullToEmpty(info.deviceEndTime),         //＜放送条件＞終了時刻
          devices: info.devices,
          deviceErrorLst: info.deviceErrorLst,
          devicePower: info.devicePower,   // デバイスパワー:0->停止;1->起動
          sizeY: info.sizeY,
          sizeX: info.sizeX,
          deviceMatrix: [],       //構成機器のマトリックス
          suite: cNullToEmpty(info.suite),
          sendAlertMail: info.sendAlertMail, // アラートメール 0:ON / 1:OFF
          // feature       : cNullToEmpty(info.feature),
          // ssid          : cNullToEmpty(info.ssid),
          updateIndex: info.updateIndex,                 //更新の場合、必ず必須
          viewId: "TerminalDetail",
          lst: info.lst
        };

        var tDeviceMatrixX = [];
        var tDeviceMatrix = [];
        for (let idxY = 0; idxY < tResult.sizeY; idxY++) {
          for (let idxX = 0; idxX < tResult.sizeX; idxX++) {
            tDeviceMatrixX.push({ id: (idxY * tResult.sizeX + idxX + 1) + "", });
          }
          tDeviceMatrix.push(tDeviceMatrixX);
          tDeviceMatrixX = [];
        }
        tResult["deviceMatrix"] = tDeviceMatrix;

        formatStatusInfo(tResult, info.deviceErrorLst);

        setVals(tResult);
      } else {
        //その他APIエラー  
        //検索結果（初期状態） 
        tResult = {
          id                : "",       //端末ID
          idCompany         : "",       //所属会社
          idCompanyName     : "",       //所属会社名
          idGroup           : "",       //所属設置場所
          idGroupName       : "",       //所属設置場所名
          name              : "",       //端末名
          place             : "",       //設置場所説明
          status            : 0,
          runTimeLst        : [],       //稼働時間
          deviceStartTime         : "",       //＜放送条件＞開示時刻
          deviceEndTime           : "",       //＜放送条件＞終了時刻
          devices           : [],
          deviceErrorLst    : [],
          sizeY             : "",       //端末サイズ（縦）
          sizeX             : "",       //端末サイズ（横）
          deviceMatrix      : [],       //構成機器のマトリックス
          suite             : 0,        //連携端末区分　0　非　1　是
          sendAlertMail     : 0,        // アラートメール 0:ON / 1:OFF
          lst               : [],       //構成機器リスト   ［］
          statusInfo        : {
            msgContent      : null,
            errorMsg        : null,
          },
        };  
        setVals(tResult);
        setMsgInfo([true, resp.msg, 'warning']);
      }
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };

  //===========================================
  //編集発生する時
  //===========================================
  const handleEditOnChange = (name, value) => {
    let tVals = vals;
    //STATE内容、項目名、入力値
    validate(tVals, name, value);
    //情報をSTATEに保存する。
    tVals[name] = value;
    // var tResult = JSON.parse(JSON.stringify(tVals));
    var tResult = Object.assign({}, tVals);
    setVals(tResult);
  }

  //入力チェック
  function validate(tVals, name, value) {
    // //チェック処理
    // if (name === 'idGroup') {
    //   if (isNotPassNullChk(value)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
    //   } else {
    //     tVals["chk_" + name] = "success";
    //     tVals["msg_" + name] = "";
    //   }
    // } else if (name === 'place') {
    //   if (isNotPassNullChk(value)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
    //   } else if (isNotPassLengthChk(value, null, 255)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 255);
    //   } else {
    //     tVals["chk_" + name] = "success";
    //     tVals["msg_" + name] = "";
    //   }
    // } else if (name === 'deviceStartTime') {
    //   if (isNotPassNullChk(value)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
    //   } else if (isNotPassTimeChk(value)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_010;
    //   } else if (!isNotPassNullChk(tVals.deviceEndTime) && isNotPassTimeCompareChk(value, tVals.deviceEndTime)) {
    //      tVals["chk_" + name] = "error";
    //      tVals["msg_" + name] = VALIDATE_MSG.MSG_011;
    //   } else {
    //     tVals["chk_" + name] = "success";
    //     tVals["msg_" + name] = "";
    //   }
    // } else if (name === 'deviceEndTime') {
    //   if (isNotPassNullChk(value)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
    //   } else if (isNotPassTimeChk(value)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_010;
    //   } else if (!isNotPassNullChk(tVals.deviceStartTime) && isNotPassTimeCompareChk(tVals.deviceStartTime, value)) {
    //      tVals["chk_" + name] = "error";
    //      tVals["msg_" + name] = VALIDATE_MSG.MSG_011;
    //   } else {
    //     tVals["chk_" + name] = "success";
    //     tVals["msg_" + name] = "";
    //   }
    // } else    if (name === 'name') {
    //   if (isNotPassNullChk(value)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
    //   } else if (isNotPassLengthChk(value, null, 100)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
    //   } else {
    //     tVals["chk_" + name] = "success";
    //     tVals["msg_" + name] = "";
    //   }
    // }else if(name === 'feature'){
    //   if (isNotPassNullChk(value)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
    //   } else if (isNotPassLengthChk(value, null, 100)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
    //   } else {
    //     tVals["chk_" + name] = "success";
    //     tVals["msg_" + name] = "";
    //   }
    // }else if(name === 'deviceid'){
    //   if (isNotPassNullChk(value)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
    //   } else if (isNotPassLengthChk(value, null, 100)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
    //   } else {
    //     tVals["chk_" + name] = "success";
    //     tVals["msg_" + name] = "";
    //   }
    // }else if(name === 'ssid'){
    //   // if (isNotPassNullChk(value)) {
    //   //   tVals["chk_" + name] = "error";
    //   //   tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
    //   // } else 
    //   if (isNotPassLengthChk(value, null, 50)) {
    //     tVals["chk_" + name] = "error";
    //     tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 50);
    //   } else {
    //     tVals["chk_" + name] = "success";
    //     tVals["msg_" + name] = "";
    //   }
    // }
  };

  //入力チェック
  function validateAll() {  
    var tVals = vals;
    //一括チェック
    validate(tVals,'idGroup',tVals.idGroup);
    validate(tVals,'place',tVals.place);
    validate(tVals,'deviceStartTime',tVals.deviceStartTime);
    validate(tVals,'deviceEndTime',tVals.deviceEndTime);
    
    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if(tVals["chk_place"] === "error" ||
    tVals["chk_idGroup"] === "error" ||
    tVals["chk_deviceStartTime"] === "error" ||
    tVals["chk_deviceEndTime"] === "error" ){
      
      return true;
      
    }
    
    var i;
    for(i = 0;i < tVals.lst.length ;i++ ){
      validate(tVals.lst[i],'name',tVals.lst[i].name);
      validate(tVals.lst[i],'feature',tVals.lst[i].feature);
      validate(tVals.lst[i],'deviceid',tVals.lst[i].deviceid);
      validate(tVals.lst[i],'ssid',tVals.lst[i].ssid);

      //エラーがある場合、ＭＳＧを出す、処理を中断する。
      if( tVals.lst[i]["chk_name"] === "error" ||
          tVals.lst[i]["chk_feature"] === "error" ||
          tVals.lst[i]["chk_deviceid"] === "error" ||
          tVals.lst[i]["chk_ssid"] === "error" ){

          return true;
      }
    }

    // 連携端末のプレイリストの一括チェック
    if(validatePlayList()){
      return true;
    }

    return false;

  };

  const validatePlayList = () => {  
    var tVals = vals;
    // 連携端末の一括チェック
    if(vals.lst.length <= 1){
      tVals["chk_playlist"] = "success";
      tVals["msg_playlist"] = "";
      return false;
    }

    // 連携端末の一括チェック
    for(let idxTerm=0; idxTerm<tVals.lst.length; idxTerm++ ){
      // プレイリストの一括チェック
      let playlsts = tVals.lst[idxTerm].playlst;
      if(playlsts.length > 1){
        tVals["chk_playlist"] = "error";
        tVals["msg_playlist"] = VALIDATE_MSG.MSG_514;
        return true;
      } else if(playlsts.length === 1){
        // プレイリスト-項目の一括チェック
        let playlstItems = playlsts[0].playlist;
        if(playlstItems !== undefined){
          if(playlstItems.length > 1){
            tVals["chk_playlist"] = "error";
            tVals["msg_playlist"] = VALIDATE_MSG.MSG_514;
            return true;
          } else if(playlstItems.length === 1){
            if(playlstItems[0].loop_count > 1){
              tVals["chk_playlist"] = "error";
              tVals["msg_playlist"] = VALIDATE_MSG.MSG_514;
              return true;
            }
          }
        }
      }
    }

    tVals["chk_playlist"] = "success";
    tVals["msg_playlist"] = "";
    var tResult = Object.assign({}, tVals);
    setVals(tResult);
    return false;
  };


  //編集ボタン押す
  const handleClickEditBtn = () => {
    props.history.push({pathname: '/TerminalList/Edit/' + vals.id, state: { goBackUrl: '/TerminalList', searchConds: search }});
  };

  //端末削除ボタン押す
  const handleClickDeleteBtn = () => {
    alert("端末を削除ボタンを押す");
  };

  //保存ボタン押す
  const handleClickSaveBtn = () => {
    var tVals = vals;
    if(validateAll()){
      var tResult = Object.assign({}, tVals);
      setVals(tResult);
      return;
    }
    
    //進捗画面
    setIsLoading(true);
    // console.log("======================");
    // console.log(tVals);

    var url = BASE_URL + "/API/TerminalList/Update";

    var data = tVals;

    Service(props, url, data).then(resp => {
      setIsLoading(false);

      // 正常系
      if (resp.status === 0) {

        setMsgInfo([true, resp.msg, 'warning']);
        //情報のロード
        //formatDetailData(terminalid);
        props.history.push('/TerminalList');
      } else {
        //その他APIエラー      
        setMsgInfo([true, resp.msg, 'warning']);
      }
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  }

  //===========================================
  //TAB操作関連
  //===========================================
  const [tabIndex, setTabIndex] = React.useState(0);

  //変更
  const handleChange = (event, newIndex) => {
    if (newIndex === undefined) return;
    setTabIndex(newIndex);
  };

  //追加
  const handleAddTab = () => {
    var tVals = vals;
    tVals.lst.push(
      {
        termid        : "",                   //
        name          : "新規",                   //
        feature       : "",                   //
        deviceid      : "",                   //
        suit_main     : "",                   //
        ssid          : "",                   //
        playlst       : [],                   //
        localvediolst : [],                   //
      }
    )
    let tResult = Object.assign({}, tVals);
    setVals(tResult);
  };

  // //確認メッセージ後の処理
  // React.useEffect(() => {
  //   //端末削除確認結果
  //   if (confirmInfo[2].opt === "delete_term" && confirmInfo[2].result === 1) {
  //     var tVals = vals;
  //     tVals.lst.splice(tabIndex, 1);
  //     let tResult = Object.assign({}, tVals);
  //     setTabIndex(0);
  //     setVals(tResult);
  //   }
  // }, [confirmInfo]);

  //確認メッセージ後の処理
  React.useEffect(() => {

    // コマンド処理：起動、停止、ダウンロード、同期など
    const doCommand = (data) => {
      var url = BASE_URL + "/API/TerminalCommand";
      setIsLoading(true);

      //更新処理
      Service(props, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {
          setMsgInfo([true, resp.msg, 'success']);

          //情報のロード
          formatDetailData(terminalid, props);
        } else {
          setMsgInfo([true, resp.msg, 'danger']);
        }
        //進捗画面を閉じる
        setIsLoading(false);
      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });

    };

    //ダウンロード処理
    const doCommandDownload = (obj) => {
      //　入力データ
      var data = {
        commandid: 1,    // コマンドID：ダウンロード
        termids: [cEmptyToNull(obj.id)],   // 端末ID集
      };
      // console.log(data);

      doCommand(data);
    };

    //リセット処理
    const doCommandReset = (obj) => {
      //　入力データ
      var data = {
        commandid: 2,    // コマンドID：リセット
        termids: [cEmptyToNull(obj.id)],   // 端末ID集
      };
      // console.log(data);

      doCommand(data);
    };

    //電源切り処理
    const doCommandPoweroff = (obj) => {
      //　入力データ
      var data = {
        commandid: 3,    // コマンドID：電源切り
        termids: [cEmptyToNull(obj.id)],   // 端末ID集
      };
      // console.log(data);

      doCommand(data);
    };


    //電源オン処理
    const doCommandPoweron = (obj) => {
      //　入力データ
      var data = {
        commandid: 4,    // コマンドID：電源オン
        termids: [cEmptyToNull(obj.id)],   // 端末ID集
      };
      // console.log(data);

      doCommand(data);
    };

    //同期処理
    const doCommandSync = (obj) => {
      //　入力データ
      var data = {
        commandid: 6,    // コマンドID：同期
        termids: [cEmptyToNull(obj.id)],   // 端末ID集
      };
      // console.log(data);

      doCommand(data);
    };

    //削除処理
    function doDel(obj) {
      //編集IDX取得
      setIsLoading(true);
      var url = BASE_URL + "/API/TerminalList/Delete";
      //　入力データ
      var data = {
        id: cEmptyToNull(obj.id),
      };
      // console.log(data);
      //更新処理
      Service(props, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {
          setMsgInfo([true, resp.msg, 'success']);
          // //情報のロード
          // formatDetailData(search, props);
          goBack(props);
        } else {
          setMsgInfo([true, resp.msg, 'danger']);
        }
        //進捗画面を閉じる
        setIsLoading(false);
      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });
    };

    //起動確認結果
    if (confirmInfo[2].opt === "del" && confirmInfo[2].result === 1) {
      doDel(confirmInfo[2].id);
      //ダウンロード確認結果
    } else if (confirmInfo[2].opt === "download" && confirmInfo[2].result === 1) {
      doCommandDownload(confirmInfo[2].id);
      //電源オン確認結果
    } else if (confirmInfo[2].opt === "start" && confirmInfo[2].result === 1) {
      doCommandPoweron(confirmInfo[2].id);
      //電源切り確認結果
    } else if (confirmInfo[2].opt === "stop" && confirmInfo[2].result === 1) {
      doCommandPoweroff(confirmInfo[2].id);
      //同期確認結果
    } else if (confirmInfo[2].opt === "sync" && confirmInfo[2].result === 1) {
      doCommandSync(confirmInfo[2].id);
      //リセット確認結果
    } else if (confirmInfo[2].opt === "reset" && confirmInfo[2].result === 1) {
      doCommandReset(confirmInfo[2].id);
    }

  }, [confirmInfo, props]);

  //同期ボタン押す
  const handleClickSync = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_511,
      {
        "opt": "sync",
        "id": obj,
        "result": 0
      }
    ]);
  };

  // ダウンロードボタン押す
  const handleClickDownloadBtn = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_508,
      {
        "opt": "download",
        "id": obj,
        "result": 0
      }
    ]);
  };

  //画面動作 DEL
  const handleClickStartDel = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        "opt": "del",
        "id": obj,
        "result": 0
      }
    ]);
  };

  //削除
  const handleDelTab = () => {
    var tVals = vals;
    if(vals.lst.length <= 1){
      tVals["chk_playlist"] = "error";
      tVals["msg_playlist"] = VALIDATE_MSG.MSG_202;
      setVals(Object.assign({}, vals));
      return;
    } else if(tVals["msg_playlist"] === VALIDATE_MSG.MSG_202){
      tVals["chk_playlist"] = "success";
      tVals["msg_playlist"] = "";
      setVals(Object.assign({}, vals));
    }

    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        "opt"     : "delete_term",
        "id"      : "",
        "result"  : 0
      }
    ]);
  };


  //TAB
  const handleOnTabEdit = (obj) => {
    var tVals = vals;
    tVals.lst[tabIndex] = obj;
    var tResult = Object.assign({}, tVals);
    setVals(tResult);
    validatePlayList();

    // 変更するPlayListチェックエラーの場合、変更前に退避する
    tVals = vals;
    if (tVals["chk_playlist"] === "error") {
      obj.playlst = obj.playListBeforeAdd;
      tVals.lst[tabIndex] = obj;
      tResult = Object.assign({}, tVals);
      setVals(tResult);
    }
  }

  const setReloadInfo = (obj) => {
    var tVals = vals;
    tVals.lst = obj;
    var tResult = Object.assign({}, tVals);
    setVals(tResult);
  }

  const handleOnValidCompany = (obj) => {
  };

  const handleOnValidPlayList = (obj) => {
    validatePlayList();
    var tResult = Object.assign({}, vals);
    setVals(tResult);
  }

  return (
    <Box>
      <Grid container spacing={1} >
        <Grid item xs={3} sm={3} className={classes.addTitle}>
          {/* 端末追加 */}
          {/* <div><h3>端末追加</h3></div> */}
        </Grid>
        <Grid item xs={9} sm={9} className={classes.optDel}>
          <Button color='warning' onClick={handleClickEditBtn}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;編&nbsp;集&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
          <Button color='danger' onClick={() => handleClickStartDel(vals)}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;削&nbsp;除&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
        </Grid>
      </Grid>
      {vals.deviceErrorLst && vals.deviceErrorLst.length > 0 ?
        <Grid container spacing={1}>
          <GridItem xs={12} sm={12}>
            <Card className={classes.card}>
              <CardHeader className={classes.detailCardHeader}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    {/* ステータス（異常あり） */}
                    <div className={classes.areaTitle, classes.status}>
                      <h4>ステータス</h4>
                      <Button round color='danger'>
                        異常あり
                    </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody className={classes.statusBody}>
                {vals.statusInfo.errorMsg ? vals.statusInfo.errorMsg.map((item, key) =>
                  <Grid container spacing={1} key={100100 + key}>
                    <Grid item xs={12} sm={12}>
                      {/* ステータスメッセージ（異常あり） */}
                      <InputLabel className={classes.selectLabel, classes.msgHeader} key={item.msgTitle}>{item.msgTitle}</InputLabel>
                      {item.msgContent ? item.msgContent.map((msgItem, key) =>
                        <InputLabel className={classes.selectLabel, classes.msgBody} key={msgItem.content}>{msgItem.content}</InputLabel>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
        :
        <Grid container spacing={1}>
          <GridItem xs={12} sm={12}>
            <Card className={classes.card}>
              <CardHeader className={classes.detailCardHeader}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    {/* ステータス（正常） */}
                    <div className={classes.areaTitle, classes.status}>
                      <h4>ステータス</h4>
                      <Button round className={classes.statusSuccess}>
                        正常
                    </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody className={classes.statusBody}>
                <Grid container spacing={1} >
                  <Grid item xs={12} sm={12}>
                    <InputLabel className={classes.selectLabel, classes.msgHeader}>{vals.statusInfo.msgContent}</InputLabel>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      }
      <Grid container spacing={1} disabled={true}>
        <GridItem xs={12} sm={12}>
          <Card className={classes.card}>
            <CardHeader className={classes.detailCardHeader}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  {/* 端末概要 */}
                  <div className={classes.areaTitle}><h4>端末概要</h4></div>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid container spacing={1} >
                <Grid item xs={6} sm={6}>
                  {/* 設置場所 */}
                  <div className={classes.areaTitle}><h4>設置場所</h4></div>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  {/* 企業名（必須） */}
                  <InputLabel className={classes.selectLabel, classes.inputLabel}>企業名</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                  <Box style={{ width: "90%" }} className={classes.customInput}>
                    <CustomInput
                      id="idCompanyName"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        value: vals.idCompanyName,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  {/* 設置場所名（必須） */}
                  <InputLabel className={classes.selectLabel, classes.inputLabel}>設置場所名</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                  <Box style={{ width: "90%" }} className={classes.customInput}>
                    <CustomInput
                      id="idGroupName"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        value: vals.idGroupName,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  {/* 設置場所概要 */}
                  <InputLabel className={classes.selectLabel, classes.inputLabel}>設置場所概要</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                  <Box style={{ width: "90%" }} className={classes.customInput}>
                    <CustomInput
                      id="place"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        value: vals.place,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ paddingTop: "20px" }}>
                <Grid item xs={6} sm={6}>
                  {/* 端末情報 */}
                  <div className={classes.areaTitle}><h4>端末情報</h4></div>
                </Grid>
              </Grid>
              {/* <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  {/* 端末名称 */}{/* 
                  <InputLabel className={classes.selectLabel, classes.inputLabel}>端末名称</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                  <Box style={{ width: "90%" }} className={classes.customInput}>
                    <CustomInput
                      id="name"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        value: vals.name,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid> */}
{/*               <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  {/* 稼働時間（必須） */}{/* 
                  <InputLabel className={classes.selectLabel, classes.inputLabel}>稼働時間</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9} className={classes.runTimeInput}>
                  {vals.runTimeLst ? vals.runTimeLst.map((item, key) =>
                    <Grid container spacing={1} style={{ textAlign: "left", width: "100%" }} key={1100 + key}>
                      {/* 追加する稼働時間 */}{/* 
                      <InputLabel className={classes.selectLabel, classes.runTimeLabel}>
                        {days[parseInt(item.idDay)].day}&nbsp;{item.deviceStartTime}〜{item.deviceEndTime}
                      </InputLabel>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid> */}
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  {/* 端末サイズ（必須） */}
                  <InputLabel className={classes.selectLabel, classes.inputLabel}>端末サイズ</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                  <Box style={{ width: "90%" }} className={classes.customInput}>
                    <CustomInput
                      id="size"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        value: vals.sizeY + " × " + vals.sizeX,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ display: "none" }}>
                <Grid item xs={3} sm={3}>
                  {/* メール送信 */}
                  <InputLabel className={classes.selectLabel, classes.inputLabel}>メール送信</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                  <Box style={{ width: "90%" }} className={classes.customInput}>
                    <CustomInput
                      id="sendAlertMail"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        value: parseInt(vals.sendAlertMail, 10) === 1 ? "ON" : "OFF",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{paddingTop: "20px"}}>
                <Grid item xs={6} sm={6}>
                  {/* 構成機器 */}
                  <div className={classes.areaTitle}><h4>構成機器</h4></div>
                </Grid>
              </Grid>
              <Box xs={12} className={classes.tableBox}>
                <Paper className={classes.tableCardBody}>
                  <TableContainer className={classes.table}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHead} style={{ width: "19%" }}>位置</TableCell>
                          <TableCell className={classes.tableHead} style={{ width: "27%" }}>製造番号</TableCell>
                          <TableCell className={classes.tableHead} style={{ width: "27%" }}>名称</TableCell>
                          <TableCell className={classes.tableHead} style={{ width: "27%" }}>機種</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBody}>
                        {vals.lst ? vals.lst.map((item, key) =>
                          <TableRow
                            className={selectedRow === item.deviceid ? classes.tableRow2 : classes.tableRow}
                            onMouseOut={() => setSelectedRow('')}
                            key={2000 + key}
                          >
                            <TableCell className={classes.tableCell} >
                              {key + 1}
                            </TableCell>
                            <TableCell className={classes.tableCellLeft}>
                              {item.deviceid}
                            </TableCell>
                            <TableCell className={classes.tableCellLeft}>
                              {item.name}
                            </TableCell>
                            <TableCell className={classes.tableCellLeft}>
                              {item.feature}
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
              {/* 構成機器のマトリックス */}
              {vals.lst.length > 0 ?
                <Box xs={12} className={classes.deviceMatrixTableBox} justify="center">
                  <Paper className={classes.deviceMatrixTableCardBody}>
                    <TableContainer className={classes.table}>
                      <Table stickyHeader>
                        <TableBody className={classes.tableBody}>
                          {vals.deviceMatrix ? vals.deviceMatrix.map((itemY, keyY) =>
                            <TableRow
                              key={3000 + keyY}
                            >
                              {itemY ? itemY.map((itemX, keyX) =>
                                <TableCell className={classes.deviceMatrixTableCell} key={4000000 + 1000 * keyY + keyX}>
                                  <Pagination
                                    pages={[
                                      { active: true, text: Number(itemX.id), onClick: doNothing },
                                    ]}
                                  />
                                </TableCell>
                              ) : null}
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
                : null}
            </CardBody>
          </Card>
        </GridItem>
      </Grid>

      <Grid container spacing={1}>
        <GridItem xs={12} sm={12}>
          <Card className={classes.card}>
            <CardHeader className={classes.detailCardHeader}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6}>
                  {/* 端末名称 */}
                  {vals.suite === 0 ?
                  <div className={classes.areaTitle}><h4>配信設定（単体モード）</h4></div>
                   : 
                  <div className={classes.areaTitle}><h4>配信設定（連携モード）</h4></div>
                  }
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <TerminalDetailItem
                {...props}
                value={tabIndex}
                info={vals.lst}
                devices={vals.devices}
                deviceErrorLst={vals.deviceErrorLst}
                hasDeviceError={hasDeviceError}
                idCompany={vals.idCompany}
                termid={vals.id}
                pageid="TerminalDetail"
                suite={vals.suite}
                setInfo={handleOnTabEdit}
                setReloadInfo={setReloadInfo}
                setTabIndex={setTabIndex}
                handleClickSync={() => handleClickSync(vals)}
                handleClickDownloadBtn={() => handleClickDownloadBtn(vals)}
                validCompany={handleOnValidCompany}
                validPlayList={handleOnValidPlayList} />
            </CardBody>
            <Box style={{ textAlign: "left", margin: "0 70px 25px"}} className={classes.selectHelpTextErr}>{vals.msg_playlist}</Box>
          </Card>
        </GridItem>
      </Grid>
      {/* 共通ダイアログ画面 */}
      <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Box>
  );
}
