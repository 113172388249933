import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import InputLabel from "@material-ui/core/InputLabel";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from "@material-ui/core/Hidden";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Stop from "@material-ui/icons/Stop";
import Delete from "@material-ui/icons/Delete";
import PlayForWork from "@material-ui/icons/PlayForWork";
import Autorenew from "@material-ui/icons/Autorenew";
import Sync from "@material-ui/icons/Sync";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// import Showdetail from "@material-ui/icons/List";

// core components

import Button from "components/CustomButtons/Button.js";

// exp components
import NBrootsPagination from "components/@NBrootsPagination/NBrootsPagination.js";
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";

import { BASE_URL } from 'variables/config.js';
import Service from "utils/service/service.js"
import cEmptyToNull from "utils/functions/cEmptyToNull"
import cStatusToString from "utils/functions/cStatusToString"
import millisecondFormat from "utils/functions/millisecondFormat";

import GroupListMntPopup from "views/CommonPopup/GroupListMntPopup.js";
import { initSystemData } from 'layouts/initSystemData.js';

import styles from "assets/jss/material-dashboard-pro-react/views/TerminalList/terminalListStyle.js";

// メモリ中企業リスト
import { userInfo, corpsInfo, groupsInfo , getGroupsInfo } from 'variables/runtimeInfos.js';
import { PAGINATION_PREV_NAME,PAGINATION_NEXT_NAME, VALIDATE_MSG, CORP_ID_SELECTED_ALL } from 'variables/config.js';

export default function TerminalList(props) {
  //样式
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);
  //検索条件（初期状態）
  var tSearch = {
    crop      : userInfo.userlevel === 2? CORP_ID_SELECTED_ALL:userInfo.corpid,           //検索条件　企業ID
    group     : CORP_ID_SELECTED_ALL,           //検索条件　設置場所ID
    orderby   : 'update_time',          //orderby
    sorttype  : 'desc',                 //sorttype
    pageIndex : 1,            //当ページ番号
    pageSize  : 10            //ページサイズ
  }
  const [search, setSearch] = React.useState(props.location.state ? props.location.state.searchConds : tSearch);
  const [selectedRow, setSelectedRow] = React.useState(props.location.state ? props.location.state.id : '');
  const [selectedErrorRow, setSelectedErrorRow] = React.useState(props.location.state ? props.location.state.id : '');
  //検索結果（初期状態）
  var tSearchResult = {
    total     : 0,            //記録数
    lst       : []            //明細リスト
  }
  const [searchResult, setSearchResult] = React.useState(tSearchResult);
  //検索結果（異常状態）
  var tSearchErrorResult = {
    total: 0,            //記録数
    lst: []            //明細リスト
  }
  const [searchErrorResult, setSearchErrorResult] = React.useState(tSearchErrorResult);
  //設置場所管理画面用
  var tGroupNameInfo = [
    false,          //Popup画面表示FLG
    {               //情報<当画面未使用>
    },
    false           //保存ボタン押すFLG<当画面未使用>
  ]
  //場所追加画面関連情報
  const [groupNameInfo, setGroupNameInfo] = React.useState(tGroupNameInfo);

  // 設置場所情報
  getGroupsInfo(search.crop);
  
  //15秒　状態更新
  React.useEffect(() => {
    const interval = setInterval(() => {
            //進捗画面
      setIsLoading(true);
      //情報のロード
      formatLstData(search);

    }, 15000);

    return () => {
      clearInterval(interval);
    };

  }, [search]);


  //検索条件変更後、 React.useEfect(() => {}, [search]); 発動、検索ロジックを行う
  React.useEffect(() => {
    //進捗画面
    setIsLoading(true);

    //情報のロード
    formatLstData(search,props);

  }, [search,props]);   

  //設置場所管理変更後、 React.useEffect(() => {}, [設置場所管理]); 初期化を再ローディング
  React.useEffect(() => {
    setIsLoading(true);
    //起動後初期化処理
    initSystemData(props)
    .then((status)=> {
      getGroupsInfo(search.crop);
      setIsLoading(false);
    }).catch(resp => {
      setIsLoading(false);
    });
  }, [groupNameInfo]); 

  // 获取端末的状态
  const getDeviceStatusCode = (item) => {
    if(item.devices.length === 0){
      return 4;
    }

    for(var i = 0;i < item.devices.length ;i++ ){
      // 普通端末
      if(item.devices[i].suite === 0){
        return item.devices[i].deviceStatusCode;
      } 
      else if((item.devices[i].suite === 2)  // スイート端末
        && (item.devices[i].suiteMain === 1)){ // スイート-main端末
        return item.devices[i].deviceStatusCode;
      } 
    }

    return item.devices[0].deviceStatusCode;
  };
  
  const formatDeviceStatusString = (deviceStatusCode) => {
    return cStatusToString(deviceStatusCode);
  };

  // 获取端末的电源状态
  const formatDevicePower = (item) => {
    if(item.devices.length === 0){
      return 0;
    }

    for(var i = 0;i < item.devices.length ;i++ ){
      // 普通端末
      if(item.devices[i].suite === 0){
        return item.devices[i].devicePower;
      } 
      else if((item.devices[i].suite === 2)  // スイート端末
        && (item.devices[i].suiteMain === 1)){ // スイート-main端末
        return item.devices[i].devicePower;
      } 
    }

    return 0;
  };

  // deviceErrorLst::deviceError 0->端末から接続がない;1->起動異常;2->停止異常;3-アップロード異常;4->端末と配信ツールでステータス(開始、停止)が違い
  const hasDeviceError = (deviceErrorLst, deviceError) => {
    for (var i = 0; i < deviceErrorLst.length; i++) {
      if (deviceErrorLst[i].deviceError === deviceError) {
        return true;
      }
    }
    return false;
  }

  // 
  const formatDeviceStartDisabled = (item) => {
    if(item.devices.length === 0){
      return true;
    }

    // // 0->停止;1->起動;2->オンライン;3->問題あり;4->オフライン
    // var code = getDeviceStatusCode(item);
    // if(code === 4){
    // ph1 end
    // ph2
    // deviceErrorLst::deviceError 0->端末から接続がない
    if (hasDeviceError(item.deviceErrorLst, 0)) {
      return true;
    } 

    // 0->停止;1->起動
    var power = formatDevicePower(item);
    if(power === 0){
      return false;
    }

    return true;
  };

  // 
  const formatDeviceStopDisabled = (item) => {
    // // 0->停止;1->起動;2->オンライン;3->問題あり;4->オフライン
    // var code = getDeviceStatusCode(item);
    // if(code === 4){
    // ph1 end
    // ph2
    if (item.devices.length === 0) {
      return true;
    }
    // deviceErrorLst::deviceError 0->端末から接続がない
    if (hasDeviceError(item.deviceErrorLst, 0)) {
      return true;
    } 

    return formatDeviceStartDisabled(item)?false:true;
  };

  const formatDeviceOtherDisabled = (item) => {
    // // 0->停止;1->起動;2->オンライン;3->問題あり;4->オフライン
    // var code = getDeviceStatusCode(item);
    // if(code === 4){
    // ph1 end
    // ph2
    if (item.devices.length === 0) {
      return true;
    }
    // deviceErrorLst::deviceError 0->端末から接続がない
    if (hasDeviceError(item.deviceErrorLst, 0)) {
      return true;
    } 

    return false;
  };

  const formatLstData = (tSearchCondition, tProps) => {

    var url = BASE_URL + "/API/TerminalList/Search";

    var data = {
      companyid : cEmptyToNull(tSearchCondition.crop === CORP_ID_SELECTED_ALL ? '' : tSearchCondition.crop),
      groupid   : cEmptyToNull(tSearchCondition.group === CORP_ID_SELECTED_ALL ? '' : tSearchCondition.group),
      orderby   : tSearchCondition.orderby,
      sorttype  : tSearchCondition.sorttype,
      pageIndex : tSearchCondition.pageIndex,
      pageSize  : tSearchCondition.pageSize,
      hasErrorTerminalDevice  : "false"
    };

    // console.log("検索条件-----------");
    // console.log(data);

    var tResult;
    Service(tProps, url, data).then(resp => {

      // console.log("検索結果-----------");
      // console.log(resp);

      // 正常系
      if (resp.status === 0) {
        // var info = resp.infos;
        var info = resp.infos.pageInfo;
        var errorlst = resp.infos.errorlst;
        //Data情報の処理
        var lst = info.list;
        //検索結果（セット）
        tResult = {
          hasErrorTerminalDevice : resp.error,
          total     : info.total,
          lst       : [...lst],
          errorlst  : [...errorlst],
        }

        // console.log(tResult)
        setSearchResult(tResult);

      } else {
        //その他APIエラー   
        tResult = {
          hasErrorTerminalDevice : "false",
          total     : 0,
          lst       : [],
          errorlst  : [],
        }
        setSearchResult(tResult);

        setMsgInfo([true, resp.msg, 'warning']);
      }
      setIsLoading(false);
    }).catch(resp => {
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };

  //検索条件部
  //企業変更の場合
  const handleChangeSearchStart = (e) => {
    
    let t = search;    
    //選択情報をSTATEに保存
    if(e.target.name === 'corpSelect'){
      t.crop = e.target.value;
      //設置場所の連動,設置場所ない画面には要らない
      t.group = CORP_ID_SELECTED_ALL;
      
      // 設置場所情報
      getGroupsInfo(t.crop);
    }else if(e.target.name === 'groupSelect'){
      t.group = e.target.value;
    }

    //第1ページ番号
    //t.pageIndex=1;           

    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  }

  //インターフェースを更新するの場合
  const handleRefresh = (e) => {
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  }

  //改ページ処理
  // pageInfos   : [ページサイズ,当ページ番号]
  const handleChangePageStart = (page) => {

    let t = search;    

    if (page.disabled) return;
    if (page.text === search.pageIndex) return;

    if (page.text === PAGINATION_PREV_NAME) {
      t.pageIndex = t.pageIndex - 1;
    } else if (page.text === PAGINATION_NEXT_NAME) {
      t.pageIndex = t.pageIndex + 1;
    } else {
      t.pageIndex = page.text;
    }

    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  };

  
  //設置追加ボタン押す
  const handleClickAddGroupNameDialogOpen = () => {
    var tGroupNameInfo = [
      true,          //Popup画面表示FLG
      {               //情報
      },
      false           //保存ボタン押すFLG
    ]
    setGroupNameInfo(tGroupNameInfo);
  };


  //端末追加ボタン押す
  const handleClickAddDialogOpen = () => {

    props.history.push({pathname: '/TerminalList/Add', state: { goBackUrl: '/TerminalList', searchConds : search}});
  };

  //起動ボタン押す
  const handleClickStartBtn = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_506,
      {
        "opt"       : "start",
        "id"        : obj,
        "result"    : 0
      }
    ]);
  };

  //停止ボタン押す
  const handleClickStopBtn = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_507,
      {
        "opt"     : "stop",
        "id"      : obj,
        "result"  : 0
      }
    ]);
  };

  //リセットボタン押す
  const handleClickReset = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_510,
      {
        "opt"     : "reset",
        "id"      : obj,
        "result"  : 0
      }
    ]);
  };

  //同期ボタン押す
  const handleClickSync = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_511,
      {
        "opt"     : "sync",
        "id"      : obj,
        "result"  : 0
      }
    ]);
  };

  // ダウンロードボタン押す
  const handleClickDownloadBtn = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_508,
      {
        "opt"     : "download",
        "id"      : obj,
        "result"  : 0
      }
    ]);
  };

  // アラート通知ボタン押す
  function handleClickSendAlertMailBtn(obj, index, lstType) {
    setIsLoading(true);
    var url = BASE_URL + "/API/TerminalList/ChangeAlertNotice";

    //　入力データ
    var data = {
      id            : cEmptyToNull(obj.id),   // 端末ID
      sendAlertMail : obj.sendAlertMail       // アラート通知 1:ON / 0:OFF
    };
    //アラート通知変更処理
    Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        setMsgInfo([true, resp.msg, 'success']);

        reSetSearchResult(obj, index, lstType);
      } else {
        setMsgInfo([true, resp.msg, 'danger']);
      }
      //進捗画面を閉じる
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };

  // アラート通知　変更後
  const reSetSearchResult = (obj, index, lstType) => {
    let tResult = searchResult;
    var changeTo = parseInt(obj.sendAlertMail, 10) === 0 ? 1 : 0;
    var indexAnother = -1;
    var lstAnother;

    if (lstType === "lst") {
      tResult.lst[index].sendAlertMail = changeTo;
      lstAnother = tResult.errorlst;
    } else {
      tResult.errorlst[index].sendAlertMail = changeTo;
      lstAnother = tResult.lst;
    }

    for(let idx=0; idx<lstAnother.length; idx++ ){
      if (lstAnother[idx].id === obj.id) {
        indexAnother = idx;
        break;
      }
    }
  
    if (indexAnother > -1) {
      lstAnother[indexAnother].sendAlertMail = changeTo;
    }

    if (lstType === "lst") {
      tResult.errorlst = lstAnother;
    } else {
      tResult.lst = lstAnother;
    }

    // tResult = Object.assign({}, tVals);

    setSearchResult(tResult);
  };
  
  //変更ボタン押す + 変更画面遷移
  const handleClickEditDialogOpen = (obj) => {

    props.history.push({pathname: '/TerminalList/Detail/' + obj.id, state: { goBackUrl: '/TerminalList', searchConds : search, id: obj.id}});
  };

  //===========================================
  //削除機能
  //===========================================
  //画面動作 DEL
  const handleClickStartDel = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        "opt"     : "del",
        "id"      : obj,
        "result"  : 0
      }
    ]);
  };

  //確認メッセージ後の処理
  React.useEffect(() => {

    // コマンド処理：起動、停止、ダウンロード、同期など
    const doCommand = (data) => {
      var url = BASE_URL + "/API/TerminalCommand";
      setIsLoading(true);

      //更新処理
      Service(props, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {
          setMsgInfo([true, resp.msg, 'success']);

          //情報のロード
          formatLstData(search,props);
        } else {
          setMsgInfo([true, resp.msg, 'danger']);
        }
        //進捗画面を閉じる
        setIsLoading(false);
      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });

    };
    
    //ダウンロード処理
    const doCommandDownload = (obj) => {
      //　入力データ
      var data = {
        commandid   : 1,    // コマンドID：ダウンロード
        termids     : [cEmptyToNull(obj.id)],   // 端末ID集
      };
      // console.log(data);

      doCommand(data);
    };

    //リセット処理
    const doCommandReset = (obj) => {
      //　入力データ
      var data = {
        commandid   : 2,    // コマンドID：リセット
        termids     : [cEmptyToNull(obj.id)],   // 端末ID集
      };
      // console.log(data);

      doCommand(data);
    };

    //電源切り処理
    const doCommandPoweroff = (obj) => {
      //　入力データ
      var data = {
        commandid   : 3,    // コマンドID：電源切り
        termids     : [cEmptyToNull(obj.id)],   // 端末ID集
      };
      // console.log(data);

      doCommand(data);
    };
    

    //電源オン処理
    const doCommandPoweron = (obj) => {
      //　入力データ
      var data = {
        commandid   : 4,    // コマンドID：電源オン
        termids     : [cEmptyToNull(obj.id)],   // 端末ID集
      };
      // console.log(data);

      doCommand(data);
    };

    //同期処理
    const doCommandSync = (obj) => {
      //　入力データ
      var data = {
        commandid   : 6,    // コマンドID：同期
        termids     : [cEmptyToNull(obj.id)],   // 端末ID集
      };
      // console.log(data);

      doCommand(data);
    };

    //削除処理
    function doDel(obj){
      //編集IDX取得
      setIsLoading(true);
      var url = BASE_URL + "/API/TerminalList/Delete";
      //　入力データ
      var data = {
        id          : cEmptyToNull(obj.id),
      };
      // console.log(data);
      //更新処理
      Service(props, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {
          setMsgInfo([true, resp.msg, 'success']);
          //情報のロード
          formatLstData(search,props);
        } else {
          setMsgInfo([true, resp.msg, 'danger']);
        }
        //進捗画面を閉じる
        setIsLoading(false);
      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });
    };

    //起動確認結果
    if (confirmInfo[2].opt === "del" && confirmInfo[2].result === 1) {
      doDel(confirmInfo[2].id);
    //ダウンロード確認結果
    } else if (confirmInfo[2].opt === "download" && confirmInfo[2].result === 1) {
      doCommandDownload(confirmInfo[2].id);
    //電源オン確認結果
    } else if (confirmInfo[2].opt === "start" && confirmInfo[2].result === 1) {
      doCommandPoweron(confirmInfo[2].id);
      //電源切り確認結果
    } else if (confirmInfo[2].opt === "stop" && confirmInfo[2].result === 1) {
      doCommandPoweroff(confirmInfo[2].id);
    //同期確認結果
    } else if (confirmInfo[2].opt === "sync" && confirmInfo[2].result === 1) {
       doCommandSync(confirmInfo[2].id);
    //リセット確認結果
    } else if (confirmInfo[2].opt === "reset" && confirmInfo[2].result === 1) {
      doCommandReset(confirmInfo[2].id);
    }

  }, [confirmInfo,search,props]);

  return (
    <Box className={classes.terminalListBox}>
      <Grid container spacing={1}>
        {/* 要対応端末 */}
        <div><h4 className={classes.contentTitle}>要対応端末</h4></div>
      </Grid>
      {searchResult.total > 0 && searchResult.hasErrorTerminalDevice === "true" ?
        <Box xs={12} className={classes.tableBoxError}>
          <Paper className={classes.tableCardBody}>
            <TableContainer className={classes.table}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {userInfo.userlevel === 2 ?
                      <Hidden smDown>
                        <TableCell className={classes.tableHead} style={{ width: "18%" }}>企業名</TableCell>
                      </Hidden>
                      : null
                    }
                    <TableCell className={classes.tableHead} style={{ width: "18%" }}>設置場所</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "10%" }}>端末タイプ</TableCell>
                    <Hidden smDown>
                      <TableCell className={classes.tableHead} style={{ width: "18%" }}>プレイリスト</TableCell>
                    </Hidden>
                    <TableCell className={classes.tableHead} style={{ width: "8%" }}>ステータス</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "18%" }}>操作</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "12%" }}>アラート通知</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBodyError}>
                  {searchResult.errorlst ? searchResult.errorlst.map((item, key) =>
                    !item.deviceErrorLst || item.deviceErrorLst.length === 0 ?
                      null
                      :
                      <TableRow
                        className={selectedErrorRow === item.id ? classes.tableRow2 : classes.tableRow}
                        onMouseOut={() => setSelectedErrorRow('')}
                        key={10000 + key}
                      >
                        {userInfo.userlevel === 2 ?
                          <Hidden smDown>
                            <TableCell className={classes.tableCellLeft} onClick={() => { handleClickEditDialogOpen(item) }} >
                              {item.company_name}
                            </TableCell>
                          </Hidden>
                          : null
                        }
                        <TableCell className={classes.tableCellLeft} onClick={() => { handleClickEditDialogOpen(item) }} >
                          {item.group_terminal_name}
                        </TableCell>
                        <TableCell className={classes.tableCellLeft} onClick={() => { handleClickEditDialogOpen(item) }} >
                          {item.sizeY === 1 && item.sizeX === 1 ?
                            "単体"
                            :
                            item.sizeY + " × " + item.sizeX + " 連携"
                          }
                        </TableCell>
                        <Hidden smDown>
                          <TableCell className={classes.tableCellLeft} onClick={() => { handleClickEditDialogOpen(item) }} >
                            {item.termVedioCount + "個（計" + millisecondFormat(item.termDuration) + "）"}
                          </TableCell>
                        </Hidden>
                        <TableCell className={classes.tableCellLeft} onClick={() => { handleClickEditDialogOpen(item) }}>
                          <div className={classes.status}>
                            <div className={classes.status_name}>異常あり</div>
                            <div className={classes.status_blank}>&nbsp;</div>
                            <div className={classes.status_error}></div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCell} >
                          {formatDeviceStartDisabled(item) ? (
                            <Tooltip id="tooltip-top" title="起動" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                              <Button simple className={classes.actionButton}>
                                <PlayArrow color="disabled" />
                              </Button>
                            </Tooltip>) : (
                              <Tooltip id="tooltip-top" title="起動" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                                <Button simple className={classes.actionButton} onClick={() => { handleClickStartBtn(item) }} color="info" >
                                  <PlayArrow />
                                </Button>
                              </Tooltip>)
                          }
                          {formatDeviceStopDisabled(item) ? (
                            <Tooltip id="tooltip-top" title="停止" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                              <Button simple className={classes.actionButton} >
                                <Stop color="disabled" />
                              </Button>
                            </Tooltip>) : (
                              <Tooltip id="tooltip-top" title="停止" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                                <Button simple className={classes.actionButton} onClick={() => { handleClickStopBtn(item) }} color="info" >
                                  <Stop />
                                </Button>
                              </Tooltip>)
                          }
                          {formatDeviceOtherDisabled(item) ? (
                            <React.Fragment>
                              {item.suite === 1 ? (
                                <Tooltip id="tooltip-top" title="映像を揃える" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                                  <Button simple className={classes.actionButton}>
                                    <Sync color="disabled" />
                                  </Button>
                                </Tooltip>) : null}
                              <Tooltip id="tooltip-top" title="アップロード" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                                <Button simple className={classes.actionButton}>
                                  <PlayForWork color="disabled" />
                                </Button>
                              </Tooltip>
                            </React.Fragment>
                          ) : (
                              <React.Fragment>
                                {item.suite === 1 ? (
                                  <Tooltip id="tooltip-top" title="映像を揃える" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                                    <Button simple className={classes.actionButton} onClick={() => { handleClickSync(item) }} color="info">
                                      <Sync />
                                    </Button>
                                  </Tooltip>) : null}
                                <Tooltip id="tooltip-top" title="アップロード" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                                  <Button simple className={classes.actionButton} onClick={() => { handleClickDownloadBtn(item) }} color="success">
                                    <PlayForWork />
                                  </Button>
                                </Tooltip>
                              </React.Fragment>
                            )}
                        </TableCell>
                        <TableCell className={classes.tableCellRight} >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={parseInt(item.sendAlertMail, 10) === 1}
                                onClick={() => { handleClickSendAlertMailBtn(item, key, "errorlst") }}
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                                }}
                              />
                            }
                            label={parseInt(item.sendAlertMail, 10) === 1 ? ("オン") : ("オフ")}
                            className={classes.switch_label}
                          />
                        </TableCell>
                      </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        :
        <Box xs={12} className={classes.tableBoxErrorAllInfo}>
          <Paper className={classes.tableCardBody}>
            <div className={classes.messageAllInfo}>対応が必要な端末はありません。全ての端末は正常に稼働しています。</div>
          </Paper>
        </Box>
      }
      <Grid container spacing={1}>
        {/* 対応端末一覧 */}
        <div><h4 className={classes.contentTitle}>対応端末一覧</h4></div>
      </Grid>
      <Grid container spacing={1} className={classes.searchOptBox}>
        {/* 検索部 */}
        <Grid item xs={6} sm={6} className={classes.searchBox}>
        {userInfo.userlevel === 2?
          <FormControl style={{ width: "40%" }} 
            className={classes.selectFormControl}
            disabled={userInfo.userlevel===2?false:true}>
            <InputLabel htmlFor="corpSelect" className={classes.selectLabel}>
              企業
            </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              value={search.crop}
              onChange={(e) => handleChangeSearchStart(e)}
              inputProps={{ name: "corpSelect"}}>
              <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                企業を選択
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={CORP_ID_SELECTED_ALL}>
                すべて
              </MenuItem>
              {corpsInfo.lst ? corpsInfo.lst.map((item,key) =>
                <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={20000 + key}>
                  {item.corpname}
                </MenuItem> 
              ) : null}
            </Select>
          </FormControl>
        :null}
        &nbsp;&nbsp;&nbsp;
          <FormControl style={{ width: "40%" }} className={classes.selectFormControl}>
            <InputLabel htmlFor="groupSelect" className={classes.selectLabel}>
              設置場所名
            </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              value={search.group}
              onChange={handleChangeSearchStart}
              inputProps={{ name: "groupSelect"}}>
              <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                場所名を選択
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={CORP_ID_SELECTED_ALL}>
                すべて
              </MenuItem>
              {groupsInfo.lst ? groupsInfo.lst.map((item, key) =>
                <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={30000 + key}>
                  {item.group}
                </MenuItem> 
              ) : null}
            </Select>
          </FormControl>
        </Grid>
        {/* 操作ボタン部 */}
        <Grid item xs={6} sm={6} className={classes.optBox}>
          <Hidden smDown>
            <Button onClick={handleClickAddGroupNameDialogOpen} color="warning">設置場所管理</Button>
            <Button onClick={handleClickAddDialogOpen} color="warning">端末追加</Button>
          </Hidden>
          {/* 携帯の場合、ボタンの幅を縮小する */}
          <Hidden mdUp>
            <Button style={{ width: "70px" }} onClick={handleClickAddGroupNameDialogOpen} color="warning">設置管理</Button>
            <Button style={{ width: "70px" }} onClick={handleClickAddDialogOpen} color="warning">端末追加</Button>
          </Hidden>
        </Grid>
      </Grid>
      <Box xs={12} className={classes.tableBox}>
        <Paper className={classes.tableCardBody}>
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {userInfo.userlevel === 2 ?
                    <Hidden smDown>
                      <TableCell className={classes.tableHead} style={{ width: "18%" }}>企業名</TableCell>
                    </Hidden>
                    : null
                  }
                  <TableCell className={classes.tableHead} style={{ width: "18%" }}>設置場所</TableCell>
                  <TableCell className={classes.tableHead} style={{ width: "10%" }}>端末タイプ</TableCell>
                  <Hidden smDown>
                    <TableCell className={classes.tableHead} style={{ width: "18%" }}>プレイリスト</TableCell>
                  </Hidden>
                  <TableCell className={classes.tableHead} style={{ width: "8%" }}>ステータス</TableCell>
                  <TableCell className={classes.tableHead} style={{ width: "18%" }}>操作</TableCell>
                  <TableCell className={classes.tableHead} style={{ width: "12%" }}>アラート通知</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {searchResult.lst ? searchResult.lst.map((item, key) =>
                  <TableRow 
                    className={selectedRow === item.id ? classes.tableRow2 : classes.tableRow} 
                    onMouseOut={() => setSelectedRow('')} 
                    key={40000 + key}
                  >
                    {userInfo.userlevel === 2?
                      <Hidden smDown>
                        <TableCell className={classes.tableCellLeft} onClick={() => { handleClickEditDialogOpen(item) }} >
                          {item.company_name}
                        </TableCell>
                      </Hidden>
                      :null
                    }
                    <TableCell className={classes.tableCellLeft} onClick={() => { handleClickEditDialogOpen(item) }} >
                      {item.group_terminal_name}
                    </TableCell>
                    <TableCell className={classes.tableCellLeft} onClick={() => { handleClickEditDialogOpen(item) }} >
                      {item.sizeY === 1 && item.sizeX === 1 ?
                        "単体"
                        :
                        item.sizeY + " × " + item.sizeX + " 連携"
                      }
                    </TableCell>
                    <Hidden smDown>
                      <TableCell className={classes.tableCellLeft} onClick={() => { handleClickEditDialogOpen(item) }} >
                        {item.termVedioCount + "個（計" + millisecondFormat(item.termDuration) + "）"}
                      </TableCell>
                    </Hidden>
                    <TableCell className={classes.tableCellLeft} onClick={() => { handleClickEditDialogOpen(item) }}>
                      <Box>
                        {item.deviceErrorLst && item.deviceErrorLst.length > 0 ?
                          <div className={classes.status}>
                            <div className={classes.status_name}>異常あり</div>
                            <div className={classes.status_blank}>&nbsp;</div>
                            <div className={classes.status_error}></div>
                          </div>
                          :
                          <div className={classes.status}>
                            <div className={classes.status_name}>{formatDeviceStatusString(item.devices[0].deviceStatusCode)}</div>
                            <div className={classes.status_blank}>&nbsp;</div>
                            <div className={item.devices[0].deviceStatusCode === 1 ? classes.status_started
                              : item.devices[0].deviceStatusCode === 0 ? classes.status_stopped : classes.status_error}></div>
                          </div>
                        }
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                      {formatDeviceStartDisabled(item)? (
                      <Tooltip id="tooltip-top" title="起動" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                        <Button simple className={classes.actionButton}>
                          <PlayArrow color="disabled" />
                        </Button>
                      </Tooltip>) : (
                        <Tooltip id="tooltip-top" title="起動" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                          <Button simple className={classes.actionButton} onClick={() => { handleClickStartBtn(item) }} color="info" >
                            <PlayArrow/>
                          </Button>
                        </Tooltip>)
                      }
                      {formatDeviceStopDisabled(item)? (
                      <Tooltip id="tooltip-top" title="停止" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                        <Button simple className={classes.actionButton} >
                          <Stop color="disabled" />
                        </Button>
                      </Tooltip>) : (
                        <Tooltip id="tooltip-top" title="停止" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                        <Button simple className={classes.actionButton} onClick={() => { handleClickStopBtn(item) }} color="info" >
                          <Stop  />
                        </Button>
                      </Tooltip>)
                      }
                      {formatDeviceOtherDisabled(item) ? (
                        <React.Fragment>
                          {item.suite === 1 ? (
                          <Tooltip id="tooltip-top" title="映像を揃える" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                            <Button simple className={classes.actionButton}>
                              <Sync color="disabled" />
                            </Button>
                          </Tooltip>  ) : null}
                          <Tooltip id="tooltip-top" title="アップロード" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                            <Button simple className={classes.actionButton}>
                              <PlayForWork color="disabled" />
                            </Button>
                          </Tooltip>
                        </React.Fragment>
                      ):(
                        <React.Fragment>
                          {item.suite === 1 ? (
                          <Tooltip id="tooltip-top" title="映像を揃える" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                            <Button simple className={classes.actionButton} onClick={() => { handleClickSync(item) }} color="info">
                              <Sync />
                            </Button>
                          </Tooltip>  ) : null}
                          <Tooltip id="tooltip-top" title="アップロード" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                            <Button simple className={classes.actionButton} onClick={() => { handleClickDownloadBtn(item) }} color="success">
                              <PlayForWork />
                            </Button>
                          </Tooltip>
                        </React.Fragment>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCellRight} >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={parseInt(item.sendAlertMail, 10) === 1}
                            onClick={() => { handleClickSendAlertMailBtn(item, key, "lst") }}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        label={parseInt(item.sendAlertMail, 10) === 1 ? ("オン") : ("オフ")}
                        className={classes.switch_label}
                      />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {/* ページジャンプ */}
        <Grid item xs={12} className={classes.pageJumpBox}>
          {/* [記録数、ページサイズ,当ページ番号] */}
          <NBrootsPagination
            count={searchResult.total}
            pagesize={search.pageSize}
            current={search.pageIndex}
            onClick={handleChangePageStart}
            color="warning"
          />
        </Grid>
        {/* 設置場所Popup画面 */}        
        <GroupListMntPopup info={groupNameInfo} setInfo={setGroupNameInfo} />
        {/* 共通ダイアログ画面 */}
        <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
        <NBrootsDialogProcess open={isLoading} color="warning" />
        <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
      </Box>
    </Box>
  );
}
