/**
 Emailチェック
 /^[^\s@]+@[^\s@]+\.[^\s@]+$/
It allows the following formats:
1.  prettyandsimple@example.com
2.  very.common@example.com
3.  disposable.style.email.with+symbol@example.com
4.  other.email-with-dash@example.com
9.  #!$%&'*+-/=?^_`{}|~@example.org
6.  "()[]:,;@\\\"!#$%&'*+-/=?^_`{}| ~.a"@example.org
7.  " "@example.org (space between the quotes)
8.  üñîçøðé@example.com (Unicode characters in local part)
9.  üñîçøðé@üñîçøðé.com (Unicode characters in domain part)
10. Pelé@example.com (Latin)
11. δοκιμή@παράδειγμα.δοκιμή (Greek)
12. 我買@屋企.香港 (Chinese)
13. 甲斐@黒川.日本 (Japanese)
14. чебурашка@ящик-с-апельсинами.рф (Cyrillic)

@param  input   チェック対象
@return Boolean チェック結果(問題あるかどうか)
                true  : 問題あり
                false : 問題なし
*/
export default function isNotPassEmailChk(input){
    
    if (input === null || input === '') return false;
    
    return (input.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) ? false : true;
}