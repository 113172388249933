/**
 * アカウント追加（会社管理/システム管理共有）
 *  会社管理の場合、　会社名非表示
 * 　システム管理、会社名表示
 * 
 */
/* eslint-disable */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// exp components
import { VALIDATE_MSG } from 'variables/config.js';
import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";
import isNotPassEmailChk from 'utils/validations/isNotPassEmailChk';
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import { corpsInfo} from 'variables/runtimeInfos.js';
// メモリ中企業リスト
import { userInfo } from 'variables/runtimeInfos.js';
import styles from "assets/jss/material-dashboard-pro-react/views/CommonPopup/CommonPopupStyle";

export default function UserListAddPopup(props) {

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  const { info, setInfo } = props;

  const open = info[0];
  const flgCropAdmin = info[1].flag === "MngCrop" ? true : false;

  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);

  //企業変更の場合
  const handleChangeCropList = (e) => {

    let tVals = vals[0];

    //選択情報をSTATEに保存
    if (e.target.name === 'corpSelect') {
  
      //情報をSTATEに保存する。
      tVals['companyID'] = e.target.value;
      setVals([tVals]);
      // var tResult = Object.assign({}, tVals);
      // setVals(tResult);

    }
  }

  //画面編集項目＜初期状態＞
  // メールアドレス  ユーザ名 	フリカナ 	パスワード 
  var t = {
    //入力項目
    id: "",
    // corpid: userInfo.corpid,
    companyName:  userInfo.corpname,
    companyID: userInfo.corpid,
    // companyName: userInfo.corpname,
    email: "",
    name: "",
    kana: "",
    password: "",
    admin: 0,
    updateIndex: "",       //更新の場合、必ず必須
    //MSG項目
    msg_companyName: "",
    msg_email: "",       //MSG項目
    msg_name: "",
    msg_kana: "",
    msg_password: "",
    //CHECK結果
    // chk_companyName: "",
    chk_email: "",
    chk_name: "",
    chk_kana: "",
    chk_password: "",

  };
  const [vals, setVals] = React.useState([t]);

  //初期化処理
  React.useEffect(() => {
    if (open) {
      // console.log('useEffect open==================');
      // console.log(vals);
    }

    //Clean
    return () => {
      setVals([t]);
    }
  }, [open]);    //毎回OPENする時処理を行う

  //編集発生する時
  const handleEditOnChange = (name, value) => {
    let tVals = vals[0];
    //STATE内容、項目名、入力値
    validate(tVals, name, value);

    if(name ==="admin" ){
      tVals["admin"] ==  1 ? tVals["admin"] =  0 :tVals["admin"] =  1; 
    }else{
      //情報をSTATEに保存する。
      tVals[name] = value;
    }
    setVals([tVals]);
    // var tResult = Object.assign({}, tVals);
    // setVals(tResult);
  }

  function validate(tVals, name, value) {
    //チェック処理 
    if (name === 'email') {
      isNotPassNullChk(value) ?
        (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_001) :
        isNotPassEmailChk(value) ?
          (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_101) :
          isNotPassLengthChk(value, null, 100) ?
            (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100)) :
            (tVals["chk_" + name] = "success", tVals["msg_" + name] = "");

    } else if (name === 'name') {
      isNotPassNullChk(value) ?
        (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_001) :
        isNotPassCharTypeChk(value, "zenkaku") ?
          (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_003) :
          isNotPassLengthChk(value, null, 100) ?
            (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100)) :
            (tVals["chk_" + name] = "success", tVals["msg_" + name] = "");

    } else if (name === 'kana') {
      isNotPassNullChk(value) ?
        (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_001) :
        isNotPassCharTypeChk(value, 'katakana') ?
          (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_005) :
          isNotPassLengthChk(value, null, 50) ?
            (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 50)) :
            (tVals["chk_" + name] = "success", tVals["msg_" + name] = "");

    } else if (name === 'password') {
      isNotPassNullChk(value) ?
        (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_001) :
        isNotPassCharTypeChk(value, "alphanumeric") ?
          (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_006) :
          isNotPassLengthChk(value, 6, null) ?
            (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_201.replace("?", 6)) :
            isNotPassLengthChk(value, null, 15) ?
            (tVals["chk_" + name] = "error", tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 15)) :
            (tVals["chk_" + name] = "success", tVals["msg_" + name] = "");

    }
  }

  //保存ボタン押す
  const handleClickOKBtn = () => {

    // console.log('handleClickOKBtn start')

    let tVals = vals[0];
    // console.log(tVals);
    //一括チェック
    // validate(tVals, 'companyName', tVals.companyName);
    // validate(tVals, 'corpid', tVals.corpid);
    validate(tVals, 'email', tVals.email);
    validate(tVals, 'name', tVals.name);
    validate(tVals, 'kana', tVals.kana);
    validate(tVals, 'password', tVals.password);

    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if (
      tVals["chk_email"] === "error" ||
      tVals["chk_name"] === "error" ||
      tVals["chk_kana"] === "error" ||
      tVals["chk_password"] === "error") {

      setVals([tVals]);
      return;
    }
    //送信処理
    var tUserListAddInfo = [
      //当画面表示FLG    
      false,
      //当画面編集情報    

      vals[0],
      
      //IsAfterOK
      true
    ]
    //前画面に渡す、画面閉じる
    setInfo(tUserListAddInfo);
  }


  //キャンセルボタン押す
  const handleClickCancelBtn = () => {
    var tUserListAddInfo = [
      //当画面表示FLG    
      false,
      //当画面編集情報       
      { },
      //IsAfterOK
      false
    ]
    setInfo(tUserListAddInfo);
  }

  return (
    <Dialog onClose={handleClickCancelBtn} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" className={classes.dialogHeader} onClose={handleClickCancelBtn}>
        アカウント追加
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="warning"
          onClick={handleClickCancelBtn}
          style={{ float: "right" }}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>

      <DialogContent>
        <GridItem xs={12} sm={12}>
        {flgCropAdmin ?
          ''
            :
          <FormControl style={{ width: "100%" }} className={classes.selectFormControl}>
            <InputLabel htmlFor="corpSelect" className={classes.selectLabel}>
              企業
                    </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              value={vals[0]['companyID']}
              // value={vals[0].companyID}
              onChange={handleChangeCropList}
              inputProps={{ name: "corpSelect", id: "corpSelect" }}>
              <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                企業を選択
                      </MenuItem>
              {corpsInfo ? corpsInfo.lst.map((item, key) =>
                <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={key}>
                  {item.corpname}
                </MenuItem>
              ) : null}

            </Select>
          </FormControl>
          }
          <CustomInput
            labelText="メールアドレス *"
            id="email"
            success={vals[0].chk_email === "success"}             // chkResult + Json.prop
            error={vals[0].chk_email === "error"}

            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: vals[0].email,
              onChange: event => {
                handleEditOnChange("email", event.target.value);
              }
            }}
            helperText={vals[0].msg_email}
          />
          <CustomInput
            labelText="ユーザ名 *"
            id="name"
            success={vals[0].chk_name === "success"}             // chkResult + Json.prop
            error={vals[0].chk_name === "error"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: vals[0].name,
              onChange: event => {
                handleEditOnChange("name", event.target.value);
              }
            }}
            helperText={vals[0].msg_name}
          />
          <CustomInput
            labelText="フリガナ *"
            id="kana"
            success={vals[0].chk_kana === "success"}             // chkResult + Json.prop
            error={vals[0].chk_kana === "error"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: vals[0].kana,
              onChange: event => {
                handleEditOnChange("kana", event.target.value);
              }
            }}
            helperText={vals[0].msg_kana}
          />
          <CustomInput
            labelText="パスワード *"
            id="password"
            success={vals[0].chk_password === "success"}             // chkResult + Json.prop
            error={vals[0].chk_password === "error"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: vals[0].password,
              onChange: event => handleEditOnChange("password", event.target.value)
            }}
            helperText={vals[0].msg_password}
          />
          <Box className={classes.checkboxAndRadio}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={vals[0].admin === 1}
                  onChange={event => handleEditOnChange("admin", event.target.value)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label="管理者"
            />
          </Box>
        </GridItem>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickCancelBtn} color="warning">
          キャンセル
          </Button>
        <Button onClick={handleClickOKBtn} color="warning">
          保存
          </Button>
      </DialogActions>
      {/* 共通ダイアログ画面 */}
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Dialog>
  );
}
