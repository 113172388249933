import React from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputLabel from "@material-ui/core/InputLabel";
import RootRef from "@material-ui/core/RootRef";
import TableContainer from '@material-ui/core/TableContainer';

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

// core components
import Tooltip from "@material-ui/core/Tooltip";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

// exp components
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";

import Service from "utils/service/service.js"
import { BASE_URL, VALIDATE_MSG } from 'variables/config.js';
//映像一覧画面
import VedioListPopup from "views/CommonPopup/VedioListPopup.js";

import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";
import isNotPassNumberRangeChk from "utils/validations/isNotPassNumberRangeChk";
import batFormat from "utils/functions/batFormat";
import millisecondFormat from "utils/functions/millisecondFormat";
import goBack from "utils/functions/goBack";

import styles from "assets/jss/material-dashboard-pro-react/views/PlayList/playListAddStyle.js";
// メモリ中企業リスト
import { userInfo, corpsInfo } from 'variables/runtimeInfos.js';


export default function PlayListAdd(props) {
  //样式
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { opt: '', id: '', result: 0 }]);

  //画面編集項目＜初期状態＞
  var t = {
    id            : "",
    idCompany     : userInfo.userlevel === 2?'':userInfo.corpid,           //検索条件　企業ID
    idCompanyName : "",
    name          : "",       //入力項目
    feature       : "",
    lst           : [],       //動画リスト
  };
  const [vals, setVals] = React.useState(t);      //[] !!!変更後、画面render発生させるため、array使用
  //映像一覧画面
  const [vedioListInfo, setVedioListInfo] = React.useState([false, '', false]);
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {

  }, []);    //[] 忘れないよう…　初期化

  //===========================================
  //編集発生する時
  //===========================================
  const handleEditOnChange = (name, value) => {
    let tVals = vals;

    if(name === 'idCompany' && vals[name] !== ''
      && tVals.lst.length > 0) {
      setConfirmInfo([true, VALIDATE_MSG.MSG_512,
        {
          "opt"     : "change",
          "id"      : {name : name, value : value},
          "result"  : 0
        }
      ]);
    }else{
      //STATE内容、項目名、入力値
      validate(tVals, name, value);
      //情報をSTATEに保存する。
      tVals[name] = value;
      // copy　by　value
      var tResult = Object.assign({}, tVals);
      setVals(tResult);
    }
  }

  //編集発生する時の確認メッセージ後の処理
  React.useEffect(() => { 
    //起動確認結果
    if (confirmInfo[2].opt === "change" && confirmInfo[2].result === 1) {
      //STATE情報渡す
      let tVals = vals; 
      //情報をSTATEに保存する。
      tVals[confirmInfo[2].id.name] = confirmInfo[2].id.value;

      var tResult = Object.assign({}, tVals);
      tResult.lst = [];
      setVals(tResult);
    }
  }, [confirmInfo]);

  //テーブル明細部編集発生する時
  //一行の明細、項目名、入力内容
  const handleTableEditOnChange = (item,name, value) => {
    let tVals = vals;
    // //編集内容の行を取得
    var idx = vals.lst.indexOf(item);
    //STATE内容、項目名、入力値
    validate(item, name, value);
    // 情報をSTATEに保存する。
    // 明細［行番］［属性］
    tVals.lst[idx][name] = value;
    // copy　by　value
    var tResult = Object.assign({}, tVals);
    setVals(tResult);
  }  

  function validate(tVals, name, value) {
    //チェック処理
    if (name === 'idCompany') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'name') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassLengthChk(value, null, 100)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }else if(name === 'playlist'){
      if(value === 0){
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_012;
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }else if(name === 'loop_count'){
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassCharTypeChk(value + "", "numeric")) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_007;
      } else if (isNotPassNumberRangeChk(value + "", 0, null)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_203.replace("?", 0);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }
  };

  //入力チェック
  const validateAll = () => {  
    var tVals = vals;
    //一括チェック
    validate(tVals,'idCompany',tVals.idCompany);
    validate(tVals,'name',tVals.name);
    validate(tVals,'playlist',tVals.lst.length);
    
    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if(tVals["chk_idCompany"] === "error" ||
      tVals["chk_name"] === "error" ||
      tVals["chk_playlist"] === "error"){
      return true;
    }

    var i;
    for(i = 0;i < tVals.lst.length ;i++ ){
      validate(tVals.lst[i],'loop_count',tVals.lst[i].loop_count);

      //エラーがある場合、ＭＳＧを出す、処理を中断する。
      if( tVals.lst[i]["chk_loop_count"] === "error"){
        return true;
      }
    }

    return false;
  };

  const validateCompany = () => {  
    var tVals = vals;
    //一括チェック
    if (isNotPassNullChk(tVals.idCompany)) {
      tVals["chk_idCompany"] = "error";
      tVals["msg_idCompany"] = VALIDATE_MSG.MSG_513;
    }

    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if(tVals["chk_idCompany"] === "error"){
      return true;
    }

    return false;
  };
    
  //===========================================
  //追加機能
  //===========================================
  //映像追加ボタン押す
  const handleClickAddVediolistDialogOpen = () => {
    var tVals = vals;
    if(validateCompany()){
      var tResult = Object.assign({}, tVals);
      setVals(tResult);
      return;
    }

    // 映像一覧画面PARAセット
    var tVedioListInfo = [
      true,               //Popup画面表示FLG
      vals,            //編集情報すべて子画面に渡す
      false               //保存ボタン押すFLG
    ]
    setVedioListInfo(tVedioListInfo);
  };

  //映像追加画面選択後の処理
  React.useEffect(() => { 
    //起動確認結果
    if (vedioListInfo[2] === true) {
      //STATEに情報をセット
      setVals(vedioListInfo[1]);
    }
  }, [vedioListInfo]);
  

  //===========================================
  //削除機能
  //===========================================
  //明細行の削除ボタン
  const handleClickPlayListStartDel = (item) => {
    //操作行の取得
    var idx = vals.lst.indexOf(item);
    //Copy　STATE情報　By　Value
    var tResult = JSON.parse(JSON.stringify(vals));
    //情報の一時削除
    tResult.lst.splice(idx,1);
    //画面呼び出す
    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        opt        : "del",
        id         : tResult,     //STATE情報渡す
        result     : 0
      }
    ]);
  };

  //明細行削除の確認メッセージ後の処理
  React.useEffect(() => { 
    //起動確認結果
    if (confirmInfo[2].opt === "del" && confirmInfo[2].result === 1) {
      //STATE情報渡す
      setVals(confirmInfo[2].id);
    }
  }, [confirmInfo]);




  //保存ボタン押す
  const handleClickSaveBtn = () => {
    var tVals = vals;
    if(validateAll()){
      var tResult = Object.assign({}, tVals);
      // console.log(tResult);
      setVals(tResult);
      return;
    }
    
    //進捗画面
    setIsLoading(true);
    // console.log(tVals);

    var url = BASE_URL + "/API/PlayList/Create";
    var data = tVals;

    Service(props, url, data).then(resp => {
      setIsLoading(false);

      // 正常系
      if (resp.status === 0) {

        setMsgInfo([true, resp.msg, 'success']);
        props.history.push('/PlayList');
      } else {
        //その他APIエラー      
        setMsgInfo([true, resp.msg, 'warning']);
      }
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  }

  /** 拖拽 ---start---*/
  const reorder = (lstContent, startIndex, endIndex) => {
    const result = Array.from(lstContent);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,
  
    ...(isDragging && {
      background: "#dadada",
      display: 'table'
    })
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let tVals = vals;

    tVals.lst = reorder(
      vals.lst,
      result.source.index,
      result.destination.index
    );

    var tResult = Object.assign({}, tVals);
    setVals(tResult);
  };
  /** 拖拽 ---end---*/


  return (
    <Box>
      <Grid container spacing={0} >
        <GridItem xs={12} sm={9}>
          <Card className={classes.card}>
            <CardHeader>
              <CardText color="primary">
                <h4 className={classes.cardTitle}>プレイリスト・表示情報</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Grid container spacing={1}>
                {userInfo.userlevel === 2 &&
                  <Grid item xs={6} sm={4}>
                    <FormControl style={{ width: "80%" }}
                      className={classes.selectFormControl}
                      disabled={userInfo.userlevel===2?false:true}>
                      <InputLabel htmlFor="corpSelect" className={vals.chk_idCompany === "error" ? classes.selectLabelErr : classes.selectLabel}>
                        企業
                      </InputLabel>
                      <Select
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={vals.idCompany}
                        onChange={ event => handleEditOnChange("idCompany", event.target.value)}
                        inputProps={{ name: "corpSelect", id: "corpSelect" }}>
                        <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                          企業を選択
                        </MenuItem>
                        {corpsInfo ? corpsInfo.lst.map((item,key) =>
                          <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={key}>
                            {item.corpname}
                          </MenuItem> 
                        ) : null}
                      </Select>
                      <Box className={classes.selectHelpTextErr}>{vals.msg_idCompany}</Box>
                    </FormControl>
                  </Grid>
                }
                <Grid item xs={6} sm={6} className={classes.searchBox}>
                  <CustomInput
                    style={{ width: "90%" }}
                    success={vals.chk_name === "success"}             // chkResult + Json.prop
                    error={vals.chk_name === "error"}
                    labelText="プレイリスト名 *"
                    id="name"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      value: vals.name,
                      onChange: event => {
                        handleEditOnChange("name", event.target.value);
                      }
                    }}
                    helperText={vals.msg_name}
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader>
              <CardText color="primary">
                <h4 className={classes.cardTitle}>プレイリスト設定</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Grid container spacing={0}>
                {/* 検索部 */}
                <Grid item xs={6} sm={6} className={classes.searchBox}>
                </Grid>
                {/* 操作ボタン部 */}
                <Grid item xs={6} sm={6} className={classes.optBox}>
                  <Button color="warning" onClick={handleClickAddVediolistDialogOpen}>
                    <Add className={classes.icons} />映像追加
                    </Button>
                </Grid>
              </Grid>
              <TableContainer style={{ minHeight: 375, maxHeight: 375 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead} style={{ width: "10%" }}></TableCell>
                      <TableCell className={classes.tableHead} style={{ width: "40%" }}>映像名称</TableCell>
                      <TableCell className={classes.tableHead} style={{ width: "10%" }}>サムネイル</TableCell>
                      <TableCell className={classes.tableHead} style={{ width: "10%" }}>サイズ</TableCell>
                      <TableCell className={classes.tableHead} style={{ width: "10%" }}>再生時間</TableCell>
                      {/* <TableCell className={classes.tableHead} style={{ width: "10%" }}>再生回数</TableCell> */}
                      <TableCell className={classes.tableHead} style={{ width: "10%" }}>操作</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* 拖拽部 */}
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <RootRef rootRef={provided.innerRef}>
                          <TableBody className={classes.tableBody}>
                            {vals.lst ? vals.lst.map((item, key) =>
                              <Draggable key={key} draggableId={key + ''} index={key}>
                              {(provided, snapshot) => (
                                <TableRow className={classes.tableRow} key={key}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}>
                                  <TableCell className={classes.tableCell} >
                                    <Button simple className={classes.actionButton} style={{cursor:'auto'}} color="transparent">
                                      <Box {...provided.dragHandleProps}><DragIndicatorIcon/></Box>
                                    </Button>
                                  </TableCell>
                                  <TableCell className={classes.tableCellLeft} >{item.name}</TableCell>
                                  <TableCell className={classes.tableCell} ><img className={classes.imagesrc} height="15%" src={item.imagesrc} alt="..." /></TableCell>
                                  <TableCell className={classes.tableCell} >{batFormat(item.size)}</TableCell>
                                  <TableCell className={classes.tableCell} >{millisecondFormat(item.duration)}</TableCell>
                                  {/* <TableCell className={classes.tableCell} >
                                    <CustomInput
                                      id={"loop_count" + key}
                                      success={item.chk_loop_count === "success"}  // chkResult + Json.prop
                                      error={item.chk_loop_count === "error"}
                                      formControlProps={{ 
                                        width       : "40px" ,
                                        className   : classes.inputLoopNum,
                                      }}                            
                                      inputProps={{
                                        type        : "text",
                                        value       : item.loop_count,
                                        onChange    : event => {
                                          handleTableEditOnChange( item, "loop_count", event.target.value);
                                        }
                                      }}
                                      inputAlignRight={true}
                                      helperText={item.msg_loop_count}  
                                    />
                                  </TableCell> */}
                                  <TableCell className={classes.tableCell} >
                                    <Tooltip id="tooltip-top" title="削除" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                                      <Button simple className={classes.actionButton} onClick={() => { handleClickPlayListStartDel(item) }} color="danger">
                                        <Delete />
                                      </Button>
                                    </Tooltip>
                                  </TableCell>

                                </TableRow>
                              )}
                              </Draggable>
                            ) : null}
                          </TableBody>
                        </RootRef>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
          <Box style={{ textAlign: "left" }} className={classes.selectHelpTextErr}>{vals.msg_playlist}</Box>

          <Box style={{ textAlign: "right" }}>
            <Button color="warning" onClick={() => { goBack(props) }}>
              &nbsp;&nbsp;戻&nbsp;る&nbsp;&nbsp;
            </Button>
            <Button color="warning" onClick={handleClickSaveBtn}>
              &nbsp;&nbsp;保&nbsp;存&nbsp;&nbsp;
            </Button>
          </Box>
        </GridItem>
      </Grid>
      {/* 映像一覧画面 */}
      <VedioListPopup info={vedioListInfo} setInfo={setVedioListInfo} />
      {/* 共通ダイアログ画面 */}
      <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Box>
  );
}
