const TokenDefine =  {
  header : 'Authorization',
  prefix : 'Bearer ',
};

const JWTTokenUtils = token => {

  let removePrefix = token => {
    let tokenValue = String(token);

    if (tokenValue.startsWith(TokenDefine.prefix)) {
      tokenValue = tokenValue.substr(TokenDefine.prefix.length);
    }

    return tokenValue;
  };

  let parseToken = token => {
    let tokenValue = removePrefix(token);

    let base64Url = tokenValue.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  let payload = Object.assign({});

  if(token){
    payload = Object.assign({}, parseToken(token));
   }
   
   return payload;
}

export {
  TokenDefine,
  JWTTokenUtils
};
