import React from "react";
// @material-ui/core components
// import ReactImageAppear from 'react-image-appear';

import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// @material-ui/icons
import AccessTime from "@material-ui/icons/AccessTime";
import Delete from "@material-ui/icons/Delete";
import Save from "@material-ui/icons/Save";
import Scale from "@material-ui/icons/LinearScale";
import Edit from "@material-ui/icons/Edit";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// exp components
import NBrootsPagination from "components/@NBrootsPagination/NBrootsPagination.js";
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsDialogPlayer from "components/@NBrootsDialogPlayer/NBrootsDialogPlayer.js";
import NBrootsImg from "components/@NBrootsImg/NBrootsImg.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";


import VideoUploader from "./VideoUploader";

import Service from "utils/service/service.js"
import { BASE_URL, VALIDATE_MSG } from 'variables/config.js';
import cEmptyToNull from "utils/functions/cEmptyToNull"
import batFormat from "utils/functions/batFormat"
import millisecondFormat from "utils/functions/millisecondFormat";

import styles from "assets/jss/material-dashboard-pro-react/views/VideoList/videosListStyle.js";

import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";

// メモリ中企業リスト
import { userInfo, corpsInfo } from 'variables/runtimeInfos.js';
import { PAGINATION_PREV_NAME, PAGINATION_NEXT_NAME, CORP_ID_SELECTED_ALL } from 'variables/config.js';

export default function VideosList(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //player画面用情報          playerInfo  : [表示FLG、VideoSrc]    
  const [playerInfo, setPlayerInfo] = React.useState([false, '']);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);
  //検索条件（初期状態）
  var tSearch = {
    crop: userInfo.userlevel === 2 ? CORP_ID_SELECTED_ALL : userInfo.corpid,           //検索条件　企業ID
    orderby: 'update_time',          //orderby
    sorttype: 'desc',                 //sorttype
    pageIndex: 1,                      //当ページ番号
    pageSize: 8                       //ページサイズ
  }
  const [search, setSearch] = React.useState(tSearch);
  //検索結果（初期状態）
  var tSearchResult = {
    total: 0,            //記録数
    lst: []            //明細リスト
  }
  const [searchResult, setSearchResult] = React.useState(tSearchResult);

  //アップロード画面用情報
  var tVideoUploaderInfo = [
    false,          //Popup画面表示FLG
    {},             //情報<当画面未使用>
    false           //保存ボタン押すFLG<当画面未使用>
  ]
  //場所追加画面関連情報
  const [videoUploaderInfo, setVideoUploaderInfo] = React.useState(tVideoUploaderInfo);

  //初期化処理
  React.useEffect(() => {
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search, props);

  }, [search, props]);    //[] 忘れないよう…　初期化

  //===========================================
  //検索/改ページなど
  //===========================================
  const formatLstData = (tSearchCondition, tProps) => {

    var url = BASE_URL + "/API/VideoList/Search";
    //　検索条件  略
    var data = {
      companyid: cEmptyToNull(tSearchCondition.crop === CORP_ID_SELECTED_ALL ? '' : tSearchCondition.crop),
      orderby: tSearchCondition.orderby,
      sorttype: tSearchCondition.sorttype,
      pageIndex: tSearchCondition.pageIndex,
      pageSize: tSearchCondition.pageSize
    };

    Service(tProps, url, data).then(resp => {
      // 正常系
      // console.log("検索結果-----------");
      // console.log(resp);
      var tResult;
      // 正常系
      if (resp.status === 0) {
        var info = resp.infos;
        //Data情報の処理
        var lst = info.list;
        //FLAGなどの整理
        lst = lst.map(item => {
          return {
            ...item,
            //追加属性
            isEdit: false,
            chk_name: '',
            msg_name: '',
          }
        });
        //検索結果（セット）
        tResult = {
          total: info.total,
          lst: [...lst]
        }
        // console.log(tResult);
        setSearchResult(tResult);
      } else {
        //その他APIエラー  
        //検索結果（初期状態） 
        tResult = {
          total: 0,
          lst: []
        }
        setSearchResult(tResult);
        setMsgInfo([true, resp.msg, 'warning']);
      }
      setIsLoading(false);
    }).catch(resp => {
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  }

  //検索条件部
  //企業変更の場合
  const handleChangeSearchStart = (e) => {
    let t = search;
    //選択情報をSTATEに保存
    if (e.target.name === 'corpSelect') {
      t.crop = e.target.value;
    }
    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search, props);
  }

  // 改ページ処理
  const handleChangePageStart = (page) => {
    let t = search;
    if (page.disabled) return;
    if (page.text === search.pageIndex) return;

    if (page.text === PAGINATION_PREV_NAME) {
      t.pageIndex = t.pageIndex - 1;
    } else if (page.text === PAGINATION_NEXT_NAME) {
      t.pageIndex = t.pageIndex + 1;
    } else {
      t.pageIndex = page.text;
    }
    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search, props);
  };

  //===========================================
  //名称変更機能
  //===========================================
  //画面動作 編集ICON押す
  const handleClickStartEdit = (obj) => {
    //TODO
    //チェック処理必須？他の項目編集MODE？

    //編集IDX取得
    var idx = searchResult.lst.indexOf(obj);
    //編集MODEFLGセット
    if (!obj.isEdit) {
      obj.isEdit = true;
    }
    //情報セット
    searchResult.lst[idx] = obj;
    //Copy　STATE情報　By　Value
    var tResult = JSON.parse(JSON.stringify(searchResult));
    //STATEに保存＜画面側、ICON変更、編集MODEに入る＞
    setSearchResult(tResult);
  };

  //画面動作 Edit On Change
  const handleChangeStartEdit = (obj, propname, inVal) => {
    //編集IDX取得
    var idx = searchResult.lst.indexOf(obj);
    //チェック
    validate(obj, propname, inVal);
    //情報セット
    searchResult.lst[idx][propname] = inVal;
    //Copy　STATE情報　By　Value
    var tResult = JSON.parse(JSON.stringify(searchResult));
    //STATEに保存
    setSearchResult(tResult);
  };

  function validate(item, name, value) {
    //チェック処理
    if (name === 'name') {
      if (isNotPassNullChk(value)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_001;
      // } else if (isNotPassCharTypeChk(value, "zenkaku")) {
      //   item["chk_" + name] = "error";
      //   item["msg_" + name] = VALIDATE_MSG.MSG_003;
      } else if (isNotPassLengthChk(value, null, 100)) {
        item["chk_" + name] = "error";
        item["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        item["chk_" + name] = "success";
        item["msg_" + name] = "";
      }
    }
  }

  //画面動作 保存ICON押す
  const handleClickStartSave = (obj) => {

    validate(obj, 'name', obj.name);

    if (obj["chk_name"] === "error") {
      var tResult = JSON.parse(JSON.stringify(searchResult));
      //STATEに保存
      setSearchResult(tResult);
      return;
    }

    //編集IDX取得
    setIsLoading(true);
    var url = BASE_URL + "/API/VideoList/Update";
    //　入力データ
    var data = {
      // id          : cEmptyToNull(obj.id),
      // idCompany   : obj.companyID,  
      id: obj.id,
      name: obj.name,
      updateIndex: obj.updateIndex
    };
    // console.log(data);
    //更新処理
    Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        setMsgInfo([true, resp.msg, 'success']);
        //情報のロード
        formatLstData(search);
      } else {
        setMsgInfo([true, resp.msg, 'danger']);
      }
      //進捗画面を閉じる
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };

  //===========================================
  //PLAY機能
  //===========================================
  const handleClickStartPlay = (obj) => {
    //Player画面を開く
    setPlayerInfo([true, obj.src]);
  };

  //===========================================
  //削除機能
  //=========================================== 
  //画面動作 DEL
  const handleClickStartDel = (item) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        // "opt"     : "del",
        // "id"      : obj,
        // "result"  : 0
        opt: "del",
        id: item.id,
        result: 0
      }
    ]);
  };

  //確認メッセージ後の処理
  React.useEffect(() => {
    //削除処理
    function doDel(obj, tProps) {
      //編集IDX取得
      setIsLoading(true);
      var url = BASE_URL + "/API/VideoList/Delete";
      //　入力データ
      var data = {
        id: cEmptyToNull(obj.id),
      };
      // console.log(data);
      //更新処理
      Service(tProps, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {
          setMsgInfo([true, resp.msg, 'success']);
          //情報のロード
          formatLstData(search);
        } else {
          setMsgInfo([true, resp.msg, 'danger']);
        }
        //進捗画面を閉じる
        setIsLoading(false);
      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });
    };

    //起動確認結果
    if (confirmInfo[2].opt === "del" && confirmInfo[2].result === 1) {
      // console.log(confirmInfo[2]);
      doDel(confirmInfo[2], props);
    }

  }, [confirmInfo, search, props]);


  //===========================================
  //追加機能
  //===========================================
  const handleClickStartAdd = () => {
    var tVideoUploaderInfo = [
      true,          //Popup画面表示FLG
      {               //情報
      },
      false           //保存ボタン押すFLG
    ]
    setVideoUploaderInfo(tVideoUploaderInfo);
  };

  //Popup画面後の処理
  React.useEffect(() => {

    //追加処理
    function doAfterAdd() {

      //情報のロード
      formatLstData(search);
      setMsgInfo([true, 'アップロード成功しました。', 'success']);

    }

    //保存ボタン押す後
    if (videoUploaderInfo[2] === true)
      doAfterAdd();

  }, [videoUploaderInfo, search]);



  return (
    <Box>
      <Grid container spacing={1}>
        {/* 検索部 */}
        <Grid item xs={6} sm={6} className={classes.searchBox}>
          {userInfo.userlevel === 2 &&
            <FormControl style={{ width: "40%" }}
              className={classes.selectFormControl}
              disabled={userInfo.userlevel === 2 ? false : true}>
              <InputLabel htmlFor="corpSelect" className={classes.selectLabel}>
                企業
              </InputLabel>
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                value={search.crop}
                onChange={(e) => handleChangeSearchStart(e)}
                inputProps={{ name: "corpSelect" }}>
                <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                  企業を選択
                </MenuItem>
                <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={CORP_ID_SELECTED_ALL}>
                  すべて
                </MenuItem>
                {corpsInfo ? corpsInfo.lst.map((item, key) =>
                  <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={key}>
                    {item.corpname}
                  </MenuItem>
                ) : null}

              </Select>
            </FormControl>
          }
        </Grid>
        {/* 操作ボタン部 */}
        <Grid item xs={6} sm={6} className={classes.optBox}>
          <Button color="warning" onClick={() => handleClickStartAdd()}>コンテンツ追加</Button>
        </Grid>
        {/* ページジャンプ */}
        <Grid item xs={12} className={classes.pageJumpBox}>
          <NBrootsPagination
            count={searchResult.total}
            pagesize={search.pageSize}
            current={search.pageIndex}
            onClick={handleChangePageStart}
            color="warning"
          />
        </Grid>
      </Grid>

      {/* ScrollBar表示部分 */}
      <Box xs={12} className={classes.gidBox}>
        <GridContainer>
          {searchResult.lst ? searchResult.lst.map((item, idx) =>
            <GridItem xs={12} sm={6} lg={3} key={idx}>
              <Card product className={classes.cardHover}>
                {/* 画像部 */}
                <CardHeader image className={classes.cardHeaderHover}>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <NBrootsImg src={item.imagesrc} />
                  </a>
                  {/* <ReactImageAppear src={item.imagesrc} placeholder/> */}
                </CardHeader>
                {/* 操作部 */}
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardHoverUnder}>
                    <Tooltip id="tooltip-top" title="プレイ" placement="bottom"
                      classes={{ tooltip: classes.tooltip }}>
                      <Button color="transparent" simple justIcon>
                        <PlayCircleOutline className={classes.underChartIcons}
                          onClick={() => handleClickStartPlay(item)} />
                      </Button>
                    </Tooltip>
                    {item.isEdit ?
                      // <Tooltip id="tooltip-top" title="保存" placement="bottom"
                      //   classes={{ tooltip: classes.tooltip }}>
                      //   <Button color="success" simple justIcon>
                      //     <Save className={classes.underChartIcons}
                      //       onClick={() => handleClickStartSave(item)} />
                      //   </Button>
                      // </Tooltip>
                      null
                      :
                      <Tooltip id="tooltip-top" title="編集" placement="bottom"
                        classes={{ tooltip: classes.tooltip }}>
                        <Button color="success" simple justIcon>
                          <Edit className={classes.underChartIcons}
                            onClick={() => handleClickStartEdit(item)} />
                        </Button>
                      </Tooltip>
                    }
                    <Tooltip id="tooltip-top" title="削除" placement="bottom"
                      classes={{ tooltip: classes.tooltip }}>
                      <Button color="danger" simple justIcon>
                        <Delete className={classes.underChartIcons}
                          onClick={() => handleClickStartDel(item)} />
                      </Button>
                    </Tooltip>
                  </div>
                  {item.isEdit ?
                    <React.Fragment>
                      <CustomInput id="name"
                        success={item.chk_name === "success"}      // chkResult + Json.prop
                        error={item.chk_name === "error"}
                        formControlProps={{
                          className: classes.inputName,
                        }}
                        value={item.name}                               //set
                        inputProps={{
                          value: item.name,                      //set
                          onChange: event => handleChangeStartEdit(item, "name", event.target.value),
                        }}
                        helperText={item.msg_name}
                      />
                      <Tooltip id="tooltip-top" title="保存" placement="bottom"
                        classes={{ tooltip: classes.tooltip }}>
                          <Save className={classes.successText} onClick={() => handleClickStartSave(item)} />
                      </Tooltip>
                    </React.Fragment>
                    :
                    <h4 className={classes.cardProductTitle}>
                      {item.name}
                    </h4>
                  }
                </CardBody>
                {/* foot部 */}
                <CardFooter product>
                  <div className={classes.price}>
                    <Scale /><p>{batFormat(item.size)}</p>
                  </div>
                  <div className={classes.price}>
                    <p>{millisecondFormat(item.duration)}</p><AccessTime />
                  </div>


                </CardFooter>
              </Card>
            </GridItem>
          ) : null}
        </GridContainer>
      </Box>
      {/* アップロードPopup画面 */}
      <VideoUploader info={videoUploaderInfo} setInfo={setVideoUploaderInfo} />
      {/* 共通ダイアログ画面 */}
      <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
      <NBrootsDialogPlayer open={playerInfo[0]} src={playerInfo[1]} onClose={() => setPlayerInfo([false, ''])} />
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Box>
  );
}
