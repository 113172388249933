import {
  // successColor,
  tooltip,
  cardTitle,
  grayColor,
  warningColor,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.js';
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const userListStyle = {
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "5px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0]
  },
  searchBox: {
    textAlign: 'left',
  },
  optBox: {
    textAlign: 'right',
  },
  pageJumpBox: {
    textAlign: 'center',
    height: '40px',
  },
  tableBox: {
    height    : 'calc(100% - 210px)',
    width     : 'calc(100% - 50px)',
    position  : 'absolute',
    paddingTop: '10px',
  },
  tableCardBody: {
    padding: '10px',
    height: '100%',
    width: '100%',
    borderRadius: '6px',
    //display       : 'block',
    position: 'relative',
    // position      : 'absolute'
    // marginTop: '10px',

    // width:'100%',
    // marginTop: '10px',
    // overflowY:'hidden',
    // overflowX: 'hidden',
  },
  table: {
    height: '100%',
    width: '100%',
    marginTop: '0px',
    overflowX: 'auto',
    overflowY: 'auto'
  },
  /*テーブルヘッダ部ICONのSTYLE*/
  tableHeadIcon: {
    margin: 0,
    '&:hover': {
      backgroundColor: '#EFEFEF',
    }

  },
  tableHeadSwapIcon: {
    color: '#c3c3c3',
    height: '17px',
    width: '17px',
    '&:hover': {
      backgroundColor: '#EFEFEF',
    }
  },
  /*テーブルヘッダ部のSTYLE*/
  tableHead: {
    // fontSize          : '14px',
    // backgroundColor   : '#f8f8f8',
    padding: '10px 10px 10px 10px',
    minheight: '50px',
    minwidth: '80px',
    height: '50px',
    // color             : '#383838',
    // fontWeight        : 'bold',
    lineHeight: '1rem',
    verticalAlign: 'middle',
    textAlign: 'center',
    'z-index': '10',
    'white-space': 'nowrap',
  },
  tableSortHeadWrapper: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  /*テーブルBODY部のSTYLE*/
  tableCell: {
    padding: '10px 5px 5px 10px',
    textAlign: 'center'
  },
  /*テーブルBODY部のSTYLE*/
  tableCellLeft: {
    padding: '10px 5px 5px 10px',
    textAlign: 'left'
  },
  /*テーブルBODY部のSTYLE*/
  tableCellRight: {
    padding: '10px 5px 5px 10px',
    textAlign: 'right'
  },
  /*テーブルBODY部のSTYLE*/
  tableBody: {
    maxheight: '500px',
    overflow: 'visible'
  },
  /*テーブル行のSTYLE*/
  tableRow: {
    marginLeft: '3px',
    backgroundColor: '#FFFFFF',
    height: '40px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFFDF2',
    }
  },
  tableRow2:{
    marginLeft       : '3px',
    backgroundColor  : '#FFFDF2',
    height           : '40px',
    cursor           : 'pointer',
  },
  //操作ボタン
  actionButton: {
    //margin: '10px 0px 0px 10px',
    margin: '5px',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px'
    }
  },
  dialogHeader: {
    backgroundColor: warningColor[0] + "!important",
    color: whiteColor + " !important",
  }
};

export default userListStyle;
