import {
  warningColor,
  whiteColor,
  grayColor,
  blackColor,
  tooltip,
  cardTitle
} from 'assets/jss/material-dashboard-pro-react.js';

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.js';

const terminalsListStyle = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  ...hoverCardStyle,
  tooltip:{
    ...tooltip,
    marginTop: '0px',
    marginBottom: '3px'
  },
  cardTitle: {
    ...cardTitle,
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    color:whiteColor,
    textDecoration: "none"
  },
  card: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  cardBody: {
    padding: '0.4375rem 20px',
  },
  detailCardHeader: {
    backgroundColor: grayColor[11],
    paddingTop     : '0',
    paddingBottom  : '0',
    "& > div > div > div > h4": {
      fontWeight   : '800',
      paddingRight : '15px',
    },
  },
  addTitle: {
    textAlign:'left',
  },
  searchBox:{
    textAlign:'left',
  },
  optBox:{
    "& > button": {
      right   : '10px',
    },
    textAlign :'right',
  },
  areaTitle: {
    "& > h4": {
      fontWeight: '800',
    },
  },
  inputLabel: {
    fontSize: "14px",
    paddingLeft: '64px',
    paddingTop: '20px',
  },
  selectControl: {
    "& > div > div": {
      paddingTop: '6px',
      paddingLeft: '25px',
    }
  },
  customInput: {
    "& > div": {
      paddingTop: '11px',
    },
    "& > div > div > input": {
      marginLeft: '25px',
    }
  },
  runTimeBox: {
    paddingTop: '0px',
  },
  runTimeSelectControlInit: {
    paddingRight: '25px',
    marginTop: '11px',
    "& > div > div": {
      fontSize: '14px',
      paddingTop: '6px',
      paddingLeft: '25px',
    }
  },
  runTimeSelectControl: {
    paddingRight: '25px',
    marginTop: '0px',
    "& > div > div": {
      fontSize: '14px',
      paddingTop: '4px',
      paddingLeft: '25px',
    }
  },
  runTimePickerBoxInit: {
    width: "20%",
    float: "left",
  },
  runTimePickerBox: {
    width: "20%",
    float: "left",
    "& > div": {
      marginTop: '0px',
    }
  },
  runTimeLabelInit: {
    margin: '20px 0 0',
    textAlign:'center',
    width: '25px',
  },
  runTimeLabel: {
    margin: '7px 0 0',
    textAlign:'center',
    width: '25px',
  },
  addedRunTimeLabel: {
    color: grayColor[14],
    fontSize: '14px',
    marginTop: '7px',
    paddingLeft: '25px',
  },
  runTimeInput: {
    "& > div:first-child": {
      paddingTop: '13px',
    },
    // "& > div > div > label": {
    //   marginTop: '7px',
    //   marginLeft: '25px',
    // },
    "& > div > div > div > div > div > input": {
      margin: '0',
      textAlign:'center',
    }
  },
  runTimeInputInit: {
    "& > div > div > div > div > div > input": {
      margin: '0',
      textAlign:'center',
    }
  },
  addButtonInit: {
    textAlign: "right",
    paddingRight: "32px",
    marginTop: "13px",
    "& > button": {
      padding: '0',
      height: '18px',
      width: '18px',
      minWidth: '18px',
    },
    "& > button > span": {
      color: '#000',
      height: '18px',
      width: '18px',
    }
  },
  addButton: {
    textAlign: "right",
    paddingRight: "32px",
    "& > button": {
      padding: '0',
      height: '18px',
      width: '18px',
      minWidth: '18px',
    },
    "& > button > span": {
      color: '#000',
      height: '18px',
      width: '18px',
    }
  },
  removeButton: {
    textAlign: "right",
    paddingRight: "32px",
    "& > button": {
      padding: '0',
      height: '18px',
      width: '18px',
      minWidth: '18px',
    },
    "& > button > span": {
      color: '#000',
      height: '18px',
      width: '18px',
    }
  },
  sizeLabel: {
    color: grayColor[14],
    fontSize: '14px',
  },
  sizeYInput: {
    "& > label": {
      paddingTop: '24px',
      paddingLeft: '29px',
      paddingBottom: '20px'
    },
    "& > div > div": {
      paddingTop: '15px',
    },
    "& > div > div > div > input": {
      margin: '0',
      textAlign: 'center',
    }
  },
  sizeXInput: {
    "& > label": {
      paddingTop: '14px',
      paddingLeft: '29px',
    },
    "& > div > div": {
      paddingTop: '5px',
    },
    "& > div > div > div > input": {
      margin: '0',
      textAlign:'center',
    }
  },
  pageJumpBox:{
    textAlign:'center',
    height:'40px',
  },
  tableBox:{
    paddingTop: '10px',
    paddingLeft: '50px',
  },
  tableCardBody:{
    padding       : '10px',
    height        : '100%',
    width         : '100%',
    borderRadius  : '6px',
    //display       : 'block',
    position      : 'relative',
    // position      : 'absolute'
    // marginTop: '10px',

    // width:'100%',
    // marginTop: '10px',
    // overflowY:'hidden',
    // overflowX: 'hidden',
  },
  table:{
    height    : '100%',
    width     : '100%',
    marginTop : '0px',
    display   : 'block',
    // position  : 'fixed',
    overflowX : 'auto',
    overflowY : 'auto'
  },
  /*テーブルヘッダ部ICONのSTYLE*/
  tableHeadIcon: {
    margin          : 0,
    '&:hover': {
        backgroundColor: '#EFEFEF',
    }

  },
  tableHeadSwapIcon: {
    color        : '#c3c3c3',
    height       : '17px',
    width        : '17px',
    '&:hover': {
          backgroundColor: '#EFEFEF',
      }
  },
  /*テーブルヘッダ部のSTYLE*/
  tableHead: {
    // fontSize          : '14px',
    // backgroundColor   : '#f8f8f8',
    padding           : '10px 10px 10px 10px',
    minheight         : '50px',
    minwidth          : '80px',
    height            : '50px',
    // color             : '#383838',
    // fontWeight        : 'bold',
    lineHeight        : '1rem',
    verticalAlign     : 'middle',
    textAlign         : 'center',
    'z-index'         : '10',
    'white-space'     : 'nowrap',
  },
  tableSortHeadWrapper: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  /*テーブルBODY部のSTYLE*/
  tableCell:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'center',
    "& > div": {
      padding: '0',
      margin: '0',
    },
    "& > div > div > input": {
      marginLeft: '25px',
    }
  },
  /*テーブルBODY部のSTYLE*/
  tableCellLeft:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'left',
    "& > div": {
      padding: '0',
      margin: '0',
    },
    "& > div > div > input": {
      marginLeft: '25px',
    }
  },
  deviceMatrixTableBox:{
    padding     : '30px 0 30px 0',
    alignItems  : 'center',
    display     : 'flex',
    justifyContent : 'center',
  },
  deviceMatrixTableCardBody:{
    padding       : '20px',
    borderRadius  : '6px',
    boxShadow     : 'none',
    position      : 'relative',
    backgroundColor: grayColor[11],
  },
  deviceMatrixTableCell:{
    border      : '0',
    padding     : '15px 15px 5px 15px',
    textAlign   : 'center',
    "& > div": {
      padding: '0',
      margin: '0',
    },
    "& > ul > li > button": {
      backgroundColor: grayColor[4],
      width       : '55px',
      height      : '55px',
      fontSize    : "20px",
      fontWeight  : "400",
      textTransform: "uppercase",
      color: blackColor + " !important",
      boxShadow   : 'none',
    },
    "& > ul > li > button:hover,& > ul > li > button:focus": {
      backgroundColor: grayColor[4],
      boxShadow: 'none',
    },
  },
  /*テーブルBODY部のSTYLE*/
  tableCellRight:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'right'
  },
  /*テーブルBODY部のSTYLE*/
  tableBody:{
    maxheight        : '500px',
    overflow         : 'visible'
  },
  /*テーブル行のSTYLE*/
  tableRow:{
    marginLeft       : '3px',
    backgroundColor  : '#FFFFFF',
    height           : '40px',
    '&:hover': {
        backgroundColor: '#FFFDF2',
      }
  },
  tableRow2:{
    marginLeft       : '3px',
    backgroundColor  : '#FFFDF2',
    height           : '40px',
    cursor           : 'pointer',
  },
  //操作ボタン
  actionButton: {
    //margin: '10px 0px 0px 10px',
    margin: '5px',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px'
    }
  },
  inputName: {
    margin: '0 0 5px 0',
    paddingTop: '10px'
  },  
  dialogHeader: {
    backgroundColor: warningColor[0] + '!important',
    color: whiteColor + ' !important',
  }
};

export default terminalsListStyle;
