import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import styles from "assets/jss/material-dashboard-pro-react/components/snapShotStyle.js";

const useStyles = makeStyles(styles);

const PrettoSlider = withStyles({
    root: {
      color: '#ff9800',
      height: 8,
      padding: 0,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);

export default function NBrootSnapShot(props) {
    const classes = useStyles();

    const { 
      labelText,
      id,
      maxDuration,
      value,
      onChange,
      onReload,
    } = props;

    const handleChange = (event, val) => {
        onChange("snapShotAt", val);
    };
  
    return (
      <Box >
        <Typography gutterBottom  className={classes.labelRoot}>{labelText}</Typography>
        <PrettoSlider 
          fullwidth="true"
          valueLabelDisplay="auto" 
          aria-label="pretto slider" 
          defaultValue={0}
          max={maxDuration}
          id={id}
          value={value}
          onChange={ handleChange }
          onMouseUp={ onReload }
        />
      </Box>
    );
  }

  NBrootSnapShot.propTypes = {
    labelText: PropTypes.node,
    maxDuration: PropTypes.number,
    id: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.any.isRequired,
  };