

/**
 Phoneチェック
    1234567890
    12345678901
    123-456-7890
    12-3456-7890
    123-4567-8901
 
@param  input   チェック対象
@return Boolean チェック結果(問題あるかどうか)
                true  : 問題あり
                false : 問題なし
*/
export default function isNotPassPhoneChk(input){
    
    if (input === null || input === '') return false;

    return (input.match(/^(?:\d{10}|\d{11}|\d{3}-\d{3}-\d{4}|\d{2}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4})$/)) ? false : true;
}