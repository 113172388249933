import React from "react";
// import ReactImageAppear from 'react-image-appear';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import AttachMoney from "@material-ui/icons/AttachMoney";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// exp components
import NBrootsPagination from "components/@NBrootsPagination/NBrootsPagination.js";
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsDialogPlayer from "components/@NBrootsDialogPlayer/NBrootsDialogPlayer.js";
import NBrootsImg from "components/@NBrootsImg/NBrootsImg.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";

import Service from "utils/service/service.js"
import ccyFormat from "utils/functions/ccyFormat"
import cEmptyToNull from "utils/functions/cEmptyToNull"

import { BASE_URL, VALIDATE_MSG, CORP_ID_SELECTED_ALL } from 'variables/config.js';
import styles from "assets/jss/material-dashboard-pro-react/views/Markets/marketForUserStyle.js";
import { PAGINATION_PREV_NAME, PAGINATION_NEXT_NAME } from 'variables/config.js';
// 素材-種類リスト
import { userInfo, materialCategoryInfo } from 'variables/runtimeInfos.js';

const useStyles = makeStyles(styles);

export default function MarketForUser(props) {

  const classes = useStyles();

  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //player画面用情報          playerInfo  : [表示FLG、VedioSrc]
  const [playerInfo, setPlayerInfo] = React.useState([false, '']);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //検索条件（初期状態）
  var tSearch = {
    categoryId: CORP_ID_SELECTED_ALL,           //検索条件　素材-種類ID
    orderby   : 'update_time',          //orderby
    sorttype  : 'desc',                 //sorttype
    pageIndex: 1,            //当ページ番号
    pageSize: 8            //ページサイズ
  }
  const [search, setSearch] = React.useState(tSearch);
  //検索結果（初期状態）
  var tSearchResult = {
    total: 0,            //記録数
    lst: []            //明細リスト
  }
  const [searchResult, setSearchResult] = React.useState(tSearchResult);

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {

    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search,props);

  }, [search,props]);    //[] 忘れないよう…　初期化


  //APIJSON　→　画面用JSON（FLGやERRMSGなど）
  // const formatLstData = async () => {
  const formatLstData = (tSearchCondition,tProps) => {


    var url = BASE_URL + "/API/MntSysAdminList/Search";

    var data = {
      id        : cEmptyToNull(tSearchCondition.categoryId === CORP_ID_SELECTED_ALL ? '' : tSearchCondition.categoryId),
      orderby   : tSearchCondition.orderby,
      sorttype  : tSearchCondition.sorttype,
      pageIndex : tSearchCondition.pageIndex,
      pageSize  : tSearchCondition.pageSize
    };
    // var tResult ;
    Service(tProps, url, data).then(resp => {
      // console.log("検索結果-----------20200421");
      // console.log(resp);
      var tResult;
      // 正常系
      if (resp.status === 0) {
        var info = resp.infos;
        //Data情報の処理
        var lst = info.list;
        //検索結果（セット）
        tResult = {
          total: info.total,
          lst: [...lst]
        }
        // console.log(tResult);
        setSearchResult(tResult);
        //FLAGなどの整理
        //  lst = lst.map(item => {
        //   return {
        //     ...item,
        //     //追加属性
        //     isEdit        : false,
        //     chk_name      : '',
        //     msg_name      : '',
        //   }
        // });
        //検索結果（セット）
        tResult = {
          total: info.total,
          lst: [...lst]
        }
        // console.log(tResult);
        setSearchResult(tResult);
      } else {
        //その他APIエラー
        //検索結果（初期状態）
        tResult = {
          total: 0,
          lst: []
        }
        setSearchResult(tResult);
        setMsgInfo([true, resp.msg, 'warning']);
      }
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });


  };


  //改ページ処理
  // pageInfos   : [ページサイズ,当ページ番号]
  const handleChangePageStart = (page) => {

    let t = search;
    if (page.disabled) return;
    if (page.text === search.pageIndex) return;

    if (page.text === PAGINATION_PREV_NAME) {
      t.pageIndex = t.pageIndex - 1;
    } else if (page.text === PAGINATION_NEXT_NAME) {
      t.pageIndex = t.pageIndex + 1;
    } else {
      t.pageIndex = page.text;
    }

    //情報セット
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  };

  //画面動作 PLAY
  const handleClickStartPlay = (obj) => {

    // setPlayerInfo([true, obj.src]);

    setIsLoading(true);

    var url = BASE_URL + "/API/MntSysAdminList/UpdatePlayTimes";

    //　入力データ
    var data = {
      // id          : cEmptyToNull(obj.id),
      id_mater: obj.id,
      id_user: userInfo.userid,
      playTimes: obj.playTimes,
      updateIndex: obj.updateIndex,
    };
    // console.log(data+'20200422----------');

    Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        setMsgInfo([true, resp.msg, 'success']);
        //情報のロード
        formatLstData(search);
        setPlayerInfo([true, obj.src]);
      } else {
        setMsgInfo([true, resp.msg, 'danger']);
      }
      //進捗画面を閉じる
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };

  //画面動作 PLAY
  const handleClickStartBuy = (obj) => {
    setMsgInfo([true, '作成中…' , 'danger']);
    //　var index = searchResult.lst.indexOf(obj);

    // console.log(obj);

    //　alert("Buy " + index + "\r\n" + JSON.stringify(obj));

  };

  const handleChangeSearchStart = (e) => {

    let t = search;
    //選択情報をSTATEに保存
    if (e.target.name === 'typeSelect') {
      t.categoryId = e.target.value;
    } else if (e.target.name === 'sortItemSelect') {
      t.orderby = e.target.value;
    }

    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  }

  return (
    <Box>
      <Grid container spacing={1}>
        {/* 検索部 */}
        <Grid item xs={6} sm={6} className={classes.searchBox}>
          <FormControl style={{ width: "40%" }}
            className={classes.selectFormControl}>
            <InputLabel htmlFor="typeSelect" className={classes.selectLabel}>
              種類
            </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              value={search.categoryId}
              onChange={(e) => handleChangeSearchStart(e)}
              inputProps={{ name: "typeSelect"}}>
              <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                種類を選択
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={CORP_ID_SELECTED_ALL}>
                すべて
              </MenuItem>
              {materialCategoryInfo.lst ? materialCategoryInfo.lst.map((item,key) =>
                <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.categoryId} key={1000 + key}>
                  {item.categoryName}
                </MenuItem> 
              ) : null}
              {/* <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={1} key={1}>
                春
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={2} key={2}>
                夏
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={3} key={3}>
                秋
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={4} key={4}>
                冬
              </MenuItem> */}

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6}></Grid>
        <Grid item xs={12} sm={3}></Grid>
        {/* ページジャンプ */}
        <Grid item xs={12} sm={6} className={classes.pageJumpBox}>
          {/* [記録数、ページサイズ,当ページ番号] */}
          <NBrootsPagination
            count={searchResult.total}
            pagesize={search.pageSize}
            current={search.pageIndex}
            onClick={handleChangePageStart}
            color="warning"
          />

        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl style={{ width: 120 }}
            variant="filled"
            underline="none"
            className={classes.selectFormControl2}>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select2 }}
              value={search.orderby}
              onChange={(e) => handleChangeSearchStart(e)}
              inputProps={{ name: "sortItemSelect"}}>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={'update_time'} key={201}>
                更新日
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={'create_time'} key={202}>
                登録日
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={'play_times'} key={203}>
                プレイ数
              </MenuItem>

            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box xs={12} className={classes.gidBox}>
        <GridContainer>
          {searchResult.lst ? searchResult.lst.map((item, idx) =>
            <GridItem xs={12} sm={6} md={3} key={idx}>
              <Card product className={classes.cardHover}>
                <CardHeader image className={classes.cardHeaderHover}>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <NBrootsImg src={item.imagesrc} />
                  </a>
                  {/* <ReactImageAppear src={item.imagesrc} placeholder/> */}
                </CardHeader>
                <CardBody>
                  <div className={classes.cardHoverUnder}>
                    <Tooltip id="tooltip-top" title="プレイ" placement="bottom"
                      classes={{ tooltip: classes.tooltip }}>
                      <Button color="transparent" simple justIcon>
                        <PlayCircleOutline className={classes.underChartIcons}
                          onClick={() => handleClickStartPlay(item)} />
                      </Button>
                    </Tooltip>
                  </div>
                  <Box>
                    <h4 className={classes.cardProductTitle}>
                      {item.name}
                    </h4>
                    <p className={classes.cardProductTitle}>
                      {item.remark}
                    </p>
                  </Box>
                </CardBody>
                <CardFooter product>
                  <Box className={classes.price}>
                    <p>{"￥"+ccyFormat(item.price)}</p>
                  </Box>
                  <Box className={classes.price}>
                    {/* <Button color="warning" size="sm" onClick={() => handleClickStartBuy(item)}>
                      {"購入"}
                    </Button> */}
                  </Box>
                  {/* <Box className={classes.price}>
                    <p>{parseInt(item.playTimes, 10) === 0 ? "0 プレイ数" : ccyFormat(item.playTimes) + " プレイ数"}</p>
                  </Box> */}
                </CardFooter>
              </Card>
            </GridItem>
          ) : null}

        </GridContainer>
      </Box>
      {/* 共通ダイアログ画面 */}
      <NBrootsDialogPlayer open={playerInfo[0]} src={playerInfo[1]} onClose={() => setPlayerInfo([false, ''])} />
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Box>
  );
}
