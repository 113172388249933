import {
  warningColor,
  whiteColor,
  grayColor,
  blackColor,
  behanceColor,
  dangerColor,
  tooltip,
  cardTitle,
  hexToRgb
} from 'assets/jss/material-dashboard-pro-react.js';

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import customCommonStyle from "assets/jss/material-dashboard-pro-react/customCommonStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.js';

const terminalsListStyle = {
  ...customCheckboxRadioSwitch,
  ...customCommonStyle,
  ...customSelectStyle,
  ...hoverCardStyle,
  tooltip:{
    ...tooltip,
    marginTop: '0px',
    marginBottom: '3px'
  },
  cardTitle: {
    ...cardTitle,
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    color:whiteColor,
    textDecoration: "none"
  },
  card: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  cardBody: {
    padding: '0.4375rem 20px',
  },
  detailCardHeader: {
    backgroundColor: grayColor[11],
    paddingTop     : '0',
    paddingBottom  : '0',
    "& > div > div > div > h4": {
      fontWeight   : '800',
      paddingRight : '15px',
    },
  },
  statusBody: {
    paddingTop: '0',
  },
  addTitle: {
    textAlign:'left',
  },
  status: {
    display: 'flex',
    "& > button": {
      height       : '20px',
      width        : '110px',
      marginTop : '10px',
    },
  },
  statusSuccess: {
    backgroundColor: behanceColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(behanceColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(behanceColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(behanceColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: behanceColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(behanceColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(behanceColor) +
        ", 0.2)"
    }
  },
  searchBox:{
    textAlign:'left',
  },
  optDel:{
    "& > button": {
      right   : '10px',
      marginLeft   : '10px',
    },
    textAlign :'right',
  },
  optEdit:{
    "& > button": {
      marginTop: '30px',
      right    : '10px',
    },
    textAlign :'right',
  },
  optBox:{
    "& > button": {
      right   : '10px',
      marginLeft   : '10px',
    },
    textAlign :'right',
  },
  areaTitle: {
    "& > h4": {
      fontWeight: '800',
    },
  },
  inputLabel: {
    fontSize: "14px",
    paddingLeft: '64px',
    paddingTop: '20px',
  },
  msgHeader: {
    color: blackColor,
    fontSize: "14px",
    fontWeight: '800',
    paddingLeft: '15px',
    paddingTop: '20px',
  },
  msgBody: {
    color: blackColor,
    fontSize: "14px",
    paddingLeft: '30px',
    paddingTop: '5px',
  },
  suiteLogicLabel: {
    color: blackColor,
    fontSize: "14px",
    paddingLeft: '50px',
    paddingTop: '5px',
  },
  selectControl: {
    "& > div > div": {
      paddingTop: '6px',
      paddingLeft: '25px',
    }
  },
  customInput: {
    "& > div": {
      paddingTop: '11px',
      marginBottom: '3px',
    },
    "& > div > div > input": {
      marginLeft: '25px',
    }
  },
  runTimeBox: {
    paddingTop: '0px',
  },
  runTimeSelectControl: {
    paddingRight: '25px',
    "& > div > div": {
      paddingTop: '12px',
      paddingLeft: '29px',
    }
  },
  runTimeLabel: {
    color: grayColor[14],
    fontSize: '14px',
  },
  runTimeInput: {
    "& > div:first-child": {
      paddingTop: '4px',
    },
    "& > div > label": {
      marginTop: '20px',
      marginLeft: '29px',
    },
    "& > div > div > div > input": {
      margin: '0',
      paddingTop: '12px',
      textAlign:'center',
    }
  },
  runTimeInputInit: {
    "& > div > div > div > div > input": {
      margin: '0',
      paddingTop: '12px',
      textAlign:'center',
    }
  },
  addButton: {
    textAlign: "right",
    width: "34%",
    paddingTop: "10px",
    paddingRight: "28px",
    "& > button": {
      padding: '0',
      height: '18px',
      width: '18px',
      minWidth: '18px',
    },
    "& > button > span": {
      color: '#000',
      height: '18px',
      width: '18px',
    }
  },
  removeButton: {
    textAlign: "right",
    paddingRight: "25px",
    "& > button": {
      padding: '0',
      height: '18px',
      width: '18px',
      minWidth: '18px',
    },
    "& > button > span": {
      color: '#000',
      height: '18px',
      width: '18px',
    }
  },
  sizeYInput: {
    "& > label": {
      paddingTop: '24px',
      paddingLeft: '29px',
    },
    "& > div > div": {
      paddingTop: '15px',
    },
    "& > div > div > div > input": {
      margin: '0',
      textAlign:'center',
    }
  },
  sizeXInput: {
    "& > label": {
      paddingTop: '14px',
      paddingLeft: '29px',
    },
    "& > div > div": {
      paddingTop: '5px',
    },
    "& > div > div > div > input": {
      margin: '0',
      textAlign:'center',
    }
  },
  pageJumpBox:{
    textAlign:'center',
    height:'40px',
  },
  tableBox:{
    paddingTop: '10px',
    paddingLeft: '50px',
  },
  vediolstTableBox:{
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '50px',
  },
  tableCardBody:{
    padding       : '10px',
    height        : '100%',
    width         : '100%',
    borderRadius  : '6px',
    //display       : 'block',
    position      : 'relative',
    // position      : 'absolute'
    // marginTop: '10px',

    // width:'100%',
    // marginTop: '10px',
    // overflowY:'hidden',
    // overflowX: 'hidden',
  },
  table:{
    height    : '100%',
    width     : '100%',
    marginTop : '0px',
    display   : 'block',
    // position  : 'fixed',
    overflowX : 'auto',
    overflowY : 'auto'
  },
  /*テーブルヘッダ部ICONのSTYLE*/
  tableHeadIcon: {
    margin          : 0,
    '&:hover': {
        backgroundColor: '#EFEFEF',
    }

  },
  tableHeadSwapIcon: {
    color        : '#c3c3c3',
    height       : '17px',
    width        : '17px',
    '&:hover': {
          backgroundColor: '#EFEFEF',
      }
  },
  /*テーブルヘッダ部のSTYLE*/
  tableHead: {
    // fontSize          : '14px',
    // backgroundColor   : '#f8f8f8',
    padding           : '10px 10px 10px 10px',
    minheight         : '50px',
    minwidth          : '80px',
    height            : '50px',
    // color             : '#383838',
    // fontWeight        : 'bold',
    lineHeight        : '1rem',
    verticalAlign     : 'middle',
    textAlign         : 'center',
    'z-index'         : '10',
    'white-space'     : 'nowrap',
  },
  tableSortHeadWrapper: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  /*テーブルBODY部のSTYLE*/
  tableCell:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'center',
    "& > div": {
      padding: '0',
      margin: '0',
    },
    "& > div > div > input": {
      marginLeft: '25px',
    }
  },
  /*テーブルBODY部のSTYLE*/
  tableCellLeft:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'left',
    "& > div": {
      padding: '0',
      margin: '0',
    },
    "& > div > div > input": {
      marginLeft: '25px',
    }
  },
  deviceMatrixTableBox:{
    padding     : '30px 0 30px 0',
    alignItems  : 'center',
    display     : 'flex',
    justifyContent : 'center',
  },
  deviceMatrixTableCardBody:{
    padding       : '20px',
    borderRadius  : '6px',
    boxShadow     : 'none',
    position      : 'relative',
    backgroundColor: grayColor[11],
  },
  deviceMatrixTableCell:{
    border      : '0',
    padding     : '15px 15px 5px 15px',
    textAlign   : 'center',
    "& > div": {
      padding: '0',
      margin: '0',
    },
    "& > ul > li > button": {
      backgroundColor: grayColor[4],
      width       : '55px',
      height      : '55px',
      fontSize    : "20px",
      fontWeight  : "400",
      textTransform: "uppercase",
      color: blackColor + " !important",
      boxShadow   : 'none',
    },
    "& > ul > li > button:hover,& > ul > li > button:focus": {
      backgroundColor: grayColor[4],
      boxShadow: 'none',
    },
  },
  /*テーブルBODY部のSTYLE*/
  tableCellRight:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'right'
  },
  /*テーブルBODY部のSTYLE*/
  tableBody:{
    maxheight        : '500px',
    overflow         : 'visible'
  },
  /*テーブル行のSTYLE*/
  tableRow:{
    marginLeft       : '3px',
    backgroundColor  : '#FFFFFF',
    height           : '40px',
    '&:hover': {
        backgroundColor: '#FFFDF2',
      }
  },
  tableRow2:{
    marginLeft       : '3px',
    backgroundColor  : '#FFFDF2',
    height           : '40px',
    cursor           : 'pointer',
  },
  //操作ボタン
  actionButton: {
    //margin: '10px 0px 0px 10px',
    margin: '5px',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px'
    }
  },
  inputName: {
    margin: '0 0 5px 0',
    paddingTop: '10px'
  },
  dialogHeader: {
    backgroundColor: warningColor[0] + '!important',
    color: whiteColor + ' !important',
  },
  error: {
    color : dangerColor[0]
  },
  cloudBule: {
    color : '#2189bb'
  },
  green:{
    color : '#4caf50'
  },
  gray:{
	color : '#999999'
  }


};

export default terminalsListStyle;
