import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";

// exp components
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";

import { VALIDATE_MSG } from 'variables/config.js';
import isNotPassNullChk from "utils/validations/isNotPassNullChk";
import isNotPassEmailChk from "utils/validations/isNotPassEmailChk";

import { BASE_URL } from 'variables/config.js';
import ServiceLogin from "utils/service/login.js"
import Service from "utils/service/service.js"
import cEmptyToNull from "utils/functions/cEmptyToNull"
import { userInfo } from 'variables/runtimeInfos.js';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/MngSystem/Login/loginStyle.js";
import logo from "assets/img/dimpact_logo.jpeg";
import { initSystemData } from 'layouts/initSystemData.js';

const useStyles = makeStyles(styles);

export default function Login(props) {
  
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();

  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);

    //画面編集項目＜初期状態＞
  // メールアドレス　パスワード
  var t = {
    email         : "",
    password      : "",       //入力項目
    msg_email     : "",       //MSG項目
    msg_password  : "",
    chk_email     : "",       //CHECK結果＜CSS影響＞
    chk_password  : "", 
  };
  const [vals, setVals] = React.useState([t]);      //[] !!!変更後、画面render発生させるため、array使用

  //初期化処理
  React.useEffect(() => {
    //Clean
    // return () =>{
    //   setVals([tParam]);
    // }
  }, []);    //毎回OPENする時処理を行う

  function validate(tVals,name,value){
    //チェック処理
    if(name === 'email'){
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassEmailChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_101;
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if(name === 'password'){
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }
  };

  //編集発生する時
  const handleEditOnChange = (name,value) => {

    let tVals = vals[0];

    //STATE内容、項目名、入力値
    validate(tVals,name,value);

    //情報をSTATEに保存する。
    tVals[name]  = value;
    
    setVals([tVals]);
  };

  // ユーザー情報を取得
  const queryUserInfo = (userID) => {
    //ログイン処理
    var url = BASE_URL + "/API/UserInfo/"+userID;
   
    //　入力データ
    var data = {
    };

    // ログイン処理
    return Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        // 登陆客户信息
        var info = resp.infos;

        userInfo.userid      = info.userid;
        userInfo.username    = info.username;
        userInfo.useremail   = info.useremail;
        userInfo.corpid      = info.corpid;
        userInfo.corpname    = info.corpname;
        // ユーザ権限　
        // ０一般ユーザ　1　企業管理員　2　システム管理員
        userInfo.userlevel   = info.userlevel;
      
        // 正常系戻り値：null
        return null;
      }

      // 異常系戻り値：エラーメッセージ
      return VALIDATE_MSG.MSG_102;
     });
  };

  //ログインボタン押す
  const handleClickLoginBtn = () => {

    let tVals = vals[0];

    //一括チェック
    validate(tVals,'email',tVals.email);
    validate(tVals,'password',tVals.password);

    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if( tVals["chk_mail"] === "error" ||
        tVals["chk_password"] === "error"){

        setVals([tVals]);
        return;
    }

    //ログイン処理
    var url = BASE_URL + "/API/auth/login";
    setIsLoading(true);
    
    //　入力データ
    var data = {
      username          : cEmptyToNull(tVals.email),
      password          : cEmptyToNull(tVals.password),
    };

    // ログイン処理
    ServiceLogin(url, data).then(resp => {
      // 異常系
      if (resp === undefined || resp === null) {
        return null;
      }

      // 正常系戻り値：ユーザーID
      return resp;
    })
    // ログイン
    //   成功：ユーザ情報の取得を行う
    //   失敗：エラーメッセージ戻り
    .then((userID) => {
      // 異常系戻り値：エラーメッセージ
      if (userID === null) {
        return VALIDATE_MSG.MSG_102;
      }

      // 正常系：ユーザ情報を取得
      return queryUserInfo(userID);
    })
    // ユーザ情報の取得
    //   成功：初期化を行う
    //   失敗：エラーメッセージ戻り
    .then((msg)=>{
      // 異常系戻り値：エラーメッセージ
      if (msg !== null) {
        return msg;
      }
      
      // 正常系戻り値：初期化を取得
      return initSystemData(props);
    })
    // 初期化
    //   成功：画面遷移
    //   失敗：エラーメッセージの表示
    .then((msg)=>{
      setIsLoading(false);

      // 正常系戻り値：画面遷移
      if(msg === 'ok'){
        const { from } = props.location.state || { from: '/TerminalList'};
        props.history.push(from);
      }
      // 異常系
      else {
        //接続エラーなど
        setMsgInfo([true, msg, 'danger']);
      }
    })
    .catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_102, 'danger']);
    });
  };

  const onKeyDown = (e) => {
    if(e.keyCode === 13){
      
      handleClickLoginBtn();

    }
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center" onKeyDown={ onKeyDown }>
        <GridItem xs={12} sm={8} md={6}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <img src={logo} alt="logo" width="300"/>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="メールアドレス"
                  id="email"
                  success={vals[0].chk_email === "success"}
                  error={vals[0].chk_email === "error"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value     : vals[0].email,  
                    onChange  : event => handleEditOnChange("email", event.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                  helperText={vals[0].msg_email}   
                />
                <CustomInput
                  labelText="パスワード"
                  id="password"
                  success={vals[0].chk_password === "success"}
                  error={vals[0].chk_password === "error"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value     : vals[0].password,  
                    onChange  : event => handleEditOnChange("password", event.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                  helperText={vals[0].msg_password}   
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button round onClick={handleClickLoginBtn}  color="warning">
                  ログイン
                </Button>
                <a href="/auth/register">
                  <Button round color="warning">
                    新規登録
                  </Button>
                </a>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      {/* 共通ダイアログ画面 */}
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </div>
  );
}
