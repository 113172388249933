import {
  cardTitle,
  blackColor,
  warningColor,
  whiteColor,
  grayColor,
  defaultFont,
  tooltip,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import modalCloseButton from "assets/jss/material-dashboard-pro-react/modalStyle";
import customCommonStyle from "assets/jss/material-dashboard-pro-react/customCommonStyle.js";

const CommonPopupStyle = {
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...customCommonStyle,

  cardTitle,
  tooltip,

  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  modalDialog: {
    minHeight: "500px"
  },
  modalUploadDialog: {
    minHeight: "400px"
  },
  modalCloseButton: {
    ...modalCloseButton,
    marginTop: "0px",
    marginBottom: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    width: "32px",
    height: "32px"
  },
  optBox:{
    textAlign:"right",
  },
  optBoxLeft:{
    textAlign:"left",
  },
  optBoxCenter:{
    textAlign:"center",
  },
  customFormControlClasses: {
    width: "50%",
    marginRight:20
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex"
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    transform: 'translate(0, 1.5px)',
    'transform-origin': 'top left',
    "& + $underline": {
      marginTop: "0px"
    }
  },
  mrAuto: {
    marginRight: "auto"
  },
  mlAuto: {
    marginLeft: "auto"
  },
  //操作ボタン
  actionButton: {
    //margin: '10px 0px 0px 10px',
    margin: '5px',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px'
    }
  },
  dialogHeader: {
    backgroundColor: warningColor[0] + "!important",
    color: whiteColor + " !important",
  },
  /*テーブル行のSTYLE*/
  tableRow2:{
    marginLeft       : '3px',
    backgroundColor  : '#FFFFFF',
    height           : '40px',
    cursor           : 'pointer',
    '&:hover': {
        backgroundColor: '#FFFDF2',
      }
  },
  /*テーブルBODY部のSTYLE*/
  tableCellCenter:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'center'
  },
  tableHeadCenter: {
    // fontSize          : '14px',
    // backgroundColor   : '#f8f8f8',
    padding           : '10px 10px 10px 10px',
    minheight         : '50px',
    minwidth          : '80px',
    height            : '50px',
    // color             : '#383838',
    // fontWeight        : 'bold',
    lineHeight        : '1rem',
    verticalAlign     : 'middle',
    textAlign         : 'center',
    'z-index'         : '10',
    'white-space'     : 'nowrap',
  },
};

export default CommonPopupStyle;
