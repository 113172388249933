import React from "react";
import PropTypes from "prop-types";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import InputLabel from "@material-ui/core/InputLabel";
import Paper from '@material-ui/core/Paper';
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from "@material-ui/core/Hidden";
import RootRef from "@material-ui/core/RootRef";
import Dvr from "@material-ui/icons/Dvr";
import CloudDone from "@material-ui/icons/CloudDone";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CloudCircle from "@material-ui/icons/CloudCircle";
import Error from "@material-ui/icons/Error";
// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// exp components
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
//PlayList一覧画面
import PlayListPopup from "views/CommonPopup/PlayListPopup.js";
import PlayListDetailPopup from "views/CommonPopup/PlayListDetailPopup.js";

import { BASE_URL, VALIDATE_MSG } from 'variables/config.js';

import Service from "utils/service/service.js"
import isNotPassNullChk from "utils/validations/isNotPassNullChk";
// import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk";
import batFormat from "utils/functions/batFormat";
import millisecondFormat from "utils/functions/millisecondFormat";

import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";

import styles from "assets/jss/material-dashboard-pro-react/views/TerminalList/terminalDetailStyle.js";

// メモリ中企業リスト
// import { groupsInfo, getGroupsInfo } from 'variables/runtimeInfos.js';

export default function TerminalDetailItem(props) {
  //样式
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  //前画面渡された情報
  const { info, setInfo, setReloadInfo, value, index, devices, deviceErrorLst, hasDeviceError, idCompany, termid, pageid, suite, setTabIndex, handleClickSync, handleClickDownloadBtn, validCompany, validPlayList} = props;
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  // PlayList一覧画面
  const [playListInfo, setPlayListInfo] = React.useState([false, '', false]);
  const [playListDetailInfo, setPlayListDetailInfo] = React.useState([false, '']);



  React.useEffect(() => {

    // formatDetailData(item);
    // Clean
    return () =>{
    }
  }, [info]);    //[item] 渡された情報を変わる時、初期化する

  //===========================================
  //編集発生する時
  //===========================================
  const handleEditOnChange = (name, value) => {

    let tinfo = info;
    // //入力チェック
    validate(tinfo, name, value);
    //情報をSTATEに保存する。
    tinfo[name] = value;

    setInfo(tinfo);
    // //Copy by value
    // var tResult = Object.assign({}, tinfo);
    // //STATEにセットする。
    // setinfo(tResult);
    // //情報を親PROPSに保存する。
    // setItem(tResult);

  }

  const formatDeviceOtherDisabled = () => {
    if (devices === undefined || devices.length === 0) {
      return true;
    }
    // deviceErrorLst::deviceError 0->端末から接続がない
    if (hasDeviceError(deviceErrorLst, 0)) {
      return true;
    }

    return false;
  };

  //入力チェック
  function validate(tinfo, name, value) {
    //チェック処理
    if (name === 'name') {
      if (isNotPassNullChk(value)) {
        tinfo["chk_" + name] = "error";
        tinfo["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassLengthChk(value, null, 100)) {
        tinfo["chk_" + name] = "error";
        tinfo["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
       } else {
        tinfo["chk_" + name] = "success";
        tinfo["msg_" + name] = "";
      }
    }else if(name === 'feature'){
      if (isNotPassNullChk(value)) {
        tinfo["chk_" + name] = "error";
        tinfo["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassLengthChk(value, null, 100)) {
        tinfo["chk_" + name] = "error";
        tinfo["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        tinfo["chk_" + name] = "success";
        tinfo["msg_" + name] = "";
      }
    }else if(name === 'deviceid'){
      if (isNotPassNullChk(value)) {
        tinfo["chk_" + name] = "error";
        tinfo["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassLengthChk(value, null, 100)) {
        tinfo["chk_" + name] = "error";
        tinfo["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        tinfo["chk_" + name] = "success";
        tinfo["msg_" + name] = "";
      }
    }
    // else if(name === 'ssid'){
    //   if (isNotPassNullChk(value)) {
    //     tinfo["chk_" + name] = "error";
    //     tinfo["msg_" + name] = VALIDATE_MSG.MSG_001;
    //   } else if (isNotPassLengthChk(value, null, 50)) {
    //     tinfo["chk_" + name] = "error";
    //     tinfo["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 50);
    //   } else {
    //     tinfo["chk_" + name] = "success";
    //     tinfo["msg_" + name] = "";
    //   }
    // }
  };

  //===========================================
  //PlayList追加
  //===========================================
  //PlayList追加ボタン押す
  const handleClickAddPlaylistDialogOpen = (item, termIndex) => {

    if (isNotPassNullChk(idCompany)
      && validCompany !== undefined
      && validCompany !== null) {
      validCompany();
      return;
    }

    //STATEに情報をセット
    setTabIndex(termIndex);
    let tinfo = item;
    tinfo['idCompany'] = idCompany;
    tinfo['suite'] = suite;
    tinfo['playListBeforeAdd'] = item.playlst;
    //Copy by value
    // var tResult = Object.assign({}, tinfo);
    var tPlayListInfo = [
      true,           //Popup画面表示FLG
      tinfo,          //編集情報すべて子画面に渡す
      false           //保存ボタン押すFLG
    ]
    setPlayListInfo(tPlayListInfo);
  };

  //PlayList追加画面選択後の処理
  React.useEffect(() => {

    //起動確認結果
    if (playListInfo[2] === true) {
      //STATE情報渡す
      setInfo(playListInfo[1]);

      // if (validPlayList !== undefined
      //     && validPlayList !== null) {
      //   validPlayList();
      // }
    }
  }, [playListInfo]);

  const handleClickStartEdit = (obj, id) => {
    let tinfo = {};
    tinfo['playlistid'] = obj.id;
    tinfo['upload'] = obj.localvediolst && obj.localvediolst.length;
    tinfo['termid'] = id;
    tinfo['idTermPlaylist'] = obj.idTermPlaylist;
    tinfo['getDownLoadStatus'] = getDownLoadStatus;
    tinfo['pageid'] = pageid;

    var tPlayListDetialInfo = [
      true,           //Popup画面表示FLG
      tinfo,
      false,
    ]
    setPlayListDetailInfo(tPlayListDetialInfo);
  }

  //PlayList詳細画面戻り後の処理
  React.useEffect(() => {

    //起動確認結果
    if (playListDetailInfo[2] === true) {
      if (pageid === "TerminalDetail") {
        //最新情報取得
        handleClickReloadBtn();
      }
    }
  }, [playListDetailInfo]);
  
  //===========================================
  //PlayList削除機能
  //===========================================
  //明細行の削除ボタン
  const handleClickPlayListStartDel = (item, termIndex) => {
    setTabIndex(termIndex);
    //Copy　STATE情報　By　Value
    var tResult = JSON.parse(JSON.stringify(info[termIndex]));
    if (suite === 0) {
      //操作行の取得
      var idx = info[termIndex].playlst.indexOf(item);
      //情報の一時削除
      tResult.playlst.splice(idx, 1);
    } else {
      tResult.playlst = [];
    }
    //画面呼び出す
    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        opt        : "del",
        id         : tResult,     //STATE情報渡す
        result     : 0
      }
    ]);
  };

  //明細行削除の確認メッセージ後の処理
  React.useEffect(() => {
    //起動確認結果
    if (confirmInfo[2].opt === "del" && confirmInfo[2].result === 1) {
      //STATE情報渡す
      setInfo(confirmInfo[2].id);

      // if (validPlayList !== undefined
      //   && validPlayList !== null) {
      //   validPlayList();
      // }
    }
  }, [confirmInfo]);

  //最新情報取得ボタン押す
  const handleClickReloadBtn = () => {
    //進捗画面
    setIsLoading(true);

    // var url = BASE_URL + "/API/TerminalList/GetLocalVedios";
    var url = BASE_URL + "/API/TerminalList/Get";

    var data = {
      // id: info.idtermid
      id: termid
    };

    var lst = [];

    // var tResult;
    Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        // lst = resp.infos;
        lst = resp.infos.lst;
        setReloadInfo(lst);

      } else {
        //その他APIエラー
        setMsgInfo([true, resp.msg, 'warning']);
      }

      // info.localvediolst = lst;
      // setInfo(info);

      setIsLoading(false);

    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });

  }

  React.useEffect(() => {
    //起動確認結果
    if (confirmInfo[2].opt === "reDownload" && confirmInfo[2].result === 1) {
      //STATE情報渡す
      var url = BASE_URL + "/API/TerminalList/RedownloadTerminalDownlodItem";

      console.log('reDownload')

      var data = confirmInfo[2].id;

      // var tResult;
      Service(props, url, data).then(resp => {
        console.log(resp.status)
        // 正常系
        if (resp.status === 0) {

          setMsgInfo([true, resp.msg, 'success']);

          // 重新导入画面数据
          handleClickReloadBtn();
        } else {
          //その他APIエラー
          setMsgInfo([true, resp.msg, 'warning']);
        }

        setIsLoading(false);

      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });
    }
  }, [confirmInfo]);

  const reDownload = (item) => {

    if(item === null || item.downLoadStatus !== 6){
      return;
    }

    setConfirmInfo([true, VALIDATE_MSG.MSG_508,
      {
        opt        : "reDownload",
        id         : item,     //STATE情報渡す
        result     : 0
      }
    ]);
  };

  /** 拖拽 ---start---*/
  const reorder = (lstContent, startIndex, endIndex) => {
    const result = Array.from(lstContent);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: "#dadada",
      display: 'table'
    })
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let tInfo = info;

    tInfo.playlst = reorder(
      info.playlst,
      result.source.index,
      result.destination.index
    );

    var tResult = Object.assign({}, tInfo);
    setInfo(tResult);
  };
  /** 拖拽 ---end---*/

  const getDownLoadStatus = (status) => {
    if(status === 0) {
      // return <Tooltip id="tooltip-top" title="更新待ち" placement="bottom" classes={{ tooltip: classes.tooltip }}><CloudCircle  className={classes.gray}/></Tooltip>;
      return "実行待ち";
    }else if(status === 1) {
      // return <Tooltip id="tooltip-top" title="更新中" placement="bottom" classes={{ tooltip: classes.tooltip }}><CloudDownload className={classes.cloudBule}/></Tooltip>;
      return "実行中";
    }else if(status === 2) {
      // return <Tooltip id="tooltip-top" title="更新完了" placement="bottom" classes={{ tooltip: classes.tooltip }}><CloudDone className={classes.green}/></Tooltip>;
      return "済";
    }else if(status === 6) {
      // return <Tooltip id="tooltip-top" title="更新失敗" placement="bottom" classes={{ tooltip: classes.tooltip }}><Error className={classes.error}/></Tooltip>;
      return "失敗";
    }
  }

  const getCommonTermDownLoadStatus = (localvediolst) => {
    var status = [];
    for (let idx = 0; idx < localvediolst.length; idx++) {
      status.push(getDownLoadStatus(localvediolst[idx].downLoadStatus));
    }

    if (status.indexOf("失敗") >= 0) {
      return "失敗";
    } else if (status.indexOf("済") >= 0 && status.indexOf("実行中") < 0 && status.indexOf("実行待ち") < 0) {
      return "済";
    } else if (status.indexOf("済") >= 0 || status.indexOf("実行中") >= 0) {
      return "実行中";
    } else {
      return "実行待ち";
    }
  }

  return (
    <Box>
      {suite === 0 ?
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            {/* 機器名称（位置 :  ） */}
            <div className={classes.areaTitle}><h4>{info && info.length > 0 ? info[0].name : ""}（位置 : 1）</h4></div>
          </Grid>
          {pageid === "TerminalDetail" ?
            formatDeviceOtherDisabled() ?
              <Grid item xs={6} sm={6} className={classes.optBox}>
                <Button color="warning" disabled={true} onClick={handleClickDownloadBtn}>
                  アップロード
                </Button>
                <Button color="warning" onClick={handleClickReloadBtn}>
                  最新情報取得
                </Button>
              </Grid>
              :
              <Grid item xs={6} sm={6} className={classes.optBox}>
                <Button color="warning" onClick={handleClickDownloadBtn}>
                  アップロード
                </Button>
                <Button color="warning" onClick={handleClickReloadBtn}>
                  最新情報取得
                </Button>
              </Grid>
            : null
          }
          {pageid === "TerminalEdit" ?
            <Grid item xs={6} sm={6} className={classes.optBox}>
              <Button color="warning" onClick={() => { handleClickAddPlaylistDialogOpen(info[0], 0) }}>
                プレイリスト追加
              </Button>
            </Grid>
            : null
          }
        </Grid>
        :
        pageid === "TerminalDetail" ?
          <Grid container spacing={1}>
            <Hidden smDown>
              <Grid item xs={6} sm={7}>
                <InputLabel className={classes.selectLabel, classes.suiteLogicLabel}>
                  ※ 連携モードでは、プレイリストに含められる動画は 1 つのみです。
                </InputLabel>
              </Grid>
              {formatDeviceOtherDisabled() ?
                <Grid item xs={6} sm={5} className={classes.optBox}>
                  <Button color="warning" disabled={true} onClick={handleClickSync}>
                    映像を揃える
                  </Button>
                  <Button color="warning" disabled={true} onClick={handleClickDownloadBtn}>
                    アップロード
                </Button>
                  <Button color="warning" onClick={handleClickReloadBtn}>
                    最新情報取得
                  </Button>
                </Grid>
                :
                <Grid item xs={6} sm={5} className={classes.optBox}>
                  <Button color="warning" onClick={handleClickSync}>
                    映像を揃える
                  </Button>
                  <Button color="warning" onClick={handleClickDownloadBtn}>
                    アップロード
                  </Button>
                  <Button color="warning" onClick={handleClickReloadBtn}>
                    最新情報取得
                  </Button>
                </Grid>
              }
            </Hidden>
            {/* 携帯の場合、ボタンの幅を縮小する */}
            <Hidden mdUp>
              <Grid item xs={6} sm={10}>
                <InputLabel className={classes.selectLabel, classes.suiteLogicLabel}>
                  ※ 連結モードでは、プレイリストに含められる動画は 1 つのみです。
                </InputLabel>
              </Grid>
              {formatDeviceOtherDisabled() ?
                <Grid item xs={6} sm={2} className={classes.optBox}>
                  <Button color="warning" disabled={true} onClick={handleClickSync}>
                    映像を揃える
                </Button>
                  <Button color="warning" disabled={true} onClick={handleClickDownloadBtn}>
                    アップロード
                </Button>
                <Button color="warning" onClick={handleClickReloadBtn}>
                    最新情報取得
                  </Button>
                </Grid>
                :
                <Grid item xs={6} sm={2} className={classes.optBox}>
                  <Button color="warning" onClick={handleClickSync}>
                    映像を揃える
                  </Button>
                  <Button color="warning" onClick={handleClickDownloadBtn}>
                    アップロード
                  </Button>
                  <Button color="warning" onClick={handleClickReloadBtn}>
                    最新情報取得
                  </Button>
                </Grid>
              }
            </Hidden>
          </Grid>
          :
          <Grid container spacing={1}>
            <Grid item xs={6} sm={12}>
              <InputLabel className={classes.selectLabel, classes.suiteLogicLabel}>
                ※ 連携モードでは、プレイリストに含められる動画は 1 つのみです。
            </InputLabel>
            </Grid>
          </Grid>
      }
      {suite === 0 ?
        <Box xs={12} className={classes.vediolstTableBox}>
          <Paper className={classes.tableCardBody}>
            <TableContainer className={classes.table}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead} style={{ width: "14%" }}>プレイリスト名</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "14%" }}>登録動画数</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "14%" }}>再生時間</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "14%" }}>サイズ</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "14%" }}>ステータス</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "30%" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {info && info.length > 0 && info[0].playlst ? info[0].playlst.map((item, key) =>
                    // <TableRow className={classes.tableRow} key={80001000 + key} onClick={() => reDownload(item)}>
                    <TableRow className={classes.tableRow} key={80001000 + key}>
                      <TableCell className={classes.tableCell} >{item.name}</TableCell>
                      <TableCell className={classes.tableCell} >{item.vedioCount}</TableCell>
                      <TableCell className={classes.tableCell} >{millisecondFormat(item.duration)}</TableCell>
                      <TableCell className={classes.tableCell} >{batFormat(item.materialSize)}</TableCell>
                      <TableCell className={classes.tableCell} >
                        {item.localvediolst && item.localvediolst.length > 0 ?
                          getCommonTermDownLoadStatus(item.localvediolst)
                          :
                          "未設定"
                        }
                      </TableCell>
                      <TableCell className={classes.tableCellRight} >
                        <Tooltip id="tooltip-top" title="詳細情報" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                          <Button simple className={classes.actionButton} onClick={() => { handleClickStartEdit(item, info[0].id) }} color="warning" >
                            <Dvr />
                          </Button>
                        </Tooltip>
                        {pageid === "TerminalEdit" ?
                          <Tooltip id="tooltip-top" title="削除" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                            <Button simple className={classes.actionButton} onClick={() => { handleClickPlayListStartDel(item, 0) }} color="danger">
                              <Delete />
                            </Button>
                          </Tooltip>
                          : null}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        :
        <Box xs={12} className={classes.vediolstTableBox}>
          <Paper className={classes.tableCardBody}>
            <TableContainer className={classes.table}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead} style={{ width: "14%" }}>機器位置</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "14%" }}>機器名称</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "14%" }}>プレイリスト名</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "14%" }}>サムネイル</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "14%" }}>ステータス</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "30%" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {info ? info.map((item, key) =>
                    <TableRow className={classes.tableRow} key={80002000 + key}
                      // onClick={() => reDownload(item.playlst && item.playlst.length > 0 && item.playlst[0].localvediolst && item.playlst[0].localvediolst.length > 0 ? item.playlst[0].localvediolst[0] : null)}
                      >
                      <TableCell className={classes.tableCell} >{key + 1}</TableCell>
                      <TableCell className={classes.tableCell} >{item.name}</TableCell>
                      <TableCell className={classes.tableCell} >
                        {item.playlst && item.playlst.length > 0 ?
                          item.playlst[0].name
                          :
                          "未設定"
                        }
                      </TableCell>
                      <TableCell className={classes.tableCell} >
                        {item.playlst && item.playlst.length > 0 ?
                          <img className={classes.imagesrc} height="15%" src={item.playlst[0].imagesrc} alt="..." />
                          :
                          "未設定"
                        }
                      </TableCell>
                      <TableCell className={classes.tableCell} >
                        {item.playlst && item.playlst.length > 0 ?
                          item.playlst[0].localvediolst && item.playlst[0].localvediolst.length > 0 ?
                            getDownLoadStatus(item.playlst[0].localvediolst[0].downLoadStatus)
                            :
                            "未設定"
                          :
                          "未設定"
                        }
                      </TableCell>
                      <TableCell className={classes.tableCellRight} >
                        {item.playlst && item.playlst.length > 0 ?
                          <Tooltip id="tooltip-top" title="詳細情報" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                            <Button simple className={classes.actionButton} onClick={() => { handleClickStartEdit(item.playlst[0], item.id) }} color="warning" >
                              <Dvr />
                            </Button>
                          </Tooltip>
                          : null
                        }
                        {pageid === "TerminalEdit" ?
                          item.playlst && item.playlst.length > 0 ?
                            <Tooltip id="tooltip-top" title="削除" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                              <Button justIcon simple className={classes.actionButton} onClick={() => { handleClickPlayListStartDel(item, key) }} color="danger">
                                <Delete />
                              </Button>
                            </Tooltip>
                            :
                            <Tooltip id="tooltip-top" title="追加" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                              <Button justIcon simple className={classes.actionButton} onClick={() => { handleClickAddPlaylistDialogOpen(item, key) }} color="behance">
                                <Add />
                              </Button>
                            </Tooltip>
                          : null
                        }
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      }
      <PlayListPopup info={playListInfo} setInfo={setPlayListInfo} />
      <PlayListDetailPopup info={playListDetailInfo} setInfo={setPlayListDetailInfo} />
      {/* 共通ダイアログ画面 */}
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Box >
  );
}

TerminalDetailItem.propTypes = {
  info: PropTypes.any.isRequired,
  setInfo: PropTypes.any.isRequired,
  setTabIndex: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  validCompany: PropTypes.any.isRequired,
  validPlayList:  PropTypes.any.isRequired,
};