/**
 *　映像一覧選択
 * 
 */
/* eslint-disable */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Grid from '@material-ui/core/Grid';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
//import Select from "@material-ui/core/Select";
//import MenuItem from "@material-ui/core/MenuItem";
// import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// exp components
import NBrootsPagination from "components/@NBrootsPagination/NBrootsPagination.js";
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";

import { BASE_URL } from 'variables/config.js';
import Service from "utils/service/service.js"
import cEmptyToNull from "utils/functions/cEmptyToNull"
import batFormat from "utils/functions/batFormat";
import millisecondFormat from "utils/functions/millisecondFormat";
import styles from "assets/jss/material-dashboard-pro-react/views/CommonPopup/CommonPopupStyle";

// メモリ中企業リスト
import { userInfo, corpsInfo } from 'variables/runtimeInfos.js';
import { PAGINATION_PREV_NAME,PAGINATION_NEXT_NAME, VALIDATE_MSG } from 'variables/config.js';

export default function VedioListPopup(props) {

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { info, setInfo } = props;
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);
  //検索条件（初期状態）
  var tSearch = {
    crop      : '',           //検索条件　企業ID
    orderby   : '',           //orderby
    sorttype  : '',           //sorttype
    pageIndex : 1,            //当ページ番号
    pageSize  : 10            //ページサイズ
  }
  const [search, setSearch] = React.useState(tSearch);
  //検索結果（初期状態）
  var tSearchResult = {
    total     : 0,            //記録数
    lst       : []            //明細リスト
  }
  const [searchResult, setSearchResult] = React.useState(tSearchResult);

  const open = info[0];

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if(open){
      let tSearch = search;
      tSearch['crop'] = info[1].idCompany;
      //進捗画面
      setIsLoading(true);
      //情報のロード
      formatLstData(tSearch);
    }
  }, [open]);    //[] 子画面の場合、毎回開く時、処理を行う

  const formatLstData = (tSearchCondition) => {
    var url = BASE_URL + "/API/VideoList/Search";
    //　検索条件  略
    var data = {
      companyid : cEmptyToNull(tSearchCondition.crop),
      pageIndex : tSearchCondition.pageIndex,
      pageSize  : tSearchCondition.pageSize
    };
    // console.log("検索条件-----------");
    // console.log(data);
    var tResult;
    Service(props, url, data).then(resp => {
      // 正常系
      // console.log("検索結果-----------");
      if (resp.status === 0) {
        var infos = resp.infos;
        //Data情報の処理
        var lst = infos.list;
        //FLAGなどの整理
        lst = lst.map(item => {
          return {
            ...item,
            //追加属性
            isChecked       : false     //選択されているＦＬＧ
          }
        });
        //検索結果（セット）
        tResult = {
          total     : infos.total,
          lst       : [...lst]
        }
        setSearchResult(tResult);
      } else {
        //その他APIエラー  
        //検索結果（初期状態） 
        tResult = {
          total : 0,
          lst   : []
        }
        setSearchResult(tResult);
        setMsgInfo([true, resp.msg, 'warning']);
      }
      setIsLoading(false);
    }).catch(resp => {
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };

  //検索条件部
  //企業変更の場合
  const handleChangeSearchStart = (e) => {
    let t = search;
    //選択情報をSTATEに保存
    if(e.target.name === 'corpSelect')
      t.crop = e.target.value;

    setSearch(t);
    //検索ロジックを行う
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  }

  //改ページ処理
  // pageInfos   : [ページサイズ,当ページ番号]
  const handleChangePageStart = (page) => {
    let t = search;
    if (page.disabled) return;
    if (page.text === search.pageIndex) return;

    if (page.text === PAGINATION_PREV_NAME) {
      t.pageIndex = t.pageIndex - 1;
    } else if (page.text === PAGINATION_NEXT_NAME) {
      t.pageIndex = t.pageIndex + 1;
    } else {
      t.pageIndex = page.text;
    }
    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  };

  //編集発生する時
  const handleEditOnChange = (item, name, value) => {
    let tVals = searchResult;
    //編集内容の行を取得
    var idx = tVals.lst.indexOf(item);
    // 情報をSTATEに保存する。
    // 明細［行番］［属性］
    if(name ==="isChecked" ){
      tVals.lst[idx][name] ? tVals.lst[idx][name] =  false : tVals.lst[idx][name] =  true; 
    }    

    //Copy by value
    var tResult = Object.assign({}, tVals);
    //情報をSTATEに保存する。
    setSearchResult(tResult);
  }

  //保存ボタン押す
  const handleClickOKBtn = () => {
    //前画面取得情報
    var tResult = info[1];

    var checkedLst = [];
    for(var i = 0;i<searchResult.lst.length;i++){
      var item = searchResult.lst;
      
      if (item[i].isChecked == true){
        var tmp = {
          ...item[i],
          order         : 0,    //PLAYLIST用情報の追加　順番
          loop_count    : 1,    //PLAYLIST用情報の追加　回数
        }
        checkedLst.push(tmp);
      }
    }
    checkedLst = info[1].lst.concat(checkedLst);
    tResult.lst = checkedLst;
    
    //[当画面表示FLG ,　情報,　IsAfterOK]
    var tReturnVal = [ false, tResult, false];
    setInfo(tReturnVal);

  }

  //キャンセルボタン押す
  const handleClickCancelBtn = () => {
    //[当画面表示FLG ,　情報,　IsAfterOK]
    var tReturnVal = [ false, {}, false];
    setInfo(tReturnVal);
  }

  return (
    <Dialog fullWidth={true} maxWidth={'lg'} onClose={handleClickCancelBtn} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" className={classes.dialogHeader} onClose={handleClickCancelBtn}>
          映像追加
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="warning"
          onClick={handleClickCancelBtn}
          style={{ float: "right" }}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent>
        <GridItem className={classes.modalDialog} xs={12} sm={12} md={12} lg={12}>
          <GridContainer>
            {/* <Grid item xs={12} sm={4}>
            <FormControl style={{ width: "80%" }}
              className={classes.selectFormControl}
              disabled={userInfo.userlevel===2?false:true}>
                <InputLabel htmlFor="corpSelect" className={classes.selectLabel}>
                  企業
                </InputLabel>
                <Select
                  MenuProps={{ className: classes.selectMenu }}
                  value={search.crop}
                  classes={{ select: classes.select }}
                  onChange={(e) => handleChangeSearchStart(e)}
                  inputProps={{ name: "corpSelect"}}>
                  <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                    企業を選択
                  </MenuItem>
                  {corpsInfo ? corpsInfo.lst.map((item,key) =>
                    <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={key}>
                      {item.corpname}
                    </MenuItem> 
                  ) : null}
                </Select>
              </FormControl>
              &nbsp;
              <br />
            </Grid> */}
            <Grid item xs={12} sm={8} className={classes.optBox}>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.optBox}>
              <NBrootsPagination
                count={searchResult.total}
                pagesize={search.pageSize}
                current={search.pageIndex}
                onClick={handleChangePageStart}
                color="warning"
              />
            </Grid>
            <TableContainer style={{ minHeight: 440, maxHeight: 440 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead} style={{ width: "10%" }}></TableCell>
                    {/* <TableCell className={classes.tableHead} style={{ width: "25%" }}>企業</TableCell> */}
                    <TableCell className={classes.tableHead} style={{ width: "35%" }}>映像名称</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "15%" }}>サムネイル</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "15%" }}>サイズ</TableCell>
                    <TableCell className={classes.tableHead} style={{ width: "15%" }}>再生時間</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {searchResult.lst ? searchResult.lst.map((item, key) =>
                    <TableRow className={classes.tableRow2} key={key}>
                      <TableCell className={classes.tableCell}>
                        <Checkbox
                          tabIndex={-1}
                          checked={item.isChecked}
                          onChange={event => handleEditOnChange(item, "isChecked", event.target.value)}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      </TableCell>
                      {/* <TableCell className={classes.tableCell}>{item.idCompanyName}</TableCell> */}
                      <TableCell className={classes.tableCell} onClick={event => handleEditOnChange(item, "isChecked", item.isChecked)}>{item.name}</TableCell>
                      <TableCell className={classes.tableCell} onClick={event => handleEditOnChange(item, "isChecked", item.isChecked)}><img className={classes.imagesrc} height="15%" src={item.imagesrc} alt="..." /></TableCell>
                      <TableCell className={classes.tableCell} onClick={event => handleEditOnChange(item, "isChecked", item.isChecked)}>{batFormat(item.size)}</TableCell>
                      <TableCell className={classes.tableCell} onClick={event => handleEditOnChange(item, "isChecked", item.isChecked)}>{millisecondFormat(item.duration)}</TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </GridContainer>
        </GridItem>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancelBtn} color="warning">
          キャンセル
          </Button>
        <Button onClick={handleClickOKBtn} color="warning">
          選択
        </Button>
      </DialogActions>
        {/* 共通ダイアログ画面 */}
        <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
        <NBrootsDialogProcess open={isLoading} color="warning" />
        <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Dialog>

  );
}
