import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
// import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import UserProfile from "views/UserInfo/UserProfile";
import PasswordUpdate from "views/UserInfo/PasswordUpdate.js"
import ErrorPage from "views/Pages/ErrorPage.js"

import TerminalAdd from "views/TerminalList/TerminalAdd.js"
import TerminalEdit from "views/TerminalList/TerminalEdit.js"
import TerminalDetail from "views/TerminalList/TerminalDetail.js"
import PlayListAdd from "views/PlayList/PlayListAdd"
import PlayListDetail from "views/PlayList/PlayListDetail"

import routes from "routes.js"
import AuthRoute from "components/@NBrootAuthRoute/AuthRoute.js"

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js"

// Demo用
import { userInfo } from 'variables/runtimeInfos.js';
import { initSystemData } from './initSystemData.js';

var ps;

const useStyles = makeStyles(styles);

export default function Default(props) {

  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);

  const image = require("assets/img/sidebar-2.jpg");
  const color = "orange"
  const bgColor = "black"
  const logo = require("assets/img/logo.png");

  // styles
  const classes = useStyles();

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({ [classes.mainPanelSidebarMini]: miniActive });

  // ref for main panel div
  const mainPanel = React.createRef();

  //起動後初期化処理
  initSystemData(props);

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {


    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";

    //routes.js設定以外の画面項目
    if (window.location.href.indexOf("/auth/login") !== -1) {
      return "ログイン";
    } else if (window.location.href.indexOf("/TerminalList/Add") !== -1) {
      return "端末追加";
    } else if (window.location.href.indexOf("/TerminalList/Edit") !== -1) {
      return "端末編集";
    } else if (window.location.href.indexOf("/TerminalList/Detail/") !== -1) {
      return "端末詳細";
    } else if (window.location.href.indexOf("/PlayList/Add") !== -1) {
      return "プレイリスト追加";
    } else if (window.location.href.indexOf("/PlayList/Detail/") !== -1) {
      return "プレイリスト詳細";
    } else if (window.location.href.indexOf("/Setting/User") !== -1) {
      return "アカウント情報";
    } else if (window.location.href.indexOf("/Setting/PasswordUpdate") !== -1) {
      return "パスワード変更";
    }

    for (let i = 0; i < routes.length; i++) {

      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].path === "/TerminalList") {
            return "";
          } else {
            return routes[i].name;
          }
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      //権限判断
      if (prop.userlevel > userInfo.userlevel) return null;
      // ０一般ユーザ　1　企業管理員　2　システム管理員
      if (userInfo.userlevel === 2 && prop.userlevel === 1 ) return null

      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "") {
        return (
          <AuthRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (

    <div className={classes.wrapper}>
      {/* 左側操作メニュー */}
      <Sidebar
        routes={routes}
        // logoText={"Creative Tim"}
        logoText={logo}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        {/* ヘッダ部 */}
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* 内容部 */}
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>

              <AuthRoute path="/TerminalList/Detail/:id" component={TerminalDetail} />
              <AuthRoute path="/TerminalList/Add" component={TerminalAdd} />
              <AuthRoute path="/TerminalList/Edit/:id" component={TerminalEdit} />

              <AuthRoute path="/PlayList/Detail/:id" component={PlayListDetail} />
              <AuthRoute path="/PlayList/Add" component={PlayListAdd} />

              <AuthRoute path="/Setting/User" component={UserProfile} />
              <AuthRoute path="/Setting/PasswordUpdate" component={PasswordUpdate} />
              <Route path="/ErrorPage" component={ErrorPage} />

              {getRoutes(routes)}

              <Redirect from="/" to="/auth" />
            </Switch>
          </div>
        </div>

      </div>
    </div>
  );
}
