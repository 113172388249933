/* eslint-disable */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Delete from "@material-ui/icons/Delete";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// core components
import Button from "components/CustomButtons/Button.js";

// メモリ中企業リスト
import { userInfo } from 'variables/runtimeInfos.js';

// exp components
import NBrootsPagination from "components/@NBrootsPagination/NBrootsPagination.js";
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";


import UserListAddPopup from "views/CommonPopup/UserListAddPopup.js";
import UserListDetailPopup from "views/CommonPopup/UserListDetailPopup.js";

import Service from "utils/service/service.js"
import { BASE_URL,VALIDATE_MSG } from 'variables/config.js';
import cEmptyToNull from "utils/functions/cEmptyToNull";

import styles from "assets/jss/material-dashboard-pro-react/views/MngSystem/UserList/userListStyle.js";
// メモリ中企業リスト
import { corpsInfo } from 'variables/runtimeInfos.js';

import { PAGINATION_PREV_NAME, PAGINATION_NEXT_NAME, CORP_ID_SELECTED_ALL } from 'variables/config.js';

export default function UserList(props) {
  //样式
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);

 
  //検索条件（初期状態）
  var tSearch = {
    crop: CORP_ID_SELECTED_ALL,           //
    orderby: 'desc',           //orderby
    sorttype: 'update_time',           //sorttype
    pageIndex: 1,            //当ページ番号
    pageSize: 10            //ページサイズ
  }
  const [search, setSearch] = React.useState(tSearch);
  //検索結果（初期状態）
  var tSearchResult = {
    total     : 0,            //記録数
    lst       : []            //明細リスト
  }
  const [searchResult, setSearchResult] = React.useState(tSearchResult);

  //追加画面用
  var tUserListAddInfo = [
    //当画面表示FLG    
    false,
    //当画面編集情報       
    {},
    //IsAfterOK
    false
  ]
  const [userListAddInfo, setUserListAddInfo] = React.useState(tUserListAddInfo);
  //詳細画面用
  var tUserListDetailInfo = [
    //当画面表示FLG    
    false,
    //当画面編集情報       
    {
      flag : "MngSys",
      id    : ""
    },
    //IsAfterOK
    false
  ]
  const [userListDetailInfo, setUserListDetailInfo] = React.useState(tUserListDetailInfo);

  //初期化処理
  React.useEffect(() => {
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);

  }, []);    //[] 忘れないよう…　初期化


  const formatLstData = (tSearchCondition) => {

    var url = BASE_URL + "/API/MntSysUserList/Search";

    var data = {
      companyid: cEmptyToNull(tSearchCondition.crop === CORP_ID_SELECTED_ALL ? '' : tSearchCondition.crop),
      orderby   : tSearchCondition.orderby,
      sorttype  : tSearchCondition.sorttype,
      pageIndex: tSearchCondition.pageIndex,
      pageSize: tSearchCondition.pageSize
    };

    var tResult;
    Service(props, url, data).then(resp => {
      // 正常系
      if (resp.status === 0) {
        var info = resp.infos;
        //Data情報の処理
        var lst = info.list;
        //検索結果（セット）
        tResult = {
          total     : info.total,
          lst       : [...lst]
        }
        setSearchResult(tResult);
      } else {
        //その他APIエラー   
        tResult = {
          total     : 0,
          lst       : []
        }
        setSearchResult(tResult);
        setMsgInfo([true, resp.msg, 'warning']); 
      }
      setIsLoading(false);
    }).catch(resp => {
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
  };

  //検索条件部
  //企業変更の場合
  const handleChangeSearchStart = (e) => {
    let t = search;
    //選択情報をSTATEに保存
    if (e.target.name === 'corpSelect') {
      t.crop = e.target.value;
    }
    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  }

  //改ページ処理
  // pageInfos   : [記録数、ページサイズ,当ページ番号]
  const handleChangePageStart = (page) => {
    let t = search;
    if (page.disabled) return;
    if (page.text === search.pageIndex) return;

    if (page.text === PAGINATION_PREV_NAME) {
      t.pageIndex = t.pageIndex - 1;
    } else if (page.text === PAGINATION_NEXT_NAME) {
      t.pageIndex = t.pageIndex + 1;
    } else {
      t.pageIndex = page.text;
    }
    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  }

  //===========================================
  //追加機能
  //===========================================
  //追加ボタン押す
  const handleClickStartAdd = () => {
    userListAddInfo[0] = true;
    userListAddInfo[1] = {
      flag : "MngSys"
    };                //    企業のアカウント管理    
    userListAddInfo[2] = false;
    setUserListAddInfo([...userListAddInfo]);
  }

  //Popup画面後の処理
  React.useEffect(() => {
    //追加処理
    function doAdd(obj) {
      // console.log(obj);
      setIsLoading(true);
      var url = BASE_URL + "/API/MntSysUserList/Create";
      var data = {
        idCompany: cEmptyToNull(obj.companyID),  
        email: cEmptyToNull(obj.email),
        name: cEmptyToNull(obj.name),
        kana: cEmptyToNull(obj.kana),
        password: cEmptyToNull(obj.password),
        admin: obj.admin,
      };

      Service(props, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {

          setMsgInfo([true, resp.msg, 'success']);
          //情報のロード
          formatLstData(search);

        } else {
          //その他APIエラー      
          setMsgInfo([true, resp.msg, 'warning']);
        }

        setIsLoading(false);

      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });
    }

    //保存ボタン押す後
    if (userListAddInfo[2] === true)
      doAdd(userListAddInfo[1]);

  }, [userListAddInfo]);


  //===========================================
  //編集機能
  //===========================================
  //編集ボタン押す　Popup
  const handleClickStartEdit = (obj) => {
    //画面開くFLG
    userListDetailInfo[0] = true;
    //情報
    userListDetailInfo[1] = {
      flag  : "MngSys",
      id    : obj.id
    };    
    //保存ボタンFLG
    userListDetailInfo[2] = false;
    setUserListDetailInfo([...userListDetailInfo]);
  }

  //Popup画面後の処理
  React.useEffect(() => {
    //更新処理
    function doUpdate(obj) {
      setIsLoading(true);

      var url = BASE_URL + "/API/MntSysUserList/Update";

      var data = {
        idCompany: obj.companyID,  
        id: obj.id,
        email: cEmptyToNull(obj.email),
        name: cEmptyToNull(obj.name),
        kana: cEmptyToNull(obj.kana),
        password: cEmptyToNull(obj.password),
        admin: obj.admin,
        updateIndex: obj.updateIndex,    //更新の場合、必ず必須

      };

      Service(props, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {

          setMsgInfo([true, resp.msg, 'success']);
          //情報のロード
          formatLstData(search);

        } else {
          //その他APIエラー      
          setMsgInfo([true, resp.msg, 'warning']);
        }

        setIsLoading(false);

      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });

    }

    if (userListDetailInfo[2] === true)
      doUpdate(userListDetailInfo[1]);

  }, [userListDetailInfo]);


  //===========================================
  //削除機能
  //===========================================
  //画面動作 DEL
  const handleClickStartDel = (item) => {
    // console.log('削除:' + item);
    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        opt: "del",
        id: item.id,
        result: 0
      }
    ]);
  };

  //確認メッセージ後の処理
  React.useEffect(() => {
    //削除処理
    function doDel(obj) {
      setIsLoading(true);
      var url = BASE_URL + "/API/MntSysUserList/Delete";
      var data = {
        id: obj["id"]
      };
      Service(props, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {
          setMsgInfo([true, resp.msg, 'success']);
          //情報のロード
          formatLstData(search);
        } else {
          //その他APIエラー      
          setMsgInfo([true, resp.msg, 'success']);
        }

        setIsLoading(false);
      }).catch(resp => {
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });
    }

    //起動確認結果
    if (confirmInfo[2].opt === "del" && confirmInfo[2].result === 1) {
      // console.log(confirmInfo[2]);
      doDel(confirmInfo[2]);
    }

  }, [confirmInfo]);



  return (
    <Box>
      <Grid container spacing={1}>
        {/* 検索部 */}
        <Grid item xs={6} sm={6} className={classes.searchBox}>
          <FormControl style={{ width: "40%" }} className={classes.selectFormControl}>
            <InputLabel htmlFor="corpSelect" className={classes.selectLabel}>
              企業
            </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              value={search.crop}
              onChange={(e) => handleChangeSearchStart(e)}
              inputProps={{ name: "corpSelect" }}>
              <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                企業を選択
              </MenuItem>
              <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={CORP_ID_SELECTED_ALL}>
                すべて
              </MenuItem>
              {corpsInfo ? corpsInfo.lst.map((item, key) =>
                <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={key}>
                  {item.corpname}
                </MenuItem>
              )
                :null
              }

            </Select>
          </FormControl>

        </Grid>
        {/* 操作ボタン部 */}
        <Grid item xs={6} sm={6} className={classes.optBox}>
          <Button color="warning" onClick={() => handleClickStartAdd()}>アカウント追加</Button>
        </Grid>
        {/* ページジャンプ */}
        <Grid item xs={12} className={classes.pageJumpBox}>

          <NBrootsPagination
            count={searchResult.total}
            pagesize={search.pageSize}
            current={search.pageIndex}
            onClick={handleChangePageStart}
            color="warning"
          />
        </Grid>
      </Grid>

      <Box xs={12} className={classes.tableBox}>
        <Paper className={classes.tableCardBody}>
          <TableContainer className={classes.table}>

            <Table stickyHeader >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead} style={{ width: "15%" }}>企業</TableCell>
                  <TableCell className={classes.tableHead} style={{ width: "15%" }}>メールアドレス</TableCell>
                  <TableCell className={classes.tableHead} style={{ width: "15%" }}>ユーザー名</TableCell>
                  <TableCell className={classes.tableHead} style={{ width: "20%" }}>フリガナ</TableCell>
                  <TableCell className={classes.tableHead} style={{ width: "15%" }}>管理者</TableCell>
                  <TableCell className={classes.tableHead} style={{ width: "20%" }}>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {/* {lstContent ? lstContent.map((item, key) => */}
                {searchResult.lst ? searchResult.lst.map((item, key) =>
                  <TableRow className={classes.tableRow} key={key} >
                    <TableCell className={classes.tableCellLeft} onClick={() => { handleClickStartEdit(item) }}>{item.companyName}</TableCell>
                    <TableCell className={classes.tableCellLeft} onClick={() => { handleClickStartEdit(item) }}>{item.email}</TableCell>
                    <TableCell className={classes.tableCellLeft} onClick={() => { handleClickStartEdit(item) }}>{item.name}</TableCell>
                    <TableCell className={classes.tableCellLeft} onClick={() => { handleClickStartEdit(item) }}>{item.kana}</TableCell>
                    <TableCell className={classes.tableCell} onClick={() => { handleClickStartEdit(item) }}>
                      {item.admin === 1 ? <FiberManualRecordIcon style={{ color: "#4AC18A" }} /> : null}
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                      <Tooltip id="tooltip-top" title="詳細情報" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                        <Button simple className={classes.actionButton} onClick={() => { handleClickStartEdit(item) }} color="warning" >
                          <Dvr />
                        </Button>
                      </Tooltip>
                      <Tooltip id="tooltip-top" title="削除" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                        <Button simple className={classes.actionButton} onClick={() => { handleClickStartDel(item) }} color="danger">
                          <Delete />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      {/* 編集Popup画面 */}
      <UserListDetailPopup info={userListDetailInfo} setInfo={setUserListDetailInfo} />
      {/* 追加Popup画面 */}
      <UserListAddPopup info={userListAddInfo} setInfo={setUserListAddInfo} />
      {/* 共通ダイアログ画面 */}
      <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Box>
  );
}
