import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
// import Search from "@material-ui/icons/Search";
// import PagesIcon from "@material-ui/icons/Pages";

// core components
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

import { userInfo } from 'variables/runtimeInfos.js';
import { clearLoginUserInfo } from 'layouts/initSystemData.js';

const useStyles = makeStyles(styles);


// ヘッダ部タイトル画面コンポーネント
// 修正履歴
// 1. 20200324 権限チェック機能　検証実装
// 2. ゴミ削除
export default function HeaderLinks(props) {

  // const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogout = () => {
    clearLoginUserInfo();
    
    setOpenProfile(null);
  };

  const classes = useStyles();
  const { rtlActive } = props;

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });

  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });

  const managerClasses = classNames({
    [classes.managerClasses]: true
  });

  return (
    <div className={wrapper}>
      <div className={managerClasses}>


          <Hidden smDown implementation="css">
            <span className={classes.linkText}>
              {userInfo.userlevel === 0 ? "ユーザー | " : userInfo.userlevel === 1 ? "企業管理者 | " :userInfo.userlevel === 2 ? "システム管理者 | ":null }
              {userInfo.useremail + " | " }
              {userInfo.corpname}
            </span>

            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={handleClickProfile}
            >
              <Person />
            </Button>            
          </Hidden>
          <Hidden mdUp implementation="css">
            <Button
              color="transparent"
              aria-label="Person"
              justIcon
              aria-owns={openProfile ? "profile-menu-list" : null}
              aria-haspopup="true"
              onClick={handleClickProfile}
              className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
              muiClasses={{
                label: rtlActive ? classes.labelRTL : ""
              }}
            >
              <Person
                className={
                  classes.headerLinksSvg +
                  " " +
                  (rtlActive
                    ? classes.links + " " + classes.linksRTL
                    : classes.links)
                }
              /><span onClick={handleClickProfile} className={classes.linkText}>{"ユーザー"}</span>
            </Button>       
          </Hidden>
        {/* </Button> */}
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <Link to={"/Setting/User"}>
                      <MenuItem
                        onClick={handleCloseProfile}
                        className={dropdownItem}
                      >
                        {"アカウント情報"}
                      </MenuItem>
                    </Link>
                    <Link to={"/Setting/PasswordUpdate"}>
                      <MenuItem
                        onClick={handleCloseProfile}
                        className={dropdownItem}
                      >
                        {"パスワード変更"}
                      </MenuItem>
                    </Link>
                    <Divider light />
                    <Link to={"/auth/login"}>
                      <MenuItem
                        onClick={handleLogout}
                        className={dropdownItem}
                      >
                        {"ログアウト"}
                      </MenuItem>
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
