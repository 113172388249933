/**
 Timeチェック
    2:3
    02:3
    02:03
 
@param  begin   チェック対象
@param  end   チェック対象
@return Boolean チェック結果(問題あるかどうか)
                true  : 問題あり
                false : 問題なし
*/
export default function isNotPassTimeCompareChk(begin,end){
    var beginTime = new Date('1970-01-01T' + begin + 'Z');
    var endTime = new Date('1970-01-01T' + end + 'Z');
    if (beginTime.getTime() >= endTime.getTime()) return true;

    return false;
}