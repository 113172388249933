import React from "react";
import cloneDeep from 'lodash/cloneDeep';
import {
  warningColor,
} from 'assets/jss/material-dashboard-pro-react.js';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputLabel from "@material-ui/core/InputLabel";
// import Tooltip from "@material-ui/core/Tooltip";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Add from "@material-ui/icons/Add";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Pagination from "components/Pagination/Pagination.js";

// exp components
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";
import NBrootsTimePicker from "components/@NBrootsTimePicker/NBrootsTimePicker.js";

import Service from "utils/service/service.js"
import { BASE_URL, VALIDATE_MSG } from 'variables/config.js';

import isNotPassNullChk from "utils/validations/isNotPassNullChk.js";
import isNotPassLengthChk from "utils/validations/isNotPassLengthChk.js";
import isNotPassTimeChk from "utils/validations/isNotPassTimeChk.js";
import isNotPassTimeCompareChk from "utils/validations/isNotPassTimeCompareChk.js";
import isNotPassCharTypeChk from "utils/validations/isNotPassCharTypeChk.js";
import isNotPassNumberRangeChk from "utils/validations/isNotPassNumberRangeChk.js";
import goBack from "utils/functions/goBack";
import cTimeCompare from "utils/functions/cTimeCompare.js";

import TerminalDetailItem from "views/TerminalList/TerminalDetailItem";

import styles from "assets/jss/material-dashboard-pro-react/views/TerminalList/terminalAddStyle.js";

// メモリ中企業リスト
import { userInfo, corpsInfo } from 'variables/runtimeInfos.js';
// メモリ中企業リスト
import { groupsInfo, getGroupsInfo } from 'variables/runtimeInfos.js';

const theme = createMuiTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: warningColor[0]
      }
    },
    MuiTab: {
      root: {
        "&:hover": {
          color: warningColor[0]
        }
      },
    }
  }
});

export default function TerminalAdd(props) {
  //样式
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  // 進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "result": 0 }]);
  //曜日
  const days = [        //曜日
    {
      id: "0",                //
      day: "日",              //曜日
    },
    {
      id: "1",                //
      day: "月",              //曜日
    },
    {
      id: "2",                //
      day: "火",              //曜日
    },
    {
      id: "3",                //
      day: "水",              //曜日
    },
    {
      id: "4",                //
      day: "木",              //曜日
    },
    {
      id: "5",                //
      day: "金",              //曜日
    },
    {
      id: "6",                //
      day: "土",              //曜日
    },
  ];
  //画面編集項目＜初期状態＞
  var initVals = {
    id: "",       //端末ID
    idCompany: userInfo.userlevel === 2?'':userInfo.corpid,           //所属会社
    idCompanyName: "",       //所属会社名
    idGroup: "",       //所属設置場所
    // idDay: "",         //曜日
    idDay: "6",         //曜日
    name: "",          //端末名
    deviceStartTime: "00:00",     //＜放送条件＞開示時刻
    deviceEndTime: "23:59",       //＜放送条件＞終了時刻
    place: "",       //設置場所説明
    // runTimeLst: [],  //稼働時間リスト
    runTimeLst: [
      {
        idDay : "0",
        id : "",
        deviceStartTime: "00:00",
        deviceEndTime: "23:59",
      },
      {
        idDay : "1",
        id : "",
        deviceStartTime: "00:00",
        deviceEndTime: "23:59",
      },
      {
        idDay : "2",
        id : "",
        deviceStartTime: "00:00",
        deviceEndTime: "23:59",
      },
      {
        idDay : "3",
        id : "",
        deviceStartTime: "00:00",
        deviceEndTime: "23:59",
      },
      {
        idDay : "4",
        id : "",
        deviceStartTime: "00:00",
        deviceEndTime: "23:59",
      },
      {
        idDay : "5",
        id : "",
        deviceStartTime: "00:00",
        deviceEndTime: "23:59",
      },
    ],  //稼働時間リスト
    sizeX: "",       //端末サイズ（縦）
    sizeY: "",       //端末サイズ（横）
    deviceMatrix: [],//構成機器のマトリックス
    suite: "",       //連携端末区分　0　非　1　是
    sendAlertMail: 1,// アラートメール 0:ON / 1:OFF
    lst: []          //構成機器リスト   ［］
  };
  const [vals, setVals] = React.useState(initVals);
  const [selectedRow, setSelectedRow] = React.useState(props.location.state ? props.location.state.location : '');
  const [search, setSearch] = React.useState(props.location.state ? props.location.state.searchConds : '');
  //企業情報のセット
  getGroupsInfo(vals.idCompany);

  //タブ部画面編集内容一時保存用
  // const [tabInfos, setTabInfos] = React.useState([]);

  // 初期化処理
  React.useEffect(() => {

  }, []);    //[] 忘れないよう…　初期化

  //===========================================
  //編集発生する時
  //===========================================
  const handleEditOnChange = (name, value) => {
    let tVals = vals;
    //STATE内容、項目名、入力値
    validate(tVals, name, value);
    //情報をSTATEに保存する。
    tVals[name] = value;
    // var tResult = JSON.parse(JSON.stringify(tVals));
    var tResult = Object.assign({}, tVals);
    setVals(tResult);
  }

  //===========================================
  //端末サイズを編集発生する時
  //===========================================
  const handleSizeEditOnChange = (name, value) => {
    var tResult;
    let tVals = vals;
    let tDevice = tVals.lst;
    let tDeviceMatrix = tVals.deviceMatrix;

    //情報をSTATEに保存する。
    tVals[name] = value;

    //STATE内容、項目名、入力値
    validate(tVals, "sizeY", tVals["sizeY"]);
    validate(tVals, "sizeX", tVals["sizeX"]);

    if (tVals["chk_sizeY"] === "error" || tVals["chk_sizeX"] === "error") {
      // tDevice = [];
      // tDeviceMatrix = [];
    } else {
      var tDeviceInfo;
      var tDeviceMatrixX = [];
      var tDeviceLength  = tDevice.length;
      if (tDeviceLength > tVals.sizeY * tVals.sizeX) {
        tDevice = tDevice.slice(0, tVals.sizeY * tVals.sizeX);
      }

      tDeviceMatrix = [];
      for (let idxY = 0; idxY < tVals.sizeY; idxY++) {
        for (let idxX = 0; idxX < tVals.sizeX; idxX++) {
          tDeviceMatrixX.push({ id: (idxY * tVals.sizeX + idxX + 1) + "", });
          if (tDeviceLength < idxY * tVals.sizeX + idxX + 1) {
            tDeviceInfo = {
              id: "",
              suite_main: "",
              deviceid: "",
              name: "",
              feature: "",
              ssid: "ssid",
              localvediolst: [],
              playlst: [],
            };
            tDevice.push(tDeviceInfo);
          }
          tVals["chk_deviceid" + (idxY * tVals.sizeX + idxX)] = "success";
          tVals["msg_deviceid" + (idxY * tVals.sizeX + idxX)] = "";
          tVals["chk_name" + (idxY * tVals.sizeX + idxX)] = "success";
          tVals["msg_name" + (idxY * tVals.sizeX + idxX)] = "";
          tVals["chk_feature" + (idxY * tVals.sizeX + idxX)] = "success";
          tVals["msg_feature" + (idxY * tVals.sizeX + idxX)] = "";
        }

        tDeviceMatrix.push(tDeviceMatrixX);
        tDeviceMatrixX = [];
      }
    }
  
    //構成機器情報をSTATEに保存する。
    tVals["lst"] = tDevice;
    tVals["deviceMatrix"] = tDeviceMatrix;

    tResult = Object.assign({}, tVals);
    setVals(tResult);
  }

  //===========================================
  //起動時間・停止時間を編集発生する時
  //===========================================
  const handleRunTimeEditOnChange = (name, value) => {
    var tResult;
    let tVals = vals;

    //STATE内容、項目名、入力値
    validate(tVals, name, value);

    //情報をSTATEに保存する。
    tVals[name] = value;

    if (name === "deviceStartTime") {
      validate(tVals, "deviceEndTime", tVals.deviceEndTime);
    } else {
      validate(tVals, "deviceStartTime", tVals.deviceStartTime);
    }

    var tResult = Object.assign({}, tVals);
    setVals(tResult);
  }

  //===========================================
  //構成機器を編集発生する時
  //===========================================
  const handleDeviceEditOnChange = (name, id, value) => {
    var tResult;
    let tVals = vals;

    //STATE内容、項目名、入力値
    validate(tVals, name + id, value);

    //情報をSTATEに保存する。
    let tDevice = tVals.lst;
    var tDeviceInfo = tDevice[parseInt(id)];
    tDeviceInfo[name] = value;
    tDevice[parseInt(id)] = tDeviceInfo;
    tVals["lst"] = tDevice;
  
    tResult = Object.assign({}, tVals);
    setVals(tResult);
  }

  //企業変更の場合
  const handleChangeListStart = (name,value) => {
    var tResult ;
    //選択情報をSTATEに保存
    if(name === 'idCompany'){
      if(vals[name] !== ''){
        
        setConfirmInfo([true, VALIDATE_MSG.MSG_512,
          {
            "opt"     : "change",
            "id"      : {name : name, value : value},
            "result"  : 0
          }

        ]);

      }else{

        let tVals = vals;
        //STATE内容、項目名、入力値
        validate(tVals, name, value);
        //情報をSTATEに保存する。
        tVals[name] = value;

        getGroupsInfo(value);
        // copy　by　value
        tResult = Object.assign({}, tVals);

        setVals(tResult);
      }
            
    }else{      
      let tVals = vals;
      //STATE内容、項目名、入力値
      validate(tVals, name, value);
      //情報をSTATEに保存する。
      tVals[name] = value;
      // var tResult = JSON.parse(JSON.stringify(tVals));
      tResult = Object.assign({}, tVals);
  
      setVals(tResult);
    }
  }

  //編集発生する時の確認メッセージ後の処理
  React.useEffect(() => { 
    //起動確認結果
    if (confirmInfo[2].opt === "change" && confirmInfo[2].result === 1) {
      //STATE情報渡す
      let tVals = vals; 
      
      tVals.idCompany = confirmInfo[2].id.value;
      tVals.idGroup = "";
      tVals.place = "";
      for(let idxTerm=0; idxTerm<tVals.lst.length; idxTerm++ ){
        tVals.lst[idxTerm].playlst = [];
      }

      setVals(Object.assign({}, tVals));
      getGroupsInfo(confirmInfo[2].id.value);
    }
  }, [confirmInfo]);

  //設定する稼働時間を削除
  const removeRunTime = (index) => {
    var tResult;
    let tVals = vals;

    tVals.runTimeLst.splice(index, 1);

    //入力行の稼働時間をチェック
    let name = "idDay";
    let value = tVals.idDay;
    validate(tVals, name, value);

    tResult = Object.assign({}, tVals);
  
    setVals(tResult);
  };

  //設定する稼働時間を追加
  const addRunTime = () => {
    var tResult;
    let tVals = vals;
    let tRunTimeLst = tVals.runTimeLst;

    //曜日チェック
    let name="idDay";
    let value=vals.idDay;
    validate(tVals, name, value);
    if (tVals["chk_" + name] === "error"
      || tVals["chk_deviceStartTime"] === "error"
      || tVals["chk_deviceEndTime"] === "error"
      || tVals["chk_runTime"] === "error") {
      tResult = Object.assign({}, tVals);
      setVals(tResult);
      return;
    }

    tRunTimeLst.push({
      idDay : vals.idDay,
      id : "",
      deviceStartTime: tVals.deviceStartTime,
      deviceEndTime: tVals.deviceEndTime,
    });

    tVals["runTimeLst"] = tRunTimeLst;
    tVals["idDay"] = "";
    tVals["deviceStartTime"] = "00:00";
    tVals["deviceEndTime"] = "23:59";

    tResult = Object.assign({}, tVals);
  
    setVals(tResult);
  };

  //何もしない
  const doNothing = () => { };

  //入力チェック
  const validate = (tVals, name, value) => {
    //チェック処理
    if (name === 'idCompany') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'idGroup') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'idDay') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (!isNotPassNullChk(tVals.deviceStartTime) && !isNotPassNullChk(tVals.deviceEndTime) && isNotPassTimeCompareChk(tVals.deviceStartTime, tVals.deviceEndTime)) {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
        tVals["chk_deviceStartTime"] = "error";
        tVals["msg_deviceStartTime"] = VALIDATE_MSG.MSG_011;
        tVals["chk_deviceEndTime"] = "error";
        tVals["msg_deviceEndTime"] = VALIDATE_MSG.MSG_011;
      } else if (!isNotPassNullChk(tVals.deviceStartTime) && !isNotPassNullChk(tVals.deviceEndTime) && isInvalidDateRunTime(tVals, value, tVals.deviceStartTime, tVals.deviceEndTime)) {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'sizeY' || name === 'sizeX') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassCharTypeChk(value, "numeric")) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_007;
      } else if (parseInt(value, 10) < 1 || parseInt(value, 10) > 7) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_206.replace("{0}", 1).replace("{1}", 7);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'place') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassLengthChk(value, null, 255)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 255);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'deviceStartTime') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassTimeChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_010;
      } else if (!isNotPassNullChk(tVals.deviceEndTime) && isNotPassTimeCompareChk(value, tVals.deviceEndTime)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_011;
      } else if (!isNotPassNullChk(tVals.deviceEndTime) && isInvalidDateRunTime(tVals, tVals.idDay, value, tVals.deviceEndTime)) {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'deviceEndTime') {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassTimeChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_010;
      } else if (!isNotPassNullChk(tVals.deviceStartTime) && isNotPassTimeCompareChk(tVals.deviceStartTime, value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_011;
      } else if (!isNotPassNullChk(tVals.deviceStartTime) && isInvalidDateRunTime(tVals, tVals.idDay, tVals.deviceStartTime, value)) {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'name' || (name.length > 4 && name.substr(0, 4) === 'name')) {
    /*if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else*/ if (isNotPassLengthChk(value, null, 100)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'feature' || (name.length > 7 && name.substr(0, 7) === 'feature')) {
    /*if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else*/ if (isNotPassLengthChk(value, null, 100)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    } else if (name === 'deviceid' || (name.length > 8 && name.substr(0, 8) === 'deviceid')) {
      if (isNotPassNullChk(value)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      } else if (isNotPassLengthChk(value, null, 100)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 100);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }else if(name === 'ssid'){
      // if (isNotPassNullChk(value)) {
      //   tVals["chk_" + name] = "error";
      //   tVals["msg_" + name] = VALIDATE_MSG.MSG_001;
      // } else 
      if (isNotPassLengthChk(value, null, 50)) {
        tVals["chk_" + name] = "error";
        tVals["msg_" + name] = VALIDATE_MSG.MSG_200.replace("?", 50);
      } else {
        tVals["chk_" + name] = "success";
        tVals["msg_" + name] = "";
      }
    }
  };

  // 同一曜日内に複数の起動時間・停止時間の整合性チェック
  const isInvalidDateRunTime = (tVals, idDay, deviceStartTime, deviceEndTime) => {
    tVals["chk_runTime"] = "";
    tVals["msg_runTime"] = "";

    let tRunTimeLst = tVals.runTimeLst;
    let tIdDayRunTimeLst = [];
    for (let idx = 0; idx < tRunTimeLst.length; idx++) {
      if (tRunTimeLst[idx].idDay === idDay) {
        var tIdDayRunTime = tRunTimeLst[idx];
        tIdDayRunTime["rowNum"] = idx + 1;
        tIdDayRunTimeLst.push(tIdDayRunTime);
      }
    }

    if (tIdDayRunTimeLst.length === 0) {
      return false;
    }

    let tSortedIdDayRunTimeLst = tIdDayRunTimeLst.sort((a, b) => cTimeCompare(a.deviceStartTime, b.deviceStartTime));

    for (let idx = 0; idx < tSortedIdDayRunTimeLst.length; idx++) {
      var tRumTime = tSortedIdDayRunTimeLst[idx];
      var chkRunTime = days[parseInt(tRumTime.idDay)].day + " " + tRumTime.deviceStartTime + "〜" + tRumTime.deviceEndTime;

      if (deviceEndTime <= tRumTime.deviceStartTime) {
        // OK
        return false;
      } else if (deviceStartTime < tRumTime.deviceStartTime && tRumTime.deviceStartTime <= deviceEndTime) {
        // NG
        tVals["chk_runTime"] = "error";
        tVals["msg_runTime"] = VALIDATE_MSG.MSG_013.replace("{0}", tRumTime.rowNum).replace("{1}", chkRunTime);
        return true;
      } else if (tRumTime.deviceStartTime <= deviceStartTime && deviceStartTime < tRumTime.deviceEndTime) {
        // NG
        tVals["chk_runTime"] = "error";
        tVals["msg_runTime"] = VALIDATE_MSG.MSG_013.replace("{0}", tRumTime.rowNum).replace("{1}", chkRunTime);
        return true;
      }
    }

    return false;
  };

  //入力チェック
  const validateAll = (tVals) => {  
    //一括チェック
    validate(tVals,'idCompany',tVals.idCompany);
    validate(tVals,'idGroup',tVals.idGroup);
    validate(tVals,'place',tVals.place);
    validate(tVals,'idDay',tVals.idDay);
    validate(tVals,'sizeY',tVals.sizeY);
    validate(tVals,'sizeX',tVals.sizeX);
    validate(tVals,'deviceStartTime',tVals.deviceStartTime);
    validate(tVals,'deviceEndTime',tVals.deviceEndTime);
    
    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if(tVals["chk_place"] === "error" ||
      tVals["chk_idCompany"] === "error" ||
      tVals["chk_idGroup"] === "error" ||
      tVals["chk_idDay"] === "error" ||
      tVals["chk_sizeY"] === "error" ||
      tVals["chk_sizeX"] === "error" ||
      tVals["chk_deviceStartTime"] === "error" ||
      tVals["chk_deviceEndTime"] === "error"  ||
      tVals["chk_runTime"] === "error" ){
      
      return true;
    }
    
    // 連携端末の一括チェック
    var hasError = false;
    for(var i = 0;i < tVals.lst.length ;i++ ){
      validate(tVals,'name' + i,tVals.lst[i].name);
      validate(tVals,'feature' + i,tVals.lst[i].feature);
      validate(tVals,'deviceid' + i,tVals.lst[i].deviceid);
      validate(tVals,'ssid' + i,tVals.lst[i].ssid);

      //エラーがある場合、ＭＳＧを出す、処理を中断する。
      if( tVals["chk_name" + i] === "error" ||
          tVals["chk_feature" + i] === "error" ||
          tVals["chk_deviceid" + i] === "error" ||
          tVals["chk_ssid" + i] === "error" ){

        hasError = true;
      }
    }

    if (hasError) {
      return true;
    }

    // 連携端末のプレイリストの一括チェック
    if(validatePlayList()){
      return true;
    }

    return false;

  };

  const validateCompany = () => {  
    var tVals = vals;
    //一括チェック
    if (isNotPassNullChk(tVals.idCompany)) {
      tVals["chk_idCompany"] = "error";
      tVals["msg_idCompany"] = VALIDATE_MSG.MSG_513;
    }

    //エラーがある場合、ＭＳＧを出す、処理を中断する。
    if(tVals["chk_idCompany"] === "error"){
      return true;
    }

    return false;
  };

  const validatePlayList = () => {  
    var tVals = vals;
    // 連携端末の一括チェック
    if(vals.lst.length <= 1){
      tVals["chk_playlist"] = "success";
      tVals["msg_playlist"] = "";
      return false;
    }

    // 連携端末の一括チェック
    for(let idxTerm=0; idxTerm<tVals.lst.length; idxTerm++ ){
      // プレイリストの一括チェック
      let playlsts = tVals.lst[idxTerm].playlst;
      if(playlsts.length > 1){
        tVals["chk_playlist"] = "error";
        tVals["msg_playlist"] = VALIDATE_MSG.MSG_514;
        return true;
      } else if(playlsts.length === 1){
        // プレイリスト-項目の一括チェック
        let playlstItems = playlsts[0].playlist;
        if(playlstItems !== undefined){
          if(playlstItems.length > 1){
            tVals["chk_playlist"] = "error";
            tVals["msg_playlist"] = VALIDATE_MSG.MSG_514;
            return true;
          } else if(playlstItems.length === 1){
            if(playlstItems[0].loop_count > 1){
              tVals["chk_playlist"] = "error";
              tVals["msg_playlist"] = VALIDATE_MSG.MSG_514;
              return true;
            }
          }
        }
      }
    }

    tVals["chk_playlist"] = "success";
    tVals["msg_playlist"] = "";
    return false;
  };

  //保存ボタン押す
  const handleClickSaveBtn = () => {
    var tVals = vals;
    if(validateAll(tVals)){
      var tResult = Object.assign({}, tVals);
      setVals(tResult);
      return;
    }
    
    //進捗画面
    setIsLoading(true);

    var url = BASE_URL + "/API/TerminalList/Create";

    var tId;
    if (tVals.runTimeLst.length === 0) {
      tId = "0";
    } else {
      tId = (parseInt(tVals.runTimeLst[tVals.runTimeLst.length - 1].id) + 1).toString();
    }

    var data = tVals;

    var data = cloneDeep(tVals);

    data.runTimeLst.push({
      id : tId,
      idDay : vals.idDay,
      deviceStartTime: tVals.deviceStartTime,
      deviceEndTime: tVals.deviceEndTime,
    });

    if (data["deviceEndTime"] === '23:59') {
      data["deviceEndTime"] = data["deviceEndTime"] + ':59';
    }
    let tRunTimeLst = data.runTimeLst;
    for (let idx = 0; idx < tRunTimeLst.length; idx++) {
      if (tRunTimeLst[idx]["deviceEndTime"] === '23:59') {
        tRunTimeLst[idx]["deviceEndTime"] = tRunTimeLst[idx]["deviceEndTime"] + ':59';
      }
    }

    Service(props, url, data).then(resp => {

      // 正常系
      if (resp.status === 0) {
        setMsgInfo([true, resp.msg, 'success']);
        
        //props.history.push('/TerminalList'); ph1.0
        var info = resp.infos;
        props.history.push({ pathname: '/TerminalList/Detail/' + info.id, state: { goBackUrl: '/TerminalList', searchConds: search } });
      } else {
        //その他APIエラー      
        removeRunTime(tVals.runTimeLst.length - 1);
        setIsLoading(false);
        setMsgInfo([true, resp.msg, 'warning']);
      }
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });
    //props.history.push({ pathname: '/TerminalList/Detail/' + 'AD2001003', state: { id: 'AD2001003' } });
  }

    
  //===========================================
  //TAB操作関連
  //===========================================
  const [tabIndex, setTabIndex] = React.useState(0);

  //変更
  const handleChange = (event, newIndex) => {
    if(newIndex >=  vals.lst.length) return;
    setTabIndex(newIndex);
  };

  //追加
  const handleAddTab = () => {

    var tVals = vals;

    if(validateCompany()){
      var mResult = Object.assign({}, tVals);
      setVals(mResult);
      return;
    }

    tVals.lst.push(
      {
        termid: "",                   //
        name: "新規",                   //
        feature: "",                   //
        deviceid: "",                   //
        suite_main: "",                   //
        ssid: "ssid",                   //
        playlst: [],                   //
        localvediolst: [],                   //
      }
    )
    let tResult = Object.assign({}, tVals);
    setVals(tResult);
  };

  //確認メッセージ後の処理
  React.useEffect(() => {
    //端末削除確認結果
    if (confirmInfo[2].opt === "delete_term" && confirmInfo[2].result === 1) {
      var tVals = vals;
      tVals.lst.splice(tabIndex, 1);
      let tResult = Object.assign({}, tVals);
      setTabIndex(0);
      setVals(tResult);
    }
  }, [confirmInfo]);

  //削除
  const handleDelTab = () => {
    var tVals = vals;
    if(vals.lst.length <= 1){
      tVals["chk_playlist"] = "error";
      tVals["msg_playlist"] = VALIDATE_MSG.MSG_202;
      setVals(Object.assign({}, vals));

      return;
    } else if(tVals["msg_playlist"] === VALIDATE_MSG.MSG_202){
      tVals["chk_playlist"] = "success";
      tVals["msg_playlist"] = "";
      setVals(Object.assign({}, vals));
    }

    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        "opt"     : "delete_term",
        "result"  : 0
      }
    ]);
  };

  //TAB
  const handleOnTabEdit = (obj) => {
    var tVals = vals;
    tVals.lst[tabIndex] = obj;
    var tResult = Object.assign({}, tVals);
    setVals(tResult);
  }

  const handleOnValidCompany = (obj) => {
    var tVals = vals;

    if(validateCompany()){
      var tResult = Object.assign({}, tVals);
      setVals(tResult);
      return;
    }
  }

  const handleOnValidPlayList = (obj) => {
    validatePlayList();
    var tResult = Object.assign({}, vals);
    setVals(tResult);
  }

  return (
    <Box>
      <Grid container spacing={1} >
        <Grid item xs={3} sm={3} className={classes.addTitle}>
          {/* 端末追加 */}
          {/* <div><h3>端末追加</h3></div> */}
        </Grid>
        <Grid item xs={9} sm={9} className={classes.optBox}>
          <Button color="warning" onClick={handleClickSaveBtn}>
            &nbsp;&nbsp;保&nbsp;存&nbsp;&nbsp;
            </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1} >
        <GridItem xs={12} sm={12}>
          <Card className={classes.card}>
            <CardBody>
              <Grid container spacing={1} >
                <Grid item xs={6} sm={6}>
                  {/* 設置場所 */}
                  <div className={classes.areaTitle}><h4>設置場所</h4></div>
                </Grid>
              </Grid>
              <Grid container spacing={1} disabled={userInfo.userlevel===2?false:true}>
                <Grid item xs={3} sm={3}>
                  {/* 企業名（必須） */}
                  {vals.chk_idCompany === "error" ?
                    <InputLabel htmlFor="corpSelect" className={classes.selectLabelErr, classes.inputLabel}>企業名（必須）</InputLabel>
                    :
                    <InputLabel htmlFor="corpSelect" className={classes.selectLabel, classes.inputLabel}>企業名（必須）</InputLabel>}
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControl style={{ width: "90%" }}
                    className={classes.selectFormControl, classes.selectControl}
                    disabled={userInfo.userlevel === 2 ? false : true}>
                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={vals.idCompany}
                      onChange={event => handleChangeListStart("idCompany", event.target.value)}
                      inputProps={{ name: "corpSelect" }}>
                      <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                        企業を選択
                    </MenuItem>
                      {corpsInfo ? corpsInfo.lst.map((item, key) =>
                        <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={1000 + key}>
                          {item.corpname}
                        </MenuItem>
                      ) : null}
                    </Select>
                    <Box className={classes.selectHelpTextErr}>{vals.msg_idCompany}</Box>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  {/* 設置場所名（必須） */}
                  {vals.chk_idGroup === "error" ?
                    <InputLabel htmlFor="groupSelect" className={classes.selectLabelErr, classes.inputLabel}>設置場所名（必須）</InputLabel>
                    :
                    <InputLabel htmlFor="groupSelect" className={classes.selectLabel, classes.inputLabel}>設置場所名（必須）</InputLabel>}
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControl style={{ width: "90%" }}
                    className={classes.selectFormControl, classes.selectControl}>
                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={vals.idGroup}
                      onChange={event => handleChangeListStart("idGroup", event.target.value)}
                      inputProps={{ name: "groupSelect" }}>
                      <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                      場所名を選択
                    </MenuItem>
                      {groupsInfo ? groupsInfo.lst.map((item, key) =>
                        <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={2000 + key}>
                          {item.group}
                        </MenuItem>
                      ) : null}
                    </Select>
                    <Box className={classes.selectHelpTextErr}>{vals.msg_idGroup}</Box>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  {/* 設置場所概要 */}
                  {vals.chk_place === "error" ?
                    <InputLabel className={classes.selectLabelErr, classes.inputLabel}>設置場所概要（必須）</InputLabel>
                    :
                    <InputLabel className={classes.selectLabel, classes.inputLabel}>設置場所概要（必須）</InputLabel>}
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box style={{ width: "90%"}} className={classes.customInput}>
                    <CustomInput
                      id="place"
                      success={vals.chk_place === "success"}
                      error={vals.chk_place === "error"}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        value: vals.place,
                        onChange: event => handleEditOnChange("place", event.target.value)
                      }}
                      helperText={vals.msg_place}
                    />
                    </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{paddingTop: "20px"}}>
                <Grid item xs={6} sm={6}>
                  {/* 端末情報 */}
                  <div className={classes.areaTitle}><h4>端末情報</h4></div>
                </Grid>
              </Grid>
{/*              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  {/* 稼働時間（必須） */}{/*
                  <InputLabel className={classes.selectLabel, classes.inputLabel}>稼働時間（必須）</InputLabel>
                </Grid>
                <Grid item xs={6} sm={6} className={vals.runTimeLst.length === 0 ? classes.runTimeInputInit : classes.runTimeInput}>
                  {vals.runTimeLst ? vals.runTimeLst.map((item, key) =>
                    <Grid container spacing={1} style={{ width: "100%" }} key={3100 + key}>
                      <Grid item xs={12} sm={10} style={{ textAlign: "left" }} key={3200 + key}>
                        {/* 追加する稼働時間 */}{/*
                        <InputLabel className={classes.selectLabel, classes.addedRunTimeLabel}>{days[parseInt(item.idDay)].day}&nbsp;{item.deviceStartTime}〜{item.deviceEndTime}</InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Box className={classes.removeButton}>
                          <Button
                            key={3300 + key}
                            justIcon
                            onClick={event => removeRunTime(key)}
                            simple
                          >
                            <Clear />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid container spacing={1} style={{ width: "100%" }} className={classes.runTimeBox}>
                    <Grid item xs={12} sm={10} style={{ textAlign: "left" }}>
                      {/* 曜日 */}{/*
                      <FormControl style={{ width: "34%", float: "left" }}
                        className={classes.selectFormControl, vals.runTimeLst.length === 0 ? classes.runTimeSelectControlInit : classes.runTimeSelectControl}>
                        <Select
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={vals.idDay}
                          onChange={event => handleEditOnChange("idDay", event.target.value)}
                          inputProps={{ name: "daySelect" }}>
                          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                            曜日を選択
                        </MenuItem>
                          {days ? days.map((item, key) =>
                            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={4000 + key}>
                              {item.day}
                            </MenuItem>
                          ) : null}
                        </Select>
                        <Box className={classes.selectHelpTextErr}>{vals.msg_idDay}</Box>
                      </FormControl>
                      <Box style={{ width: "20%", float: "left" }} className={vals.runTimeLst.length === 0 ? classes.runTimePickerBoxInit : classes.runTimePickerBox}>
                        <NBrootsTimePicker
                          id="deviceStartTime"
                          success={vals.chk_deviceStartTime === "success"}
                          error={vals.chk_deviceStartTime === "error"}
                          value={vals.deviceStartTime}
                          onChange={handleRunTimeEditOnChange}
                          helperText={vals.msg_deviceStartTime}
                        />
                      </Box>
                      <InputLabel style={{ float: "left" }} className={vals.runTimeLst.length === 0 ? classes.runTimeLabelInit : classes.runTimeLabel}>〜</InputLabel>
                      <Box style={{ width: "20%", float: "left" }} className={vals.runTimeLst.length === 0 ? classes.runTimePickerBoxInit : classes.runTimePickerBox}>
                        <NBrootsTimePicker
                          id="deviceEndTime"
                          success={vals.chk_deviceEndTime === "success"}
                          error={vals.chk_deviceEndTime === "error"}
                          value={vals.deviceEndTime}
                          onChange={handleRunTimeEditOnChange}
                          helperText={vals.msg_deviceEndTime}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Box className={vals.runTimeLst.length === 0 ? classes.addButtonInit : classes.addButton}>
                        <Button
                          justIcon
                          onClick={addRunTime}
                          simple
                        >
                          <Add />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.selectHelpTextErr} style={{ marginLeft: "25px" }}>{vals.msg_runTime}</Box>
                </Grid>
              </Grid> */}
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  {/* 端末サイズ（必須） */}
                  <InputLabel className={classes.selectLabel, classes.inputLabel}>端末サイズ（必須）</InputLabel>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Grid container spacing={1} className={classes.sizeYInput}>
                    {/* 縦 : */}
                    <InputLabel className={classes.selectLabel}>縦 : </InputLabel>
                    <Box style={{ width: "20%" }} className={classes.customInput}>
                      <CustomInput
                        id="sizeY"
                        success={vals.chk_sizeY === "success"}
                        error={vals.chk_sizeY === "error"}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "text",
                          value: vals.sizeY,
                          onChange: event => handleSizeEditOnChange("sizeY", event.target.value)
                        }}
                        helperText={vals.msg_sizeY}
                      />
                    </Box>
                  </Grid>
                  <Grid container spacing={1} className={classes.sizeXInput}>
                    {/* 横 : */}
                    <InputLabel className={classes.selectLabel}>横 : </InputLabel>
                    <Box style={{ width: "20%" }} className={classes.customInput}>
                      <CustomInput
                        id="sizeX"
                        success={vals.chk_sizeX === "success"}
                        error={vals.chk_sizeX === "error"}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "text",
                          value: vals.sizeX,
                          onChange: event => handleSizeEditOnChange("sizeX", event.target.value)
                        }}
                        helperText={vals.msg_sizeX}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ display: "none" }}>
                <Grid item xs={3} sm={3}>
                  {/* メール送信 */}
                  <InputLabel className={classes.selectLabel, classes.inputLabel}>メール送信</InputLabel>
                </Grid>
                <Grid item xs={6} sm={6} style={{ marginLeft: "21px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={parseInt(vals.sendAlertMail, 10) === 1}
                        onChange={event => handleEditOnChange("sendAlertMail", parseInt(vals.sendAlertMail, 10) === 1 ? 0 : 1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ paddingTop: "20px" }}>
                <Grid item xs={6} sm={6}>
                  {/* 構成機器 */}
                  <div className={classes.areaTitle}><h4>構成機器</h4></div>
                </Grid>
              </Grid>
              <Box xs={12} className={classes.tableBox}>
                <Paper className={classes.tableCardBody}>
                  <TableContainer className={classes.table}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHead} style={{ width: "19%" }}>位置</TableCell>
                          <TableCell className={classes.tableHead} style={{ width: "27%" }}>製造番号（必須）</TableCell>
                          <TableCell className={classes.tableHead} style={{ width: "27%" }}>名称（任意）</TableCell>
                          <TableCell className={classes.tableHead} style={{ width: "27%" }}>機種（任意）</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBody}>
                        {vals.lst ? vals.lst.map((item, key) =>
                          <TableRow
                            className={selectedRow === key ? classes.tableRow2 : classes.tableRow}
                            onMouseOut={() => setSelectedRow('')}
                            key={5000 + key}
                          >
                            <TableCell className={classes.tableCell} >
                              {key + 1}
                            </TableCell>
                            <TableCell className={classes.tableCellLeft}>
                              {vals["msg_deviceid" + key] && vals["msg_deviceid" + key] !== "" ?
                                <CustomInput
                                  id={"deviceid" + key}
                                  success={vals["chk_deviceid" + key] === "success"}
                                  error={vals["chk_deviceid" + key] === "error"}
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    type: "text",
                                    value: item.deviceid,
                                    autoComplete: "off",
                                    onChange: event => handleDeviceEditOnChange("deviceid", key, event.target.value)
                                  }}
                                  helperText={vals["msg_deviceid" + key]}
                                />
                                :
                                <CustomInput
                                  id={"deviceid" + key}
                                  success={vals["chk_deviceid" + key] === "success"}
                                  error={vals["chk_deviceid" + key] === "error"}
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    type: "text",
                                    value: item.deviceid,
                                    autoComplete: "off",
                                    onChange: event => handleDeviceEditOnChange("deviceid", key, event.target.value)
                                  }}
                                />
                              }
                            </TableCell>
                            <TableCell className={classes.tableCellLeft}>
                              {vals["msg_name" + key] && vals["msg_name" + key] !== "" ?
                                <CustomInput
                                  id={"name" + key}
                                  success={vals["chk_name" + key] === "success"}
                                  error={vals["chk_name" + key] === "error"}
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    type: "text",
                                    value: item.name,
                                    autoComplete: "off",
                                    onChange: event => handleDeviceEditOnChange("name", key, event.target.value)
                                  }}
                                  helperText={vals["msg_name" + key]}
                                />
                                :
                                <CustomInput
                                  id={"name" + key}
                                  success={vals["chk_name" + key] === "success"}
                                  error={vals["chk_name" + key] === "error"}
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    type: "text",
                                    value: item.name,
                                    autoComplete: "off",
                                    onChange: event => handleDeviceEditOnChange("name", key, event.target.value)
                                  }}
                                />
                              }
                            </TableCell>
                            <TableCell className={classes.tableCellLeft}>
                              {vals["msg_feature" + key] && vals["msg_feature" + key] !== "" ?
                                <CustomInput
                                  id={"feature" + key}
                                  success={vals["chk_feature" + key] === "success"}
                                  error={vals["chk_feature" + key] === "error"}
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    type: "text",
                                    value: item.feature,
                                    autoComplete: "off",
                                    onChange: event => handleDeviceEditOnChange("feature", key, event.target.value)
                                  }}
                                  helperText={vals["msg_feature" + key]}
                                />
                                :
                                <CustomInput
                                  id={"feature" + key}
                                  success={vals["chk_feature" + key] === "success"}
                                  error={vals["chk_feature" + key] === "error"}
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    type: "text",
                                    value: item.feature,
                                    autoComplete: "off",
                                    onChange: event => handleDeviceEditOnChange("feature", key, event.target.value)
                                  }}
                                />
                              }
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
              {/* 共通ダイアログ画面 */}
              <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
              <NBrootsDialogProcess open={isLoading} color="warning" />
              <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
              {/* 構成機器のマトリックス */}
              {vals.lst.length > 0 ?
                <Box xs={12} className={classes.deviceMatrixTableBox} justify="center">
                  <Paper className={classes.deviceMatrixTableCardBody}>
                    <TableContainer className={classes.table}>
                      <Table stickyHeader>
                        <TableBody className={classes.tableBody}>
                          {vals.deviceMatrix ? vals.deviceMatrix.map((itemY, keyY) =>
                            <TableRow
                              key={6000 + keyY}
                            >
                              {itemY ? itemY.map((itemX, keyX) =>
                                <TableCell className={classes.deviceMatrixTableCell} key={7000000 + 1000 * keyY + keyX}>
                                  <Pagination
                                    pages={[
                                      { active: true, text: Number(itemX.id), onClick: doNothing }
                                    ]}
                                  />
                                </TableCell>
                              ) : null}
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
                : null}
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
}
