/* eslint-disable */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import Button from "components/CustomButtons/Button.js";
import NBrootsDialogPlayer from "components/@NBrootsDialogPlayer/NBrootsDialogPlayer.js";

// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Delete from "@material-ui/icons/Delete";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";
// exp components
import NBrootsPagination from "components/@NBrootsPagination/NBrootsPagination.js";
import NBrootsDialogProcess from "components/@NBrootsDialogProcess/NBrootsDialogProcess.js";
import NBrootsMsgBox from "components/@NBrootsMsgBox/NBrootsMsgBox.js";
import NBrootsConfirm from "components/@NBrootsConfirm/NBrootsConfirm.js";

import Service from "utils/service/service.js"
import cEmptyToNull from "utils/functions/cEmptyToNull"
import batFormat from "utils/functions/batFormat";
import { BASE_URL, VALIDATE_MSG } from 'variables/config.js';

import styles from "assets/jss/material-dashboard-pro-react/views/PlayList/playListStyle";

// メモリ中企業リスト
import { userInfo, corpsInfo } from 'variables/runtimeInfos.js';
import { PAGINATION_PREV_NAME,PAGINATION_NEXT_NAME, CORP_ID_SELECTED_ALL } from 'variables/config.js';



export default function PlayList(props) {

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  
  //進捗画面
  const [isLoading, setIsLoading] = React.useState(false);
  //Msg画面用情報             msgInfo     : [表示FLG, Msg, Type:"info","success","warning","danger","primary","rose"]    
  const [msgInfo, setMsgInfo] = React.useState([false, '', 'info']);
  //Confirm画面用情報         confirmInfo : [表示FLG, Msg, returnVal ]    
  const [confirmInfo, setConfirmInfo] = React.useState([false, '', { "opt": '', "id": '', "result": 0 }]);
  //player画面用情報          playerInfo  : [表示FLG、VedioSrc]    
  const [playerInfo, setPlayerInfo] = React.useState([false, []]);

  var initIndex = 0;
  const [index, setIndex] = React.useState(initIndex);
  //検索条件（初期状態）
  var tSearch = {
    crop      : userInfo.userlevel === 2?CORP_ID_SELECTED_ALL:userInfo.corpid,           //検索条件　企業ID
    orderby   : 'update_time',           //orderby
    sorttype  : 'desc',           //sorttype
    pageIndex : 1,            //当ページ番号
    pageSize  : 10            //ページサイズ
  }
  const [search, setSearch] = React.useState(props.location.state ? props.location.state.searchConds : tSearch);
  const [selectedRow, setSelectedRow] = React.useState(props.location.state ? props.location.state.id : '');

  //検索結果（初期状態）
  var tSearchResult = {
    total     : 0,            //記録数
    lst       : []            //明細リスト
  }
  const [searchResult, setSearchResult] = React.useState(tSearchResult);

  //初期化処理
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);

  }, []);    //[] 忘れないよう…　初期化

  //APIJSON　→　画面用JSON（FLGやERRMSGなど）
  const formatLstData = (tSearchCondition) => {
    var url = BASE_URL + "/API/PlayList/Search";
    
    var data = {
      companyid : cEmptyToNull(tSearchCondition.crop === CORP_ID_SELECTED_ALL ? '' : tSearchCondition.crop),
      orderby   : tSearchCondition.orderby,
      sorttype  : tSearchCondition.sorttype,
      pageIndex : tSearchCondition.pageIndex,
      pageSize  : tSearchCondition.pageSize
    };
    var tResult ;
    Service(props, url, data).then(resp => {
      // console.log(resp);
      // 正常系
      if (resp.status === 0) {
        var info = resp.infos;
        //Data情報の処理
        var lst = info.list;
        //検索結果（セット）
        tResult = {
          total     : info.total,
          lst       : [...lst]
        }

        setSearchResult(tResult);

      } else {
        //その他APIエラー  
        //検索結果（初期状態） 
        tResult = {
          total : 0,
          lst   : []
        }
        setSearchResult(tResult);
        setMsgInfo([true, resp.msg, 'warning']);
      }
      setIsLoading(false);
    }).catch(resp => {
      //接続エラーなど
      setIsLoading(false);
      setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
    });

  };

  //検索条件部
  //企業変更の場合
  const handleChangeSearchStart = (e) => {    
    let t = search;    
    //選択情報をSTATEに保存
    if(e.target.name === 'corpSelect'){
      t.crop = e.target.value;
    }
    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  }


  //改ページ処理
  // pageInfos   : [ページサイズ,当ページ番号]
  const handleChangePageStart = (page) => {

    let t = search;
    if (page.disabled) return;
    if (page.text === search.pageIndex) return;

    if (page.text === PAGINATION_PREV_NAME) {
      t.pageIndex = t.pageIndex - 1;
    } else if (page.text === PAGINATION_NEXT_NAME) {
      t.pageIndex = t.pageIndex + 1;
    } else {
      t.pageIndex = page.text;
    }

    //情報セット  
    setSearch(t);
    //進捗画面
    setIsLoading(true);
    //情報のロード
    formatLstData(search);
  };


  //再生ボタン押す
  const handleClickStartPlay = (obj) => {

    setIndex(initIndex);

    var lst = [];
    if(obj.playlist && obj.playlist.length > 0){
      var k = 0;
      for(k; k< obj.playlist.length; k++){
        var i = 1;
        for(i;i <= obj.playlist[k].loop_count; i++){
          lst.push(obj.playlist[k].src);
          
          if(k === obj.playlist.length - 1 && i === obj.playlist[k].loop_count){
          }else{
            lst.push('img/vedio/Waiting.mp4');
          }
        }
      }
    }
    //Player画面を開く
    // console.log(lst);
    setPlayerInfo([true, lst]);
    
  }

  //変更ボタン押す
  const handleClickStartEdit = (obj) => {
    
    props.history.push({pathname: '/PlayList/Detail/' + obj.id, state: { goBackUrl: '/PlayList', searchConds : search, id: obj.id}});
  };

  //追加ボタン押す
  const handleClickStartAdd = (obj) => {

    props.history.push({pathname: '/PlayList/Add/', state: { goBackUrl: '/PlayList', searchConds : search}});
  };

  //===========================================
  //削除機能
  //===========================================
  //削除ボタン押す  
  const handleClickStartDel = (obj) => {
    setConfirmInfo([true, VALIDATE_MSG.MSG_503,
      {
        "opt"     : "del",
        "id"      : obj,
        "result"  : 0
      }
    ]);
  };

  //確認メッセージ後の処理
  React.useEffect(() => {
    //削除処理
    function doDel(obj){
      //編集IDX取得
      setIsLoading(true);
      var url = BASE_URL + "/API/PlayList/Delete";
      //　入力データ
      var data = {
        playlistid      : cEmptyToNull(obj.id),
      };
      // console.log(data);
      //更新処理
      Service(props, url, data).then(resp => {
        // 正常系
        if (resp.status === 0) {
          setMsgInfo([true, resp.msg, 'success']);
          //情報のロード
          formatLstData(search);
        } else {
          setMsgInfo([true, resp.msg, 'danger']);
        }
        //進捗画面を閉じる
        setIsLoading(false);
      }).catch(resp => {
        //接続エラーなど
        setIsLoading(false);
        setMsgInfo([true, VALIDATE_MSG.MSG_901, 'danger']);
      });
    };

    //起動確認結果
    if (confirmInfo[2].opt === "del" && confirmInfo[2].result === 1) {
      doDel(confirmInfo[2].id);
    }

  }, [confirmInfo]);

  return (
    <Box>
      <Grid container spacing={1}>
        {/* 検索部 */}
        <Grid item xs={6} sm={6} className={classes.searchBox}>
          {userInfo.userlevel === 2 &&
            <FormControl style={{ width: "40%" }}
              className={classes.selectFormControl}
              disabled={userInfo.userlevel===2?false:true}>
              <InputLabel htmlFor="corpSelect" className={classes.selectLabel}>
                企業
              </InputLabel>
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                value={search.crop}
                onChange={(e) => handleChangeSearchStart(e)}
                inputProps={{ name: "corpSelect"}}>
                <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                  企業を選択
                </MenuItem>
                <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={CORP_ID_SELECTED_ALL}>
                  すべて
                </MenuItem>
                {corpsInfo ? corpsInfo.lst.map((item,key) =>
                  <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={item.id} key={key}>
                    {item.corpname}
                  </MenuItem> 
                ) : null}

              </Select>
            </FormControl>
          }
        </Grid>
        {/* 操作ボタン部 */}
        <Grid item xs={6} sm={6} className={classes.optBox}>
          <Button color="warning" onClick={() => handleClickStartAdd()}>プレイリスト追加</Button>
        </Grid>
        {/* ページジャンプ */}
        <Grid item xs={12} className={classes.pageJumpBox}>
          {/* [記録数、ページサイズ,当ページ番号] */}
          <NBrootsPagination
            count={searchResult.total}
            pagesize={search.pageSize}
            current={search.pageIndex}
            onClick={handleChangePageStart}
            color="warning"
          />
        </Grid>
      </Grid>

      <Box xs={12} className={classes.tableBox}>
        <Paper className={classes.tableCardBody}>
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {userInfo.userlevel === 2 && <TableCell className={classes.tableHead} style={{ width: "15%" }}>企業</TableCell> }
                  <TableCell className={classes.tableHead} style={{ width: "35%" }}>プレイリスト名</TableCell>
                  <TableCell className={classes.tableHead} style={{ width: "10%" }}>サイズ</TableCell>
                  <TableCell className={classes.tableHead} style={{ width: "20%" }}>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {searchResult.lst ? searchResult.lst.map((item, key) =>
                  <TableRow 
                    className={selectedRow === item.id ? classes.tableRow2 : classes.tableRow} 
                    onMouseOut={() => setSelectedRow('')} 
                    key={key}
                  >
                    {userInfo.userlevel === 2 && <TableCell className={classes.tableCellLeft} onClick={() => { handleClickStartEdit(item) }}>{item.idCompanyName}</TableCell>}
                    <TableCell className={classes.tableCellLeft} onClick={() => { handleClickStartEdit(item) }}>{item.name}</TableCell>
                    <TableCell className={classes.tableCell} onClick={() => { handleClickStartEdit(item) }}>
                      {batFormat(item.materialSize)}
                    </TableCell>
                    <TableCell className={classes.tableCell} >

                      <Tooltip id="tooltip-top" title="再生" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                        <Button simple className={classes.actionButton} onClick={() => { handleClickStartPlay(item) }} color="transparent" >
                          <PlayCircleOutline />
                        </Button>
                      </Tooltip>
                      <Tooltip id="tooltip-top" title="詳細情報" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                        <Button simple className={classes.actionButton} onClick={() => { handleClickStartEdit(item) }} color="warning" >
                          <Dvr />
                        </Button>
                      </Tooltip>
                      <Tooltip id="tooltip-top" title="削除" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                        <Button simple className={classes.actionButton} onClick={() => { handleClickStartDel(item) }} color="danger">
                          <Delete />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      {/* 共通ダイアログ画面 */}
      <NBrootsConfirm info={confirmInfo} setInfo={setConfirmInfo} />
      <NBrootsDialogPlayer open={playerInfo[0]} srcLst={playerInfo[1]} index={index} setIndex={setIndex} onClose={() => setPlayerInfo([false, []])} />
      <NBrootsDialogProcess open={isLoading} color="warning" />
      <NBrootsMsgBox open={msgInfo[0]} message={msgInfo[1]} color={msgInfo[2]} close setMessage={setMsgInfo} />
    </Box>
  );
}
