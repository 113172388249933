// ユーザ権限　
// ０一般ユーザ　1　企業管理員　2　システム管理員
export var userInfo = {
    // API用的token
    token       : "",

    userid      : "",
    username    : "",
    useremail   : "",
    corpid      : "",
    corpname    : "",

    email       :"",

    // ユーザ権限　
    // ０一般ユーザ　1　企業管理員　2　システム管理員
    userlevel   : 0,
};

//共通コンポーネント用変数
//企業リスト
export var corpsInfo = {
    lst       : []
};

//共通コンポーネント用変数
//設置場所リスト
export var groupsInfo = {
    lst       : []
};


//共通コンポーネント用変数
//設置場所リスト
export const getGroupsInfo = (id) => {
    for (var i = 0;i<corpsInfo.lst.length;i++){
        if(corpsInfo.lst[i].id === id){
            groupsInfo.lst = corpsInfo.lst[i].grouplst;
            return 
        }

    }
    groupsInfo.lst = []
    return;
};

//共通コンポーネント用変数
//素材-種類リスト
export var materialCategoryInfo = {
    lst       : []
};
