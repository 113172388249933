import { InsertLinkRounded } from '@material-ui/icons';
import {
  blackColor,
  warningColor,
  whiteColor,
  tooltip,
  grayColor,
  cardTitle,
  hexToRgb
} from 'assets/jss/material-dashboard-pro-react.js';

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.js';
import customCommonStyle from 'assets/jss/material-dashboard-pro-react/customCommonStyle.js';

const terminalsListStyle = {
  
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  ...hoverCardStyle,
  ...customCommonStyle,

  tooltip:{
    ...tooltip,
    marginTop: '0px',
    marginBottom: '3px'
  },
  cardTitle: {
    ...cardTitle,
    marginTop: '0px',
    marginBottom: '3px'
  },
  cardBody: {
    padding: '0.4375rem 20px',
  },
  terminalListBox:{
    width      : 'calc(100% - 60px)',
    position   : 'absolute',
  },
  searchOptBox:{
    marginLeft : '15px',
    width      : 'calc(100% - 15px)',
  },
  searchBox:{
    textAlign:'left',    
  },
  optBox:{
    "& > button": {
      marginLeft   : '10px',
    },
    textAlign :'right',
    marginTop : '15px'
  },
  pageJumpBox:{
    textAlign:'center',
    height:'40px',
    paddingTop: '10px',
  },
  tableBoxError:{
    width     : '100%',
    paddingTop: '10px',
  },
  tableBoxErrorAllInfo:{
    height    : '50px',
    width     : '100%',
    paddingTop: '10px',
  },
  tableBox:{
    //  height    : '620px',
    width     : '100%',
    paddingTop: '10px',
  },
  tableCardBody:{
    padding       : '10px',
    height        : '100%',
    width         : '100%',
    borderRadius  : '6px',
    //display       : 'block',
    position      : 'relative',
    // position      : 'absolute'
    // marginTop: '10px',

    // width:'100%',
    // marginTop: '10px',
    // overflowY:'hidden',
    // overflowX: 'hidden',
  },
  table:{
    height    : '100%',
    width     : '100%',
    marginTop : '0px',
    display   : 'block',
    // position  : 'fixed',
    overflowX : 'auto',
    overflowY : 'auto'
  },
  /*テーブルヘッダ部ICONのSTYLE*/
  tableHeadIcon: {
    margin          : 0,
    '&:hover': {
        backgroundColor: '#EFEFEF',
    }

  },
  tableHeadSwapIcon: {
    color        : '#c3c3c3',
    height       : '17px',
    width        : '17px',
    '&:hover': {
          backgroundColor: '#EFEFEF',
      }
  },
  /*テーブルヘッダ部のSTYLE*/
  tableHead: {
    // fontSize          : '14px',
    // backgroundColor   : '#f8f8f8',
    padding           : '10px 10px 10px 10px',
    minheight         : '50px',
    minwidth          : '80px',
    height            : '50px',
    // color             : '#383838',
    // fontWeight        : 'bold',
    lineHeight        : '1rem',
    verticalAlign     : 'middle',
    textAlign         : 'center',
    'z-index'         : '10',
    'white-space'     : 'nowrap',
  },
  tableSortHeadWrapper: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  /*テーブルBODY部のSTYLE*/
  tableCell:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'center'
  },
  /*テーブルBODY部のSTYLE*/
  tableCellLeft:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'left'
  },
  /*テーブルBODY部のSTYLE*/
  tableCellRight:{
    padding     : '10px 5px 5px 10px',
    textAlign   : 'right'
  },
  /*テーブルBODY部のSTYLE*/
  tableBodyError:{
    overflow         : 'visible'
  },
  /*テーブルBODY部のSTYLE*/
  tableBody:{
    maxheight        : '500px',
    overflow         : 'visible'
  },
  /*テーブル行のSTYLE*/
  tableRow:{
    marginLeft       : '3px',
    backgroundColor  : '#FFFFFF',
    height           : '40px',
    cursor           : 'pointer',
    '&:hover': {
        backgroundColor: '#FFFDF2',
      }
  },
  tableRow2:{
    marginLeft       : '3px',
    backgroundColor  : '#FFFDF2',
    height           : '40px',
    cursor           : 'pointer',
  },
  //操作ボタン
  actionButton: {
    //margin: '10px 0px 0px 10px',
    margin: '5px',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px'
    }
  },
  inputName: {
    margin: '0 0 5px 0',
    paddingTop: '10px'
  },  
  dialogHeader: {
    backgroundColor: warningColor[0] + '!important',
    color: whiteColor + ' !important',
  },
  omit: {
    maxWidth: 300,
    display: 'block',
    textOverflow:'ellipsis',
    overflow:'hidden',
    whiteSpace:'nowrap',
  },
  contentTitle: {
    fontWeight: '800',
    color: grayColor[3] + ' !important',
    marginLeft: '15px',
    marginTop: '20px',
    marginBottom: '5px',
  },
  messageAllInfo: {
    marginLeft: '10px',
    font: '14px',
    fontWeight: '800',
  },
  status: {
    height: '20px',
  },
  status_name: {
    float: 'right',
    height: '15px',
    width: '56px',
  },
  status_blank: {
    float: 'right',
    height: '15px',
  },
  status_error: {
    width: '15px',
    height: '15px',
    backgroundColor: '#FF0000',
    borderRadius: '50%',
    marginTop: '1px',
    float: 'right',
  },
  status_started: {
    width: '15px',
    height: '15px',
    backgroundColor: '#00FF00',
    borderRadius: '50%',
    marginTop: '1px',
    float: 'right',
  },
  status_stopped: {
    width: '15px',
    height: '15px',
    backgroundColor: '#C0C0C0',
    borderRadius: '50%',
    marginTop: '1px',
    float: 'right',
  },
  switch_label: {
    "&>span": {
      color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
      fontSize: '14px !important',
      lineHeight: '1.43 !important'
    }
  }

};

export default terminalsListStyle;
